/* .custom-date .dx-texteditor-buttons-container{
    display: none;
} */

.custom-date{
    width: 50% !important;
}

.custom-date .dx-texteditor-input{
    padding-right: 9px !important;
}
.dx-calendar-body .dx-calendar-navigator .dx-calendar-navigator-previous-month .dx-button .dx-icon, 
.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
.dx-calendar-body .dx-calendar-navigator .dx-calendar-navigator-next-month.dx-button .dx-icon, .dx-calendar-navigator-next-view.dx-button .dx-icon{
    color: #008da9 !important;
}
.dx-calendar-body .dx-calendar-selected-date{
    background-color: #008da9 !important;
    -webkit-box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px #008da9 !important;
    box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px #008da9 !important;
}

.dx-calendar-body .dx-calendar-cell.dx-calendar-contoured-date:not(.dx-calendar-empty-cell) {
    -webkit-box-shadow: inset 0 0 0 1px #008da9;
    box-shadow: inset 0 0 0 1px #008da9;

}