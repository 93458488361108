.background-image {
    background: url(../../../../images/homeslide01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    width: 100%;
    height: 760px;
    margin: -5rem 0 0 0;
}

.logo {
    text-align: center;
    background: url(../../../../assets/logos/Oasis_1c_inverted.png) top left;
    background-size: cover;
    height: 50px;
    width: 113px;
}

.main_button {
    border: 6px solid rgba(255, 255, 255, 1);
    background-color: transparent;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    padding: 1rem;
    margin: 0 auto;
    width: 270px;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.main_button--small {
    height: 45px;
    margin: 0 0 0 1rem;
}

.main_button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(26, 147, 170, 1);
}

.input-search {
    text-transform: uppercase;
    /* padding: 0.6rem; */
    font-size: 12px;
    width: 720px;
    height: 48px;
    /* border: none; */
    border-radius: 0 !important;
}

.input-search input {
    text-transform: uppercase;
}

.input-search:focus {
    outline: none;
}

.calendar-container {
    display: none;
}

.calendar-input {
    padding: 10px;
    height: 48px;
    border-radius: 0 !important;
    margin-right: 1px;
}

.calendar-selector {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: solid 1px rgba(0, 0, 0, 0.237);
}

.calendar-selector::before {
    background: #fff;
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    z-index: 10;
    transform: translateX(-50%) rotate(45deg);
}

.calendar-selector--up::before {
    bottom: -7px;
}

.calendar-selector--down::before {
    top: -8px;
    left: 50%;
    border-top: solid 1px rgba(0, 0, 0, 0.237);
    border-left: solid 1px rgba(0, 0, 0, 0.237);
}

.calendar-selector--down {
    top: 60px
}

.calendar-selector--up {
    bottom: 60px
}

.input-search--error,
.calendar-input--error {
    border: solid 1.2px rgba(255, 0, 0, 0.591) !important;
    transition: all 1s !important;
}

/*assets*/
.search_bar {
    background-color: rgba(26, 147, 170, 0.90);
    margin: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
}

/* Responsive Styles */
/* @media screen and (min-width: 1281px) {
    .search_bar {
        width: auto;
    }

    .input-search {
        width: 370px;
    }
}

@media screen and (min-width: 1206px) and (max-width: 1280px) {
    .search_bar {
        width: auto;
    }

    .input-search {
        width: 370px;
    }
} */

@media screen and (min-width: 1080px) and (max-width: 1280px) {
    .search_bar {
        width: auto;
    }

    /* .input-search {
        width: 540px;
    } */
}

@media screen and (min-width: 720px) and (max-width: 1079px) {
    .input-search {
        width: 360px;
    }

    .main_button,
    .main_button--small {
        width: auto;
    }
}

@media screen and (min-width: 480px) and (max-width: 719px) {
    .title {
        font-size: 3rem;
    }

    .subtitle {
        font-size: 1.5rem;
    }

    .search_bar {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 450px;
    }

    .input-search {
        width: 360px;
    }

    .main-button--small,
    .main_button--small {
        width: 360px;
        margin: 1rem auto 0 auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    .title {
        font-size: 1.5rem;
    }

    .subtitle {
        font-size: 0.9rem;
    }

    .search_bar {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 270px;
    }

    .input-search {
        width: 180px;
    }

    .main-button--small,
    .main_button--small {
        width: 180px;
        margin: 1rem auto 0 auto;
    }
}

/* Hero Background Video */
.hero-content {
    position: relative;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 797px;
    color: #fff;
    background: transparent;
    z-index: 99;
}

.hero-content h1 {
    font-size: 60px;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.hero-content p {
    font-size: 24px;
    font-weight: 300;
}

.hero-content-btn-container {
    width: 100%;
    height: auto;
    margin: 2rem auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-content-btn {
    border: 6px solid #fff;
    width: 270px;
    height: 18px;
    padding: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #fff;
    font-size: 20px;
    font-style: bold;
    text-transform: uppercase;
}

.hero-video-container {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    width: 100%;
    height: 797px;
    margin: 0 auto;
    overflow: hidden;
}

.hero-video-container video {
    object-fit: cover;
    object-position: center;
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: auto;
}

.hide-controls::-webkit-media-controls {
    display: none;
}