.become-a-partner{
    color: rgb(118, 118, 118);
}
.become-a-partner__section{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    /* align-items: center; */
    margin-bottom: 40px;
}
.become-a-partner__section__title{
    display: flex;
    justify-content: center;
}
.become-a-partner__section p{
    white-space: pre-wrap;
}

.become-a-partner__information-cards{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 50px;
}
.become-a-partner__section__partner-cards{
    display: flex;
    flex-wrap: wrap;
    row-gap: 50px;
    justify-content: space-between;
}

@media screen and (max-width: 782px){
    .become-a-partner__section__partner-cards{
        justify-content: center;
        gap: 20px;
    }
}
@media screen and (max-width: 600px){
    .become-a-partner__information-cards{
        grid-template-columns: auto;
        gap: 20px;
    }
}
