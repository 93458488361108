.custom-phone input::placeholder{
    color: transparent;
}
.custom-phone .react-tel-input{
    font-size: inherit;
}
.custom-phone .react-tel-input .flag-dropdown.open{
    z-index: 4000;
}

.custom-phone .react-tel-input .country-list{
    font-size: 13px;
}
.custom-phone .react-tel-input .form-control{
    width: 100% !important;
    border-color: #ddd;
    color: #7b7b7b;
    font-size: inherit;
}

.custom-phone .react-tel-input .form-control.invalid-number, 
.custom-phone .react-tel-input .form-control.invalid-number:focus
{
    background-color: #fff;
}
.custom-phone .form-control:hover{
    border-color: rgba(51,122,183,.4);
}
.custom-phone .form-control:focus{
    color: #7b7b7b;
    font-size: inherit;
    border-color: #337ab7;
    box-shadow: none;
}
.custom-phone--error{
    position: absolute;
    z-index: 100;
    padding: 8px 10px;
    color: #fff;
    background-color: #d9534f;
    border-radius: 4px;
}
.custom-phone__container__icon::after{
    pointer-events: none;
    font-weight: 700;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 13px;
    background-color: #d9534f;
    color: #fff;
    content: "!";
    border-radius: 50%;
}

.custom-phone__container{
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: baseline;
}
.custom-phone__container__icon{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 23px);
    height: 100%;
    text-align: right;
    cursor: text;
    pointer-events: none;
    color: #999;
}

.custom-phone--label-mode .react-tel-input :disabled{
    cursor: text;
}
.custom-phone--label-mode .form-control{
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
    height: fit-content !important;
}
.custom-phone--label-mode .flag-dropdown{
    display: none;
}