.image-container {
    display: block;
    margin: 0 2rem;
    width: auto;
    height: auto;
}

.slider-image {
    width: 100%;
    height: 245px;
    object-fit: cover;
    /*box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);*/
}

.slider-title {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    margin: 1rem 0 0 0;
}

.left-passive {
    background: url(../../../../images/landing/arrows-landing/left-passive.png);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    width: 54px;
    height: 72px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 1rem;
    margin: 0 0 2rem 0;
}

.left-active {
    background: url(../../../../images/landing/arrows-landing/left-active.png);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 54px;
    height: 72px;
    bottom: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 1rem;
    margin: 0 0 2rem 0;
}

.right-passive {
    background: url(../../../../images/landing/arrows-landing/right-passive.png);
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 72px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 1rem;
    margin: 0 0 2rem 0;
}

.right-active {
    background: url(../../../../images/landing/arrows-landing/right-active.png);
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 72px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 1rem;
    margin: 0 0 2rem 0;
}

.slider-container {
    width: 90%;
    height: 360px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* @media screen and (min-width: 1670px) and (max-width: 1800px) {
    .slider-container {
        max-width: 1450px;
    }
} */

/* @media screen and (min-width: 1500px) and (max-width: 1669px) {
    .slider-container {
        max-width: 1350px;
    }
} */

/* @media screen and (min-width: 1400px) and (max-width: 1499px) {
    .slider-container {
        max-width: 1250px;
    }
} */

@media screen and (min-width: 1291px) and (max-width: 1399px) {
    .slider-container {
        /* max-width: 1200px; */
        height: 360px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

/* @media screen and (min-width: 1001px) and (max-width: 1299px) {
    .slider-container {
        max-width: 991px;
    }
} */

/* @media screen and (min-width: 720px) and (max-width: 1000px) {
    .slider-container {
        max-width: 720px;
    }
} */

/* @media screen and (min-width: 571px) and (max-width: 721px) {
    .slider-container {
        max-width: 540px;
    }
} */

/* @media screen and (min-width: 320px) and (max-width: 570px) {
    .slider-container {
        max-width: 360px;
    }
} */