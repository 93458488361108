.pagination{
    display: flex;
    column-gap: 10px;
}

.pagination button{
    transition: all .3s;
    background-color: #fff;
    color: rgb(118, 118, 118);
    border: 1.5px solid #008da9;
    padding: 0 10px;
    font-weight: 100;
    font-size: 18px;
}  

.pagination button:hover:enabled,.pagination .page--active{
    transition: all .3s;
    background-color: #008da9 !important;
    color: #fff;
}

.pagination button:disabled{
    transition: all .3s;
    color: rgb(226, 226, 226);
    border: 1.5px solid rgb(226, 226, 226)
}

@media screen and (max-width: 500px) {
    .pagination{
        column-gap: 5px;
    }
    
    .pagination button{
        padding: 0 10px;
        font-size: 15px;
    }  
}