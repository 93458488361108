
.request-option__property-main-information__section1{
    display: flex;
    justify-content: space-between;
}
.request-option__property-main-information__section1__info{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.request-option__property-main-information__section1__info__item{
    display: flex;
    gap: 5px;
    align-items: center;
}
.request-option__property-main-information__section1__info__item--property-name{
    font-weight: bold;
}
.request-option__property-main-information__section1__info__item__link{
   color: #008da9;
   cursor: pointer;
}
.request-option__property-main-information__section1__info__item__link:hover{
    text-decoration: underline;
}
.request-option__property-main-information__section1__info__item__bold,.request-option__property-main-information__section2__subsection--bold{
    font-weight: 800;
}
.request-option__property-main-information__section1__info__calendar{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.request-option__property-main-information__section1__info__calendar__time{
    font-style: italic;
    padding-left: 25px;
}
.request-option__property-main-information__section2__subsection--missing{
    color: #cdcdcd;
}
.request-option__property-main-information__section2{
    display: grid;
    gap: 10px;
    grid-template-columns: 3.5fr 4.25fr 4.25fr;    
}
.request-option__property-main-information__section2__subsection div{
    display: flex;
    gap: 5px;
}
.request-option__property-main-information__section2__subsection__item{
    padding-left: 15px;
}

@media screen  and (max-width: 700px){
    .request-option__property-main-information__section2{
        grid-template-columns: 12fr;    
    }
}