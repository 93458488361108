.cta-container {
    /* padding: 2rem 8.75rem !important; */
    padding-bottom: 2rem;
    width: 90%;
    margin: 0 auto;
}
/* .cta-container h3 {
    margin-top: 20px !important;
} */
.cta-icons-container {
    background: url('../../../../images/landing/bg-landing/wavebg.png');
    background-repeat: repeat;
    width: 100%;
    height: auto;
    padding: 2rem 5% !important;
    margin: 0 auto;
}

.cta {
    width: 100%;
    height: auto;
}

.cta-icons {
    width: 100%;
    height: auto;
}

.cta-title {
    text-transform: uppercase !important;
    margin: -61px 0 0 0 !important;
    padding: 70px 0px 30px 0px !important;
    text-align: center !important;
    font-size: 30px !important;
}

.cta-title-p-0 {
    padding: 3rem !important;
}

.cta-title-p-1 {
    padding: 6rem 0 3rem 0 !important;
}

.cta-sub-title {
    font-size: 20px !important;
    font-weight: 900 !important;
}

.cta-content {
    width: auto;
    height: auto;
}

.cta-content p {
    font-size: 15px !important;
    text-align: left !important;
    font-weight: 200 !important;
    color: #515151 !important;
    margin: 0;
    padding: 0;
}

.cta-content h5 {
    margin: 14px 0 10px 0 !important;
}

.cta-video {
    background: black;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
    object-fit: cover;
}

.cta-video video {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
}


.cta-card__title{
    font-size: 20px;
    /* margin: 0px !important; */
    margin: 20px 0px 10px 0px !important;
}

.cta-card p:last-child{
    text-align: justify;
}
.cta-icons-container__items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5%;
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .cta-card {
        /* margin: 2rem 1rem 2rem 1rem; */
        width: auto;
        height: auto;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1499px) {
    .cta-title{
        margin: -91px 0 0 0 !important;
    }
    .cta-container {
        padding: 2rem 1.75rem !important;
    }

    .cta-icons-container {
        padding: 0 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        /* margin: 2rem 1rem 2rem 1rem; */
        width: auto;
        height: auto;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1439px) {
    .cta-container {
        padding: 2rem 1.75rem !important;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        /* margin: 2rem 1rem 2rem 1rem; */
        width: auto;
        height: auto;
    }
    .cta-title{
        margin: -91px 0 0 0 !important;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1399px) {
    .cta-container {
        padding: 2rem 1.75rem !important;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        /* margin: 2rem 1rem 2rem 1rem; */
        width: auto;
        height: auto;
    }
    .cta-title{
        margin: -91px 0 0 0 !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1279px) {
    .cta-container {
        padding: 2rem 1.75rem !important;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        /* margin: 2rem 1rem 2rem 1rem; */
        width: auto;
        height: auto;
    }
    .cta-title{
        margin: -91px 0 0 0 !important;
    }
}

@media screen  and (max-width: 1200px){
    .cta-content p{
        text-align: justify !important;
    }
    .cta-icons-container__items{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1%;
    }  
    .cta-title{
        margin: -91px 0 0 0 !important;
    }
}


@media screen and (min-width: 1080px) and (max-width: 1199px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 1.5rem;
    }
    .cta-title{
        margin: -91px 0 0 0 !important;
    }
}

@media screen and (min-width: 991px) and (max-width: 1079px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 1.5rem;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 0 0 2rem 0;
    }
}

@media screen and (min-width: 567px) and (max-width: 766px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 0 0 2rem 0;
    }
}

@media screen and (min-width: 547px) and (max-width: 566px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 0 0 2rem 0;
    }
}

@media screen and (min-width: 480px) and (max-width: 546px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 0 0 2rem 0;
    }

    .cta-card img {
        margin: 0 auto !important;
    }

    .cta-card p {
        margin: 1rem auto !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    .cta-container {
        padding: 2rem 0.75rem !important;
    }

    .cta-title {
        text-align: left !important;
        font-size: 1rem !important;
        margin: -81px 0 0 0 !important;
        padding: 3rem !important;
    }

    .cta-video {
        margin: 2rem auto;
    }

    .cta-icons-container {
        padding: 2rem 5% !important;
        margin: 0 auto !important;
    }

    .cta-card {
        margin: 0 auto;
        padding: 0 0 2rem 0;
    }

    .cta-card img {
        margin: 0 auto !important;
    }

    .cta-card p {
        margin: 1rem auto !important;
    }
}