.request-list-item .request-list-item__accordions{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.request-list-item .section-subtitle{
    font-size: 16px;    
}
.request-list-item__title{
    display: flex;
    align-items: center;
    gap: 5px;
}
.request-list-item__status-request-tag{
    font-size: small;
    color: #fff;
    background-color: #008da9;
    border-radius: 20px;
    border: solid 1px #008da9;
    font-weight: 100 !important;
    padding: 0px 7px;
}
.request-list-item__status-request-tag--new{
    background-color: #01b25a;
    border: solid 1px #01b25a;
}