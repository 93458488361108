.my-request__option__amenities-list{
    display: flex;
    gap: 20vw;
    color: #515151;

}

.my-request__option__amenities-list__list--hidden{
    max-height: 0px;
    overflow: hidden;
}

.my-request__option__amenities-list__amenity{
    padding-left: 14px;
}

.my-request__option__amenities-list__amenity__container{
    display: flex;
    gap: 5px;
    align-items: center;
    padding-bottom: 5px;
}

.my-request__option__amenities-list__list--show{
    max-height: 20000px;
    overflow: auto;
}

.my-request__option__amenities-list__amenity__more-button{
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding-left: 14px;
    cursor: pointer;
    color: #008da9;
}
.my-request__option__amenities-list__amenity__more-icon--active{
    transform: rotateX(180deg);
    transition: all .6s;
}
.my-request__option__amenities-list__amenity__more-icon--inactive{
    transform: rotateX(0deg);
    transition: all .6s;
}

@media screen  and (max-width: 500px){
    .my-request__option__amenities-list{
        flex-direction: column;
        gap: 25px;
    }
}