.guest-card{
    padding: 4% 5%;
    border: 1px solid #c9c9c9;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    row-gap: 4px;
    font-size: small;
}

.guest-card__field{
    display: flex;
    gap: 5px;
}

.guest-card__field__name{
    font-weight: 800;
    color: #656565 ;
}

.guest-card__field__value{
    color: #838383 ;
}

/* @media screen and (max-width: 1300px) {
    .guest-card{
        font-size: x-small;
    }
} */