.property-details-loading{
    margin: 30px 0;
}
.property-details-address{
    color:rgba(26, 147, 170, 0.90);
    font-size: 14.5px;
    display: inline-flex;
    column-gap: 5px;
    margin: 5px 0px;
    cursor: pointer;
}
.property-details-address span{
    text-decoration: underline;
}
.renovation-date-tag{
    display: flex;
    column-gap: 5px;
    align-items: center;
   
}
.renovation-content{
    color: #7e7e7e;
    font-size: 13.5px;
}
.renovation-content p{
    margin-left: 29px;
}
.property-details-multi-section{
    display:grid;
    flex-direction: row;
    grid-template-columns: 3fr 1fr;
    column-gap: 20px;
}
.property-details-terms{
    display: grid;
    flex-direction: row;
    grid-template-columns: 2fr;

}

@media screen and (max-width: 1500px) {
    .property-details-multi-section{
        display:grid;
        flex-direction: row;
        grid-template-columns: 2fr 1fr;
        column-gap: 20px;
    }
}
@media screen and (max-width: 1100px) {
    .property-details-multi-section{
        display:grid;
        flex-direction: row;
        grid-template-columns: 2fr 1.5fr;
        column-gap: 20px;
    }
}
@media screen and (max-width: 992px) {
    .property-details-multi-section{
        display:grid;
        flex-direction: row;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }
}

@media screen and (max-width: 768px) {
    .property-details-multi-section{
        display:flex;
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 456px) {
    .property-details-address{
        font-size: 12.5px;
        align-items: center;
    }
}

@media screen and (max-width: 399px) {
    .property-details-address{
        font-size: 10px;
        align-items: center;
    }
}
@media screen and (max-width: 326px) {
    .property-details-address{
        font-size: 9.5px;
    }
}