.empty-basket__wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background-color: #81eaff38;
    border: 1px solid #008da9;
    border-radius: 2px;
    max-width: fit-content;
}
.empty-basket__wrapper__rows{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.empty-basket__wrapper__row{
    display: flex;
    gap: 10px;
    padding-left: 20px;
    align-items: center;
}
.empty-basket__wrapper__row a{
 color: #008da9;
}
.empty-basket__wrapper__row__message{
    color: #9b9b9b;
    font-size: smaller
   }
.empty-basket__wrapper__row a:hover{
    color: #008da9;
    text-decoration: underline !important;
}