.map-container{
    width: 100%;
    height: calc(100vh - 82px - 20px - 24px - 21px - 55px - 20px); 
}

.map-container__loading-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    animation: fadeInOut 2s infinite;
}

.map {
    height: calc(100vh - 82px - 20px - 24px - 21px - 55px - 20px); 
}

.map--loading{
    /* height: 0px !important; */
    display: none;
}

@keyframes fadeInOut {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}