.button-group{
    display: flex;
    font-size: inherit;
}

.button-group .dx-numberbox{
    /* border-right: none; */
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.button-group .dx-dropdownbutton .dx-button{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}