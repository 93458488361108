.custom-input{
    display: grid;
    /* grid-template-columns: auto 100%; */
    grid-template-columns: 20% calc(80% - 20px);
    gap: 20px;
    align-items: center;
}
.custom-input--vertical{
    align-items: flex-start !important;
}
/* .custom-input--large{
    grid-template-columns: 202px calc(100% - 222px);
} */
.custom-input .dx-texteditor-input, .custom-input--nolabel .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;
}
.custom-input .dx-widget,.custom-input--nolabel .dx-widget{
    font-size: inherit;
}
.custom-input__label{
    text-align: right;
    font-weight: 800;
    color: #515252;
    font-size: inherit;
}
.custom-input__container{
    width: 100%;
}
.custom-input__label span{
    color: rgb(221,103,99);
}

.custom-input__group-button{
    display: flex;
}
.custom-input__group-button--left .dx-textbox{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.custom-input__group-button--right .dx-textbox{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.custom-input__group-button input::placeholder, 
.custom-input input::placeholder, 
.custom-input--nolabel input::placeholder
{
    color: transparent;
}
.custom-input--placeholder input::placeholder{
    color: #999;
}

.pac-container-street{
    display: none;
}

@media screen and (max-width:1400px) {
    .custom-input{
        grid-template-columns: 30% calc(70% - 20px);
        gap: 20px;
    }
}
@media screen and (max-width:900px) {
    .custom-input{
        grid-template-columns: 25% calc(75% - 20px);
        gap: 20px;
    }
}
@media screen and (max-width:450px) {
    .custom-input{
        grid-template-columns: 35% calc(65% - 20px) !important;
        gap: 20px;
    }
}
