.layer-mask__quick-request {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 4rem auto 0 auto;
    flex-grow: 1;
}

.msg-container-success__quick-request {
    border-radius: 3px !important;
    width: 720px !important;
    height: auto !important;
    background-color: #eef9fd !important;
    border: 1px solid #42a6c9 !important;
    margin: 0;
    padding: 0;
}

@media screen and (min-width:320px) and (max-width: 767px) {
    .layer-mask__quick-request {
        padding: 0 2rem;
    }

    .msg-container-success__quick-request {
        width: auto !important;
        padding: 1.5rem;
    }
}