.guest-cards{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}


@media screen and (max-width: 440px){
    .guest-cards{
        grid-template-columns: 1fr;
    }
}