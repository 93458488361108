.custom-checkbox .dx-checkbox-text{
    /* background: #f4f4f4;  */
    font-size: 14px;
    font-family: 'Cabin', sans-serif !important; 
    color: #515252;
}
.custom-checkbox .dx-checkbox-icon{
    border-radius: 5px;
    border: solid #c8c8c8 1px;
    width: 17px;
    height: 17px;
}
.custom-checkbox .dx-checkbox-icon::before{
    border-radius: 5px;
    background-color: #008da9;
    color: #fff;
    font-size: 8px;
    width: 17px !important;
    position: relative !important;
    left: 3.5px !important;
    top: 3.9px !important
}
.custom-checkbox .dx-checkbox-checked .dx-checkbox-text{
    font-weight: 800;
}

/* @media screen and (max-width: 990px){
    .custom-checkbox .dx-checkbox-text{
        font-size: 12px;
    }
} */