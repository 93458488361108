.basketform{
    margin-top: 34px;
    min-height: 150px;
    padding: 30px;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(185, 185, 185);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.added-to-cart-text-block {
    border: 1px solid #8ec4d5;
    border-radius: 5px;
    padding: 20px;
    background-color: #e1ffff;
}
.remove-from-cart-block{
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-from-cart {
    border: none;
    background-color: transparent;
    color: red;
    text-decoration: underline;
}


.basketform__input {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.basketform__buttonarea{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.basketform__buttonarea button{
    margin-left: 30px;
    width: calc(100% - 47px);
    height: 40px;
    font-size: 18px;
}

.basketform__input--large{
    width: auto;
}

@media screen and (max-width: 768px) {
    .basketform{
        margin-top: 5px;
        margin-bottom: 10px;
    }
}