.section-subtitle{
    border-left: 3px solid;
    border-color: rgba(26, 147, 170, 0.90);
    padding:0 0 0 4px;
    margin: 10px 0;
    position: relative;
    /* left: -8px; */
    font-size: 14px;
    font-weight: 700;
    /* font-family: Brandon-Grotesque-Bold; */
    color:#515252
}