.custom-popup-gallery{
    position: fixed;
    /* display: flex;
    flex-direction: column; */
    background-color: rgba(255, 255, 255, 0.857);
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    z-index: 5000;
    overflow: hidden;
    transition: all .8s ease;
    /* padding: 30px; */
}
.custom-popup-gallery--active{
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all .8s ease;
}
.custom-popup-gallery__close{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 5px 10px 0 0;
}
.custom-popup-gallery__content{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    height: calc(100vh - 72px);
    padding: 20px 0 72px 0;
    /* border: 10px solid red; */
}
.custom-popup-gallery__content button{
    border: none;
   color: rgba(26, 147, 170, 0.90);
   background-color: transparent;

   /* background-color:rgba(26, 147, 170, 0.90) ; */
   border-radius: 100%;
   padding: 0 15px;
   font-size: 60px;
}
.custom-popup-gallery__close button{
    color: rgba(26, 147, 170, 0.90);
    background-color: transparent;
    border: none;
    font-size: 50px;
}
button:hover{
    color: rgba(20, 109, 127, 0.9);
}
.custom-popup-gallery__content__image{
    width: 80%;
    height: 100%;
    /* display: flex;
    justify-content: center; */
    /* display:flex;
    justify-content: center;
    align-items: center; */
    /* background-color: #fff; */
    /* transform: scale(.6); */

}
.custom-popup-gallery__content__image img{
    width: 100%;
    height: 100%; 
    object-fit: contain;
    object-position: center center;
    border-radius: 4px; 
}

.noVisible{
    visibility:hidden;
}
@media screen and (max-width: 1900px) {
    .custom-popup-gallery__content{
        column-gap: 50px;
        height: calc(100vh - 52px);
        padding: 20px 0 72px 0;
    }
    .custom-popup-gallery__content button{
       font-size: 45px;
    }

    .custom-popup-gallery__close button{
        font-size: 30px;
    }
}
@media screen and (max-width: 1100px) {

    .custom-popup-gallery__content button{
       font-size: 35px;
    }
}
@media screen and (max-width: 900px) {
   .custom-popup-gallery__content{
        column-gap: 10px;
        justify-content: space-between;
    }
}

@media screen and (max-width: 700px) {
    .custom-popup-gallery__content button{
        font-size: 30px;
     }
 
     .custom-popup-gallery__close button{
         font-size: 25px;
     }
 }

 @media screen and (max-width: 600px) {
    .custom-popup-gallery__content__image{
        width: 90%;
    }
    .custom-popup-gallery__content{
        column-gap: 5px;
    }
 }

 @media screen and (max-width: 500px) {
    .custom-popup-gallery__content__image{
        width: 95%;
        height: 90%;
    }
    .custom-popup-gallery__content{
        column-gap: 2px;
    }
    .custom-popup-gallery__content button{
        font-size: 25px;
     }
 
     .custom-popup-gallery__close button{
         font-size: 20px;
     }
 }

 @media screen and (max-width: 400px) {
    .custom-popup-gallery__content__image{
        width: 98%;
        height: 75%;
    }
    .custom-popup-gallery__content{
        column-gap: 0px;
    }
    .custom-popup-gallery__content button{
        font-size: 18px;
     }
 
     .custom-popup-gallery__close button{
         font-size: 20px;
     }
 }