.pagination-alt{
    display: flex;
    align-items: center;
    gap: 15px;
    color: #757575;
}
.pagination-alt span{
    min-width: 110px;
}
.pagination-alt__control-container{
    display: flex;
}
.pagination-alt__arrow-container{
    padding: 0 10px;
    cursor: pointer;
}
.pagination-alt__arrow--active{
    fill: #008da9 !important;
}
.pagination-alt__arrow--inactive{
    fill: #cdcdcd !important;
}
.pagination-alt__arrow-container:hover .pagination-alt__arrow--active{
    transform: scale(1.2);
}