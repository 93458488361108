/* Global Styles */

/* Font Family - Cabin */
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
    margin: 0;
    font-family: 'Cabin', sans-serif !important;
    overflow-inline: hidden;
}

a {
    text-decoration: none !important;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.section-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* .login-nav-item.navbar-collapse {
    min-width: 60px;
    flex-grow: unset !important;
} */

.me-auto.navbar-pages.navbar-nav {
    flex-grow: 1;
    justify-content: end;
}

.scrollbar-nav.mx-auto.navbar.navbar-expand-md.navbar-light.fixed-scrollbar{
    min-width: 90%;
}

@import './components/assets/styles/assets.css';
/* Pages */
@import './components/landing-page/Search/styles/search-styles.css';
/* Property */
@import './components/property-search/styles/property-search-styles.css';

.section-wrapper {
    width: 95%;
    max-width: 1185px;
    padding: 0;
    margin: auto;
}

.main-container {
    min-height: calc(100vh - 82px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sticky-top {
    z-index: 4500 !important;
}

.load-indicator-panel {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 255, 255, 0.1);
    z-index: 3001;
}

#load-indicator {
    position: fixed;
    left: 50%;
    top: 50%;
}

#load-indicator .dx-loadindicator-segment {
    background: #008da9;
}