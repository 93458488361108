.section-header-button{
    background-color: rgba(255, 255, 255, 1);
    color: rgba(26, 147, 170, 1);
    padding: 12px 27px;
    transition: all .6s;

}

.section-header-button:hover{
    transition: all .6s;
    border: solid 2px #fff;
    background-color: transparent;
    color: #fff;
}