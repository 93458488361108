.details-title{
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.details-title__section{
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
}

.details-title__section__name{
    font-size: 15px;
    font-weight: 500;
    color: #515252;
}

.details-title__section__buildingtype{
    /* display: flex;
    align-items: center; */
    padding: 0 10px;
    border-radius: 100px;
    font-size: 12px;
    color: #fff;
    background-color: #7e7e7e;
}
.details-title__renovation-date-tag-font{
    color: rgb(159, 159, 159);
    font-size: 14px;
}

@media screen and (max-width: 790px) {
    .details-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
@media screen and (max-width: 425px) {
    .details-title{
       font-size: smaller;
    }
    .details-title__renovation-date-tag-font{
        font-size: 12px;
    }
    .details-title__section__buildingtype{  
        font-size: 10px;
    }
    .details-title__section{
        column-gap: 5px;
    }
    .details-title__section__name{
        font-size: 14px;
    }
}
@media screen and (max-width: 368px) {
    .details-title__renovation-date-tag-font{
        font-size: 10px;
    }
    .details-title__section__buildingtype{  
        font-size: 9px;
    }
    .details-title__section{
        column-gap: 5px;
    }
    .details-title__section__img{
        width: 80px;
    }
}
