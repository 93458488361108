.property-sent{
    text-align: center;
}
.property-sent__title{
    font-size: 18px;
    font-weight: 700;
    color: #4b4b4b;
}
.property-sent__description{
    font-size: 15px;
    color: #4b4b4b;
}
.property-sent__buttonarea{
    margin-top: 10px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    
}

.property-sent__buttonarea span{
    font-size: 14px;
    color: #008da9;
    cursor: pointer;
}

.property-sent__buttonarea span:hover{
    color: #006a80;
}