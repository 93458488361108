.custom-confirmation-popup{
    background-color: #fff;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.custom-confirmation-popup__buttons{
    display: flex;
    gap: 30px;
}