.partner-level-card{
    width: 22em;
    border: 1px solid rgb(219, 219, 219);
    background-color: rgb(248,248,248);
    transition: all .6s;
}
.partner-level-card__title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    font-weight: 700;
}
.partner-level-card__title--gold{
    background-color: rgb(255,218,94);
}
.partner-level-card__title--silver{
    background-color: rgb(221,221,221);
}
.partner-level-card__title--bronze{
    background-color: #e3b399;
}
.partner-level-card__title--platinum{
    background-color: rgb(238,233,221);
}
.partner-level-card__content{
    padding: 15px 15px 10px 10px;
}

.partner-level-card__content ul{
    padding-left: 1rem;
}
.partner-level-card__content ul li{
    margin-bottom: 15px;
}
.partner-level-card__content ul li::marker{
    color: rgba(26, 147, 170);
}

.partner-level-card__buttonArea{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 20px;
}
.partner-level-card__buttonArea span{
    font-weight: 700;
}

.partner-level-card:hover{
    box-shadow: rgba(0, 0, 0, 0.132) 0px 8px 10px;
    transition: all .6s;
}