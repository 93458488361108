.favorite-button{
    stroke: rgb(176, 176, 176);
    fill: none;
}
.favorite-button--square{
    fill: #fff;
}
.favorite-button--active{
    fill: #d9534f
}

.favorite-button--small{
    transform: scale(.8);
}

