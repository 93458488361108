.dropdown-custom{
    width: 100%;
}

.dropdown-custom .dx-dropdownbox .dx-texteditor-input{
    font-family: 'Cabin', sans-serif !important; 
    color:#7b7b7b;
    font-size: 13px;
    border-left: 1px solid #eaeaea !important;
    border-top: 1px solid #eaeaea !important;
    border-bottom: 1px solid #eaeaea !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

}
.dropdown-custom .dx-dropdownbox .dx-texteditor-buttons-container{
    border-right: 1px solid #eaeaea !important;
    border-top: 1px solid #eaeaea !important;
    border-bottom: 1px solid #eaeaea !important;
    
}

.dx-dropdowneditor-overlay{
    z-index: 5001 !important;
}

@media screen and (max-width: 990px){
    .dropdown-custom .dx-dropdownbox .dx-texteditor-input{
        font-size: 12px;
    }
    .dropdown-custom__list{
        font-size: 12px;
    }
    .dx-dropdowneditor-overlay .dx-overlay-content .dx-popup-content{
        padding: 6px;
    }
}