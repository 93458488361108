.section-header{
    height: auto;
    /* background-image: url(../../../images/homeslide01.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding-top: 100px;
    padding-bottom: 60px;
}
.section-header__title{
    font-weight: 800;
    font-size: 60px;
}
.section-header__subtitle{
    font-style: italic;
    font-size: 35px;
}
.section-header__button{
    margin-top: 30px;
}

@media screen and (max-width: 1500px) {
    .section-header{
        gap: 10px;
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .section-header__title{
        font-size: 50px;
    }
    .section-header__subtitle{
        font-size: 30px;
    }
    .section-header__button{
        margin-top: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .section-header{
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .section-header__title{
        font-size: 45px;
    }
    .section-header__subtitle{
        font-size: 25px;
    }
    .section-header__button{
        margin-top: 18px;
    }
}

@media screen and (max-width: 800px) {
    .section-header{
        padding-top: 40px;
        padding-bottom: 30px;
    }
    .section-header__title{
        font-size: 40px;
    }
    .section-header__subtitle{
        font-size: 22px;
    }
    .section-header__button{
        margin-top: 16px;
    }
}

@media screen and (max-width: 600px) {
    .section-header{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .section-header__title{
        font-size: 35px;
    }
    .section-header__subtitle{
        font-size: 19px;
    }
    .section-header__button{
        margin-top: 16px;
    }
}