.custom-card-actions {
    /* font-family: Brandon-Grotesque-Regular; */
    display: grid;
    color: rgb(140, 140, 140);
    font-size: 13px;
    font-weight: 500;
    grid-template-columns: 1fr 1fr 1fr .5fr .5fr .5fr .5fr; 
    grid-template-areas: 
            "image title       title       title        includes  unavailable  unavailable  actions"
            "image description description description  includes  unavailable  unavailable  actions"
            "image description description description  includes  unavailable  unavailable  actions"
            "image description description description  includes  unavailable  unavailable  actions"
        ;
    padding: 15px 5px;
    border-bottom: solid rgba(141, 141, 141, 0.483) 1px;
    column-gap: 20px;
    /* align-items: stretch; */
    /* cursor: pointer; */
    /*width: 22em;
    height: 400px;*/
}
/* .custom-card-actions--fullsize{
    grid-template-columns: .5fr 1fr 1fr 1fr 1fr 1fr 1fr .5fr; 
    grid-template-areas: 
            "image title       title       title       title       includes includes favorite"
            "image description description description description includes includes favorite"
            "image description description description description includes includes favorite"
            "image description description description description includes includes favorite"
        ;
} */
.custom-card-actions:hover{
    color: rgb(140, 140, 140);
}
.custom-card-actions--selected{
    animation: custom-card-selected-animation 3s linear;
}

@keyframes custom-card-selected-animation {
    0%{
        box-shadow: #008da9 0px 3px 6px 0px inset,  #008da9 0px -3px 6px 1px inset;

    }
    100%{
        box-shadow: none;
    }
}
.custom-card__image{
    display: flex;
    align-items: center;
    grid-area: image;
}
.custom-card-actions--muted{
    opacity: 0.3;
}
.custom-card__title{
    font-size: 15px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    grid-area: title;
}
.custom-card__description{
    display: flex;
    flex-direction: column;
    row-gap: 4px;  
    grid-area: description;
}

.custom-card__description div {
    display: flex;
    column-gap: 5px;
}
.custom-card__includes{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    grid-area: includes;
}
.custom-card-actions--fullsize .custom-card__includes{
    display: grid;
    flex-direction: row-reverse;
    grid-template-columns: .7fr .5fr;
    grid-template-areas: "buildingtype amenities";
    
}
.custom-card__unavailable{
    grid-area: unavailable;
}
.custom-card__includes__amenities{
    margin-top: 4px;
    display: flex;
    column-gap: 10px; 
    grid-area: amenities;
}
.custom-card__includes__building-type{
    grid-area: buildingtype;
}
.custom-card-actions--fullsize .custom-card__includes__amenities{
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    height: fit-content;
    row-gap: 10px; 
}
.custom-card__actions{
    /*display: flex;
    align-items: flex-start;
    justify-content: flex-end;*/
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    /* text-align: end; */
    margin-top: 0;
    margin-right: 10px;
    grid-area: actions;
}
.custom-card__actions span, .custom-card__actions a{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #008da9;
}
.custom-card-actions .custom-card__actions__button{
    border-bottom: transparent solid 1px;
}
.custom-card__actions .custom-card__actions__button:hover{
    /* transform: scaleY(1.2); */
    border-bottom: #008da9 solid 1px;
}
.custom-card__actions span img, .custom-card__actions a img{
    height: 13px;
    width: 13px;
}
.custom-card__no-match{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(.8);
}
.custom-card__no-match__title{
    background-color: #d43f3a;
    color: #fff;
    width: fit-content;
    padding: 0px 12px;
    border-radius: 10px;
   
}
.custom-card__actions__modal{
    background-color: #fff;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.custom-card__actions__modal__buttons{
    display: flex;
    gap: 30px;
}


@media screen and (min-width: 1101px) {
    .custom-card-actions {
        grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr .5fr; 
    }
}

/* @media screen and (max-width: 1450px) {
    .custom-card-actions--fullsize{
        grid-template-columns: .5fr 1fr 1fr 1fr .5fr 1fr 1fr .5fr; 
        grid-template-areas: 
            "image title       title       title        includes  unavailable  unavailable  actions"
            "image description description description  includes  unavailable  unavailable  actions"
            "image description description description  includes  unavailable  unavailable  actions"
            "image description description description  includes  unavailable  unavailable  actions"
        ;
    }
} */
@media screen and (max-width: 1100px) and (min-width: 992px){
    .custom-card-actions {
        font-size: 9px;
        column-gap: 10px;

    }
    .custom-card__title{
        font-size: 11px;
    }
    .custom-card__title img{
        height: 20px;
    }
}
@media screen and (max-width: 1100px){
    /* .custom-card-actions--fullsize{
        font-size: 13px;
        grid-template-columns: .5fr 1fr 1fr 1fr 1fr 1fr .5fr; 
        grid-template-areas: 
                "image title       title       title       title       includes favorite"
                "image description description description description includes favorite"
                "image description description description description includes favorite"
                "image description description description description includes favorite"
            ;
    } */
    .custom-card-actions--fullsize .custom-card__includes{
        display: flex;
        flex-direction: column;
        grid-template-columns: .7fr .5fr;
        grid-template-areas: "buildingtype amenities";
        
    }
    .custom-card-actions--fullsize .custom-card__title{
        font-size: 15px;
    }

}
@media screen and (max-width: 725px) {
    .custom-card-actions {
        /* font-family: Brandon-Grotesque-Regular; */
        display: grid;
        color: rgb(140, 140, 140);
        font-size: 13px;
        font-weight: 500;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr .5fr .5fr; 
        grid-template-areas: 
                "image  title       title       title        unavailable  unavailable  actions"
                "image  includes    includes    includes     unavailable  unavailable  actions                                           "
                "image  description description description  unavailable  unavailable  actions"
                "image  description description description  unavailable  unavailable  actions"
                "image  description description description  unavailable  unavailable  actions"
            ;
        padding: 15px 5px;
        border-bottom: solid rgba(141, 141, 141, 0.483) 1px;
        column-gap: 20px;
        /* align-items: stretch; */
        /* cursor: pointer; */
        /*width: 22em;
        height: 400px;*/
    }
    .custom-card__actions{
        gap: 15px;
        justify-content: start;
    }
    .custom-card__actions__text{
        display: none !important;
    }
}
@media screen and (max-width: 600px) {
    /* .custom-card-actions {
        font-size: 9px;
        column-gap: 10px;
    } */
    .custom-card__includes, .custom-card__description{
        font-size: smaller;
    }  
}
@media screen and (max-width: 520px) {
    .custom-card-actions {
        /* font-family: Brandon-Grotesque-Regular; */

        grid-template-columns: 1fr 1fr 1fr 1fr .5fr; 
        grid-template-areas: 
                "image  title          title       title            actions"
                "image  unavailable    unavailable unavailable      actions"
                "image  includes       includes    includes         actions"
                "image  description    description description      actions"
                "image  description    description description      actions"
                "image  description    description description      actions"
            ;
    }
    .custom-card__no-match__list-item{
        display: none;
    }
}
@media screen and (max-width: 449px) {
 
    .custom-card__includes{
        /* flex-direction: row; */
        align-items: start;
        margin-bottom: 5px;
        font-size: 10px;
        column-gap: 5px;
    }
    /* .custom-card-actions--fullsize .custom-card__includes{
        align-items: flex-start;
    } */
}