.career-list__table{
    display: grid;
    border: 1.5px rgb(172, 172, 172) solid;
    border-bottom: none;
    width: 100%;
}
.career-list__table__header{
    color: #fff;
    display: grid;
}

.career-list__table__header div{
    background-color: rgba(26, 147, 170, 0.90);
    padding: 10px 5px;
    border-left: 1.5px #fff solid;
}
.career-list__table__header div:first-child{
    border-left: none;
}

.career-list__table__content .career-list__table__content__row{
    display: grid;
}

.career-list__table__content .career-list__table__content__row .career-list__table__content__column{
    padding: 10px 5px;
    border-bottom: 1.5px rgb(172, 172, 172) solid;
    border-left: 1.5px rgb(172, 172, 172) solid;
}
.career-list__table__content .career-list__table__content__row a{
    color: rgba(26, 147, 170, 0.90);
}
.career-list__table__content .career-list__table__content__row a:hover{
    color: rgb(26, 146, 170);
}
.career-list__table__content .career-list__table__content__row .career-list__table__content__column:first-child{
    border-left: none;
}

.career-list__table__content__column--actions{
    color: rgba(26, 147, 170, 0.90);
    display: flex;
    gap: 30px;
}
.career-list__table__content__column--actions span{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}
.career-list__table__content__column--actions span img{
    height: 16px;
    width: 16px;
}
.career-list__table__content__column--actions span:last-child{
    color: #d9534f;
}
.career-list__table__content__column--actions__delete svg{
    fill: #d9534f;
}
@media screen and (max-width: 600px) {
    .career-list__table__content__column--actions{
        flex-direction: column;
        gap: 20px;
    }
}