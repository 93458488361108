.action-button{
    /* width: 60px; */
    /* padding: 5px 15px; */
    border-radius: 2px;
    background: rgba(26, 147, 170, 0.90);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #45d9b1, rgba(26, 147, 170, 0.90));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #45d9b1, rgba(26, 147, 170, 0.90)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: rgb(237, 237, 237);
    box-shadow: 0.5px 0.5px 0.5px rgba(129, 129, 129, 0.607);
    /* font-family: Brandon-Grotesque-Regular; */
    border: none
}
.action-button:hover{
    color: #fff;
    box-shadow:none;
}