
.custom-dropdown-alt .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;

}
.custom-dropdown-alt .dx-widget{
    font-size: inherit;
}

.custom-dropdown-alt__label{
    color: #6f6f6f;
    font-size: inherit;
}

.custom-dropdown-alt__label span{
    color: rgb(221,103,99);
}
.dx-overlay-content .dx-popup-content{
    padding: 0;
}
.custom-dropdown-alt__list{
    font-size: inherit;
}
.custom-dropdown-alt__list .dx-item-content{
    color: #7b7b7b;
    font-size: inherit;
    background-color: #fff;
}

.custom-dropdown-alt__list .dx-list-item-selected .dx-item-content{
    background-color: #008da9;
    color: #fff;
}
.custom-dropdown-alt__list .dx-item-content:hover{
    background-color: #008da98e;
    color: #fff;
}
.custom-dropdown-alt__container{
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "inputIcon input";
    align-items: center;
    /* height: 100%; */
}
.custom-dropdown-alt__container--error{
    border: 1px solid #d9534f;
    border-radius: 4px;
}

.custom-dropdown-alt__container__message--error{
    position: absolute;
    z-index: 100;
    padding: 8px 10px;
    color: #fff;
    background-color: #d9534f;
    border-radius: 4px;
}
.custom-dropdown-alt__container__icon{
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 6px 0px 7px 10px;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    grid-area: inputIcon;
}
.custom-dropdown-alt--no-borders .custom-dropdown-alt__container__icon{
    border-top: none;
    border-left: none;
    border-radius: 0px;
    background-color: transparent;

}
.custom-dropdown-alt__container--noIcon .custom-dropdown-alt__container__icon{
    padding-left: 2px !important;
}
.custom-dropdown-alt__container .dx-texteditor{
    border-left: none;
    grid-area: input;
}
.custom-dropdown-alt--no-borders .custom-dropdown-alt__container .dx-texteditor{
    border-top: none;
    border-right: none;
    border-radius: 0px;
    background-color: transparent;

}

.dx-selectbox-popup-wrapper .dx-list-item-selected .dx-item-content{
    background-color: #008da9;
    color: #fff;
}
.dx-selectbox-popup-wrapper .dx-list-item .dx-item-content:hover{
    background-color: #008da98e;
    color: #fff;
}
.dx-state-hover ~ .custom-dropdown-alt__container__icon{
    border-color: rgba(51, 122, 183, .4);
}

.dx-state-focused ~ .custom-dropdown-alt__container__icon{
    border-color: #337ab7;
}

.dx-invalid ~ .custom-dropdown-alt__container__icon{
    border-color: rgba(217,83,79,.4);
}

.dx-invalid.dx-state-focused ~ .custom-dropdown-alt__container__icon{
    border-color: #d9534f;
}

.custom-dropdown-alt__container input{
    padding-left: 35.67px;
}
.custom-dropdown-alt__container .dx-placeholder{
    padding-left: 29.67px;
}

.custom-dropdown-alt__container--noIcon input{
    padding-left: 9px;
}
.custom-dropdown-alt__container--noIcon .dx-placeholder{
    padding-left: 3px;
}