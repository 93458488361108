.recommendations{
    margin-top: 20px;
}
.recommendations__list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 30px;
}

@media screen  and (max-width: 992px){
    .recommendations__list{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
    }
}