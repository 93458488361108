.information{
    font-size: 14px;
    /* font-family: Brandon-Grotesque-Regular; */
    color: #c0c0c0;
    align-items: center;
}
.information span{
    color: rgb(165, 0, 0);
    font-weight: 600;
    font-size: 12px;
}

.property-search{
    display: flex;
    column-gap: 10px;
    height: calc(100vh - 82px - 20px - 24px - 21px - 55px - 20px);
    margin-bottom: 20px;
}
.property-search-content{
    width: 58%;
}

.property-search-list{
    overflow-y: scroll;
    height: calc(100% - 42px);
}
.property-search-map{
    width: 42%;
    height: calc(100vh - 82px - 20px - 24px - 21px - 55px - 20px); 
}
.property-search__list__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.results-container{
    display: flex;
    align-items: center;
}

.results-container-description{
    color: rgba(0, 0, 0, 0.483);
    /* font-family: Brandon-Grotesque-Regular; */
    font-size: 15px;
}
.property-search__card-type{
    display: flex;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #008da9;
    width: fit-content;
    height: fit-content;
}
.property-search__card-type__btn-line, .property-search__card-type__btn-square{
    display: flex;
    align-items: center;
    padding: 5px 7px;
    fill:#008da9;
    cursor: pointer;
}
.property-search__card-type__btn-line svg, .property-search__card-type__btn-square svg{
    transform: scale(1.4);
}
.property-search__card-type__btn-line--active, .property-search__card-type__btn-square--active{
    background-color: #008da9;
    fill: #fff;
    /* border-bottom-right-radius: 3px;
    border-top-right-radius: 3px; */
}

@media screen and (max-width:880px){
    .property-search{
        flex-direction: column;
    }
    .property-search-content{
        width: 100%;
        height: 100%;
    }
    .property-search-map{
        width: 100%;
        height:100%;
    }
    .property_search_view--inactive{
        /* max-height: 0px;
        overflow: hidden;
        transition: all .5 ease; */
        display: none;
    }
    .property_search_view--active{
        /* max-height: 2000px;
        overflow: auto;
        transition: all .5 ease; */
        display: auto;
    }
}