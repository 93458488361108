.custom-slider-container{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.custom-slider-container .slider-container__images{
    display: flex;
    column-gap: 10px;
    overflow-x: auto;
}
.custom-slider-container .slider-container__images::-webkit-scrollbar{
    display: none;
}
.slider-container__images__image{
    min-width: 100px;
    height: 100px;
}

.custom-slider-container .slider-container__images img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: .5px solid #aaaaaa;
    cursor: pointer;
}

.custom-slider-container__arrow{
    cursor: pointer;
}
