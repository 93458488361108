.news-section {
    width: 100%;
    height: auto;
    min-height: 100vh;
}

.news-section-bg-container {
    width: auto;
    height: auto;
}

.news-section-bg {
    width: auto;
    height: auto;
    min-height: 370px;
    background: url(../../../images/bg_news.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news-section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    color: #fff;
}

.news-section-title h1 {
    font-size: 60px;
}

.news-section-title h3 {
    font-size: 40px;
    font-weight: 200 !important;
    font-style: italic;
}

.news-section-content {
    width: 100%;
    height: auto;
}

.news-section-article {
    padding: 0 0 18px 0;
    border-bottom: 1px solid #eaeaea;
}

.news-section-article img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.news-section-article__title {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #008da9;
}

.news-section-article__title>div>div {
    font-size: 24px;
}

.news-section-article__date-author-info {
    font-weight: 200;
    color: #939393;
    font-style: italic;
}

.news-section-article__summary {
    color: #525252;
}

.news-section-article__summary>p>span {
    font-size: 16px !important;
    font-weight: 200 !important;
}

.text-oasis {
    color: #008da9;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
}

.text-oasis:hover {
    color: #008da9;
}

.news-section-article__read-more {
    color: #008da9;
}

.news-section-article__read-more {
    color: #008da9;
}

.delete-btn {
    background: none;
    color: #dc3545;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
}

.delete-btn:hover {
    color: #dc3545;
}

.eye-icon,
.pencil-icon,
.trash-icon {
    width: 16px !important;
    height: 16px !important;
}

.img-container {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
}

.img-container img {
    width: 295px !important;
    height: 184px !important;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    body {
        overflow-x: hidden !important;
    }

    .img-container {
        margin: 0 0 1rem 0;
        width: 260px;
    }

    .img-container img {
        width: 90%;
    }
}

@media screen and (min-width: 480px) and (max-width: 991px) {
    .img-container {
        margin: 0 0 1rem 0;
    }

    .img-container img {
        width: 100%;
    }
}

/* Breakpoint for Mockup - Do not Remove */
@media screen and (min-width: 1400px) and (max-width: 1499px) {

    .breakpoint,
    .admin-breakpoint {
        padding: 0 0 0 5rem !important;
    }
}

@media screen and (min-width: 1500px) and (max-width: 1799px) {

    .breakpoint,
    .admin-breakpoint {
        padding: 0 0 0 4rem !important;
    }
}

@media screen and (min-width: 1800px) and (max-width: 1919px) {

    .breakpoint,
    .admin-breakpoint {
        padding: 0 0 0 4rem !important;
    }
}