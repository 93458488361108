.custom-footer__container{
    width: 90% !important;
    padding: 0px !important;
}

.custom-footer__container__images{
    display: flex;
    align-items: center;
    gap: 10%;
}

.custom-footer__container__images__world-wide-logo{
    height: 69px;
}

.custom-footer__container__info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    gap: 5px;
}

.custom-footer__container__info__footer-nav a{
    color: #45d9b1;
}
.custom-footer__container__info p{
    margin: 0px;
}


.custom-footer__container__info__copyright{
    color: #cbcbcb;
}

@media screen and (max-width: 950px) {
    .custom-footer__container__images__world-wide-logo{
        height: 55px;
    }
    .custom-footer__container__images__chpa-logo{
        height: 55px;
    }
    .custom-footer__container__images{
        gap: 4%;
    }
}

@media screen and (max-width: 750px) {
    .custom-footer__container__images__world-wide-logo{
        height: 40px;
    }
    .custom-footer__container__images__chpa-logo{
        height: 40px;
    }
    .custom-footer__container__images{
        gap: 4%;
    }
    .custom-footer__container__info{
        font-size: smaller
    }
}

@media screen and (max-width: 552px) {
    .custom-footer__container{
        /* justify-content: center !important; */
        padding: 10px 0px !important;
        gap: 20px;
    }
    .custom-footer__container__images{
        width: 100%;
        justify-content: space-between;
    }
    .custom-footer__container__info{
        width: 100%;
    }
}