.navbar-tab-container{
    height: 100%;
    display: flex;
    align-items: center;
}
.activeTab{
    border-bottom: 3px #45d9b1 solid;
}
.logo-blue {
    text-align: center;
    background: url(../../../images/logo-blue.png) top left;
    background-size: cover;
    height: 50px;
    width: 50px;
}

.bg-dark-transparent {
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.navbar {
    height: 82px;
    padding: 0 !important;
    font-size: .85rem !important;
}
/* .navbar-brand{
    padding: 1rem 0 !important;
} */
.container-fluid{
    height: 100% !important;
}
.navbar-collapse{
    height: 100% !important;
    /* column-gap: 5px !important; */
}
.navbar-collapse .navbar-pages{
    display: flex;
    align-items: center;
    height: 100% !important;
}
.navbar-collapse .navbar-pages a{
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px 18px !important;
}
.navbar-collapse .navbar-pages .activeTab a{
    padding-top: 3px !important;
}
.nav-bar-styles {
    width: 90% !important;
    padding: 0px !important;
}

.navbar-pages a {
    margin: 0 0 0 18px;
}

.box-shadow {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.50);
}

.nav-link {
    /* font-size: 1.5rem; */
}

.navbar-toggler {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.navbar-toggler:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

/* Auth Nav - Disbale DropDown Icon */
.dropdown-toggle::after {
    display: none !important;
}

/* Auth Nav - User Icon */
.fa-heart:before,
.fa-shopping-cart:before,
.fa-user:before {
    font-size: 24px;
}

/* Auth Nav - remove bootstrap default */
.dropdown-item {
    background-color: transparent !important;
}

/* Auth-Nav - Menu Box Styles */
.auth-container{
   height: 100% !important;
   display: flex;
   align-items: center;
   justify-content: center;
}
.auth-nav-styles .dropdown-menu {
    min-width: 5rem !important;
}


@media (max-width:1199.98px){
    .navbar {
        height: auto;
    }
    .navbar-brand{
        height: 82px !important;
        padding: 0px !important;
        display: flex;
        align-items: center;
    }
    .auth-container{
        width: 100% !important;
        margin-left: 18px !important;
        margin-top: 20px;
        display: flex;
        flex-direction: column !important;
        align-items: start !important;
        justify-content: start !important;
     }
    .auth-container .login-nav-item{
        width: 100%;
    }
    .auth-nav-styles .dropdown-menu{
        min-width: 100% !important;
    }
    .navbar-collapse{
        width: 100% !important;
    }
    .navbar-collapse .navbar-pages{
        display: flex;
        gap: 20px;
        align-items: start !important;
        height: 100% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .navbar-pages {
        margin: 0 !important;
    }
    .nav-link {
        font-size: 1rem;
      }
}

@media screen and (min-width: 768px) and (max-width: 796px) {
    .navbar-pages {
        margin: 0 !important;
    }
    .nav-link {
        font-size: .55rem;
    }
    .nav-bar-styles .navbar-collapse{
        column-gap: 5px !important;
    }
    .nav-bar-styles .navbar-collapse .auth-container{
        transform: scale(.75);
    }
}

@media screen and (min-width: 797px) and (max-width: 874px) {
    .navbar-pages {
        margin: 0 !important;
    }
    .nav-link {
        font-size: .58rem;
    }
    .nav-bar-styles .navbar-collapse{
        column-gap: 15px !important;
    }
    .nav-bar-styles .navbar-collapse .auth-container{
        transform: scale(.8);
    }
}

@media screen and (min-width: 875px) and (max-width: 990px) {

    .nav-link {
        font-size: .70rem;
    }
    .nav-bar-styles .navbar-collapse{
        column-gap: 15px !important;
    }
    .nav-bar-styles .navbar-collapse .auth-container{
        transform: scale(.9);
    }
}
