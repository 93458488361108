.my-requests {
    width: 100%;
    min-height: 100vh;
    height: auto;
}

.request-title {
    font-size: 18px;
    color: #6c757d !important;
}
.request-tabs .nav-link{
    font-size: 17px;
}
.request-tab {
    font-weight: 700;
    border-left: 3px solid #008da9;
    padding: 0;
    margin: 0;
}

.request-status-pending {
    background-color: #cdcdcd;
    border: 1px solid #cdcdcd;
    border-radius: 36px;
    width: 170px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    text-align: center;
}

.request-status-unavailable {
    color: #fff;
    background-color: #d43f3a;
    border: 1px solid #d43f3a;
    border-radius: 36px;
    width: 100px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    text-align: center;
}

.request-status-booked {
    background-image: linear-gradient(to bottom, #45d9b1, #008da9);
    color: #fff;
    background-color: #008da9;
    border: 1px solid #008da9;
    border-radius: 36px;
    width: 72px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    text-align: center;
    font-size: 12px;
}

.request-border {
    border-bottom: 1px solid #cdcdcd;
}

.request-green {
    font-size: 12px !important;
    color: #008da9;
}

.request-pointer {
    cursor: pointer !important;
}

.booked-title-text {
    font-weight: 700 !important;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.booked-option-text {
    font-weight: 200 !important;
    color: #6c757d !important;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.instructions-btn {
    background-image: linear-gradient(to bottom, #45d9b1, #008da9);
    border: 1px solid #45d9b1;
    border-radius: 3px;
    color: #fff;
    width: 200px;
    height: 45px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    .request-title {
        font-size: 14px;
    }

    .request-nav ul li a {
        font-size: 10px;
    }

    .request-status-pill,
    .request-option-btns {
        padding: 2rem;
    }

    .request-status-pill,
    .request-status-pill>div,
    .request-option-btns,
    .request-option-btns>div {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0 auto !important;
    }
}

/* Request Tabs */

.request-tabs.nav.nav-tabs {
    border-bottom: none !important;
}

.request-tabs.nav.nav-tabs li button {
    margin: 0 1rem 0 0 !important;
    padding: 0 0.4rem !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: 2px solid #008da9 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    border: transparent !important;
    color: inherit !important;
}

.fade.tab-pane.active.show {
    border: none !important;
}

@media screen and (min-width: 480px) and (max-width: 575px) {

    .request-info,
    .request-status-pill,
    .request-option-btns {
        padding: 1rem;
    }

    .request-status-pill,
    .request-status-pill>div,
    .request-option-btns,
    .request-option-btns>div {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0 auto !important;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1700px) {
    .request-xxl-breakpoint {
        padding: 0 0 0 5rem !important;
    }
}

@media screen and (min-width: 1701px) {
    .request-xxl-breakpoint {
        padding: 0 0 0 3rem !important;
    }
}