.request-option__details-main__container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: smaller;
    color: #515151;
    margin-bottom: 20px;
}

.request-option__details-main__container__main-information{
    /* font-size: smaller; */
    display: grid;
    gap: 1%;
    grid-template-columns: 33% 66%;
    height: fit-content;
}
.request-option__details-main__container__title-area{
    display: flex;
    justify-content: space-between;
}
.request-option__details-main__container__main-information__gallery,.request-option__details-main__container__main-information__information{
    height: 350px;
}

.request-option__details-main__container__main-information__information{
    overflow-y: auto;
}

.request-option__details-main__container__main-information__description p{
    margin-bottom: 0px;
}

.request-option__details-main__container__main-information__renovation__date{
    display: flex;
    gap: 5px;
    align-items: center;
}

.request-option__details-main__container__main-information__renovation__date img
{
    width: 22px;
    height: 19px;
}

.request-option__details-main__container__main-information__amenities__content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.request-option__details-main__container__main-information__amenities__content__section{
    display: flex;
    gap: 20vw;
}
.request-option__details-main__container__main-information__amenities__content__section__item{
    display: flex;
    gap: 10px;
}

.request-option__details-main__container__main-information__amenities__content__section__item__missing{
    font-style: italic;
    color: #939393;
}

@media screen  and (max-width: 1200px){
    .request-option__details-main__container__main-information{
        grid-template-columns: 40% 60%;
        font-size: small;
    }
    .request-option__details-main__container__main-information__amenities__content__section{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}

@media screen  and (max-width: 900px){
    .request-option__details-main__container__main-information{
        grid-template-columns: 100%;
    }
    .request-option__details-main__container__main-information__information{
        height: fit-content;
        overflow:hidden;
    }
    .request-option__details-main__container__title-area{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
}
