.custom-number-range__label{
    color: #6f6f6f;
    font-size: inherit;
}
.custom-number-range__label span{
    color: rgb(221,103,99)
 }
 .custom-input-alt__container .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;
}
.custom-number-range__numbers{
    display: flex;
    gap: 10px;
    color: #7b7b7b;
    font-size: inherit;
    align-items: center;
}
.custom-number-range__numbers .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;
}
/* .custom-number-range__numbers .dx-texteditor{
    width: 120px;
} */

.custom-number-range__numbers__separator{
    width: 10px;
    height: 1px;
    background-color: #a5a5a5;
}