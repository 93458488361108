.custom-nav-tabs{
    display: flex;
    gap: 10px;
    cursor: pointer;
    transition: all .5s;
}

.custom-nav-tab{
    font-size:.9em;
    padding: 0 10px;
    margin-bottom: 3px;
}

.custom-nav-tab--active{
    transition: all .5s;
    border-bottom: 3px solid #008da9;
    margin-bottom: 0;
}
