.new-york {
    background: url('../../../../images/landing/cities/featured-newyork.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .new-york:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-newyork.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.los-angeles {
    background: url('../../../../images/landing/cities/featured-losangeles.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .los-angeles:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-losangeles.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.paris {
    background: url('../../../../images/landing/cities/featured-paris.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .paris:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-paris.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.miami {
    background: url('../../../../images/landing/cities/featured-miami.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .miami:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-miami.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.sydney {
    background: url('../../../../images/landing/cities/featured-sydney.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .sydney:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-sydney.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.london {
    background: url('../../../../images/landing/cities/featured-london.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .london:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-london.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.singapore {
    background: url('../../../../images/landing/cities/featured-singapore.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 360px;
}

    .singapore:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../../images/landing/cities/featured-singapore.jpg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform-origin: center;
        width: 100%;
        height: 360px;
        cursor: pointer;
    }

.new-york>a>span,
.los-angeles>a>span,
.paris>a>span,
.miami>a>span,
.sydney>a>span,
.london>a>span,
.singapore>a>span {
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
}

.new-york>a,
.los-angeles>a,
.paris>a,
.miami>a,
.sydney>a,
.london>a,
.singapore>a {
    transition: all 0.5s linear;
    transition-delay: 0.5s;
}

.new-york:hover>a>span,
.los-angeles:hover>a>span,
.paris:hover>a>span,
.miami:hover>a>span,
.sydney:hover>a>span,
.london:hover>a>span,
.singapore:hover>a>span {
    border: 8px solid rgba(69, 217, 177, 1);
    padding: 0.8rem;
}

.featured-cities-container {
    background-color: #fff !important;
}

/* Media Queries */

@media screen and (min-width: 320px) and (max-width: 340px) {

    .new-york>a>span,
    .los-angeles>a>span,
    .paris>a>span,
    .miami>a>span,
    .sydney>a>span,
    .london>a>span,
    .singapore>a>span {
        font-size: 14px;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .set-row {
        display: flex !important;
        flex-direction: column !important;
    }

    .set-row-col {
        width: 100% !important;
        padding: 0 2rem !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .set-row-col {
        width: 50% !important;
    }
}

/* image-grid-container media queries */
@media screen and (min-width: 1920px) {
    .image-grid-container {
        padding: 0 5% 2rem 5% !important;
        margin: 0 auto !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1919px) {
    .image-grid-container {
        padding: 0 5% 2rem 5% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .image-grid-container {
        padding: 0 5% 2rem 5% !important;
        overflow: hidden;
    }
}