.property-filter__content__form {
    background: #f5f5f5; 
    font-size: 14px;
    color: #515252;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
.property-filter__content__form__fields{
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: 1fr .5fr 1fr 1fr 1fr;
}
.property-filter__content__button{
    display: none;
    background-color: #008da9;
    color: #fff;
    width: fit-content;
    padding: 8px 20px;
    border-top-left-radius: 10px;
    cursor: pointer;
}
.property-filter__content__form__fields__location{
    position: relative;
}
.property-filter__content__form__fields__location,.property-filter__content__form__fields__radius,.property-filter__content__form__fields__building,.property-filter__content__form__fields__floor,.property-filter__content__form__fields__pets{
    width: 100%;
}
.property-filter__content__form__fields__location__placeholder-layout{
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: #747474d4;
    position: absolute;
    color: #fff;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 1309px){
    .property-filter__content__form__fields{
        display: grid;
        align-items: center;
        gap: 20px;
        grid-template-columns: 1fr .5fr 1fr 1fr .5fr;
    }
}
@media screen and (max-width: 1175px){
    .property-filter__content__form__fields{
        display: grid;
        align-items: center;
        gap: 20px;
        grid-template-columns: 1fr .65fr 1fr 1fr;
    }
}
@media screen and (max-width: 828px){
    .property-filter__content__form__fields{
        display: grid;
        align-items: center;
        gap: 20px;
        grid-template-columns: .9fr .7fr .9fr .9fr;
    }
}
@media screen and (max-width: 767px){
    .property-filter{
        margin-bottom: 10px;
    }
    .property-filter__title{
        display: none;
    }
    .property-filter__content__button{
        display: block;
       
    }
    .property-filter__content__button--inactive{
        border-bottom-left-radius: 10px;
        padding: 9px 15px;
    }
    .property-filter__content{
        position: fixed;
        right: 0px;
        top: 92px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .property-filter__content__form{
        padding: 0px;
        background: transparent;
    }
    .property-filter__content__form--active{
        max-height: 2000px;
        overflow: auto;
        transition: all 1s ease;
    }
    .property-filter__content__form--inactive{
        max-height: 0px;
        overflow: hidden;
        transition: all .1s ease;
    }

    .property-filter__content__form__fields{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #008da9;
    }
    .property-filter__content__form__fields__pets{
        padding-left: 10px;
    }
}
