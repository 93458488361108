.request-modal-content{
    display: flex;
    flex-direction: column;
    padding: 50px;
    gap: 25px;
    color: #6b6b6b;
}
.request-modal-content .request-modal-content__title{
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
    color: #3c3c3c;
    font-weight: 800;
}

.request-modal-content .request-modal-content__buttons{
    display: flex;
    gap: 20px;
}