
.position--center {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Google autocomplete */
.pac-container {
    /* box-sizing: content-box; */
    /* border: 5px solid #7b7b7b3c;*/
    background-color: #ffffff;
    border: 1px solid #7b7b7b7c; 
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important; */
    /* filter: drop-shadow(0 0 0.75rem #7b7b7b27); */
    /* box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.25); */
    box-shadow: none;
}


.pac-item {
    font-family: 'Cabin', sans-serif; 
    text-transform: capitalize;
    color: #7b7b7b;
    font-size: 14px;
}

.pac-icon {
    transition: all 0.5s;
}
