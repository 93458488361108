.requests-filter{
    display: flex;
    gap: 10px;
    color: #4b4b4b;
    font-size: .8rem;
}

.requests-filter span:first-child{
    display: flex;
    align-items: center;
    font-weight: 700;
}

.requests-filter_item{
    cursor: pointer;
    border: 1px solid #a2a2a2;
    color: #a2a2a2;
    padding: 0px 10px;
    border-radius: 12px;
}
.requests-filter_item--inactive:hover{
    transform: scale(1.1);
}
.requests-filter_item--active{
    color: #008da9;
    border: 2px solid #008da9;
    font-weight: 600;
}
.requests-filter_separation{
    border-left: 2px solid #a2a2a2;
}