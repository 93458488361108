.custom-alert__wrapper{
    display: flex;
    justify-content: center;
    padding: 10px 50px;
    border: solid 1px #42a6c9;
    background-color: #eef9fd;
    border-radius: 3px;
}

.custom-alert__wrapper--error{
    border: solid 1px #c94242;
    background-color: #fdeeee;
}

.custom-alert__wrapper--success{
    border: solid 1px #42c954;
    background-color: #eefdf5;
}
