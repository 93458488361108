.maintenance-page-container{
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
}

.maintenance-container {
    border: 1px solid #42a6c9;
    border-radius: 3px;
    background-color: #eef9fd;
    color: #535454;
    width: 600px !important;
    /*    height: auto !important;*/
    padding: 1rem 0;
}

.maintenance-link {
    color: #42a6c9;
}

.server-icon-container {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

.server-icon {
    width: 35px;
    height: 38px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .maintenance-container {
        width: 180px !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 567px) {
    .maintenance-container {
        width: 360px !important;
    }
}

@media screen and (min-width: 568px) and (max-width: 720px) {
    .maintenance-container {
        width: 450px !important;
    }
}