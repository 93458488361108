.custom-dropdown{
    display: grid;
    grid-template-columns: 20% calc(80% - 20px);
    gap: 20px;
    align-items: center;
}
/* .custom-dropdown--large{
    grid-template-columns: 200px calc(100% - 220px);
} */

.custom-dropdown--nolabel{
    grid-template-columns: 100% !important
}
.custom-dropdown .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;

}
.custom-dropdown .dx-widget{
    font-size: inherit;
}

.custom-dropdown__label{
    text-align: right;
    font-weight: 800;
    color: #515252;
    font-size: inherit;
}

.custom-dropdown__label span{
    color: rgb(221,103,99);
}
.dx-overlay-content .dx-popup-content{
    padding: 0;
}
.custom-dropdown__list{
    font-size: inherit;
}
.custom-dropdown__list .dx-item-content{
    color: #7b7b7b;
    font-size: inherit;
    background-color: #fff;
}

.custom-dropdown__list .dx-list-item-selected .dx-item-content{
    background-color: #008da9;
    color: #fff;
}
.custom-dropdown__list .dx-item-content:hover{
    background-color: #008da98e;
    color: #fff;
}
.custom-dropdown__container--error .dx-texteditor-container{
    border: 1px solid #d9534f;
}
.custom-dropdown__container__message--error{
    position: absolute;
    z-index: 100;
    padding: 8px 10px;
    color: #fff;
    background-color: #d9534f;
    border-radius: 4px;
}
.dx-selectbox-popup-wrapper .dx-list-item-selected .dx-item-content{
    background-color: #008da9;
    color: #fff;
}
.dx-selectbox-popup-wrapper .dx-list-item .dx-item-content:hover{
    background-color: #008da98e;
    color: #fff;
}
@media screen and (max-width:1400px) {
    .custom-dropdown{
        grid-template-columns: 30% calc(70% - 20px) ;
        gap: 20px;
    }
    .custom-dropdown--nolabel{
        grid-template-columns: 100% !important
    }
}
@media screen and (max-width:900px) {
    .custom-dropdown{
        grid-template-columns: 25% calc(75% - 20px) ;
        gap: 20px;
    }
    .custom-dropdown--nolabel{
        grid-template-columns: 100% !important
    }
}
@media screen and (max-width:450px) {
    .custom-dropdown{
        grid-template-columns: 35% calc(65% - 20px) !important;
        gap: 20px;
    }
    .custom-dropdown--nolabel{
        grid-template-columns: 100% !important
    }
}