.faq {
    width: 100%;
    min-height: 100vh;
    height: auto;
    overflow: hidden;
}

.faq-bg {
    background-image: url(../../../images/faq-img.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 604px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq-text {
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: -13rem auto 0 auto;
}

.faq-accordion {
    margin: 4rem auto;
}

.accordion-item {
    border: none !important;
}

.accordion-header {
    padding: 0.8rem !important;
    background-color: #f8f8f8 !important;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.27);
}

.accordion-header button strong {
    border-left: 4px solid #2A8FAB;
    padding: 0 0px 0 9px;
}

button.accordion-button,
button.accordion-button.collapsed {
    color: #000 !important;
}

button.accordion-button::after {
    background: url(../../../images/arrow_down.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

button.accordion-button.collapsed::after {
    background: url(../../../images/arrow_down.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

button.accordion-button:focus {
    box-shadow: none !important;
    border: none !important;
}

button.accordion-button:not(.collapsed) {
    background-color: #f8f8f8 !important;
    font-weight: bold;
    color: #000 !important;
}

button.accordion-button.collapsed {
    background-color: #f8f8f8 !important;
    font-weight: bold;
    color: #000 !important;
}

.list ul li {
    list-style-type: square;
}

.list ul li::marker {
    color: #2A8FAB;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    .accordion-header strong {
        font-size: 12px;
    }

    .accordion-body p {
        font-size: 12px;
    }

    .list {
        flex-wrap: wrap;
    }

    .list ul {
        margin: 0 auto !important;
    }

    .list ul li {
        font-size: 9px;
    }
}

@media screen and (min-width: 480px) and (max-width: 991px) {
    .list {
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 901px) and (max-width: 1080px) {
    .oasis-logo {
        margin: 0 1rem 0 0;
    }
}