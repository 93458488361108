.gallery,.gallery--1,.gallery--2,.gallery--3,.gallery--4{
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
    aspect-ratio: 4/2;
}

.gallery--1 .gallery__image{
    grid-column: 1/5;
    grid-row:1/3;
}

.gallery--2 .gallery__image{
    grid-column: span 2;
    grid-row:1/3;
}

.gallery--3 .gallery__image:first-child{
    grid-column: 1/3;
    grid-row:1/3;
}
.gallery--3 .gallery__image{
    grid-column: span 2;
}

.gallery--4 .gallery__image:first-child{
    grid-column: 1/3;
    grid-row:1/3;
}

.gallery--4 .gallery__image:nth-child(2){
    grid-column: 3/5;
    grid-row:1/2;
}

.gallery .gallery__image:first-child{
    grid-column: 1/3;
    grid-row:1/3;
}

.gallery__image{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(185, 185, 185);
}
.gallery__image  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.gallery__image__more{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.556);
    color: #fff;
    height: 100%;
    width: 50%;
    margin-left: 50%;
    display: flex;
    font-size: 25px;
    text-decoration: underline;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gallery__image__more--smaller{
    font-size: 18px;
}
/* @media screen and (max-height: 960px) and (min-width: 1000px){
    .gallery,.gallery--1,.gallery--2,.gallery--3,.gallery--4{
        height: 400px;
    }
}
@media screen and (max-height: 960px) and (min-width: 1400px){
    .gallery,.gallery--1,.gallery--2,.gallery--3,.gallery--4{
        height: 500px;
    }
} */

@media screen and (max-height:  990px) and (min-height: 680px) and (min-width:792px){
    .gallery,.gallery--1,.gallery--2,.gallery--3,.gallery--4{
        height: calc(100vh - (182px + 190px));
        aspect-ratio: auto;
    }
}

@media screen and (max-width: 992px) {
    .gallery,.gallery--1,.gallery--3,.gallery--4{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .gallery--2{
        grid-template-columns: 1fr 1fr;
    }
    .gallery--1 .gallery__image{
        grid-column: 1/4;
        grid-row:1/3;
    }
    .gallery--2 .gallery__image{
        grid-row: 1/3;
        grid-column: auto/auto;
    }
    .gallery .gallery__image:first-child,.gallery--3 .gallery__image:first-child,.gallery--4 .gallery__image:first-child{
        grid-column: 1/3;
        grid-row:1/3;
    }
    .gallery .gallery__image,.gallery--3 .gallery__image,.gallery--4 .gallery__image{
        grid-column: span 1;
    }
    .gallery--4 .gallery__image:nth-child(2){
        grid-column: span 1;
    }
}

@media screen and (max-width: 800px) {
    .gallery__image__more{
        font-size: 20px;
    }
    .gallery__image__more--smaller{
        font-size: 13px;
    }
}

@media screen and (max-width: 600px) {
    .gallery__image__more{
        font-size: 16px;
    }
    .gallery__image__more--smaller{
        font-size: 12px;
    }
}
@media screen and (max-width: 466px) {
    .gallery,.gallery--1,.gallery--2,.gallery--3,.gallery--4{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .gallery .gallery__image:first-child,.gallery--3 .gallery__image:first-child,.gallery--4 .gallery__image:first-child{
        grid-column: 1/2;
        grid-row:1/3;
    }
}
@media screen and (max-width: 400px) {
    .gallery__image__more{
        font-size: 15px;
    }

}




