.full-story {
    width: 100%;
    height: auto;
    min-height: 85vh;
}

.link-color {
    color: #42a6c9;
}

/* article */
.article-title h1 {
    font-size: 20px;
}

.article-date {
    color: #979797;
    font-size: 12px;
}

.article-body {
    font-weight: 200 !important;
    line-height: 1.3;
}

.article-body>p>span {
    font-size: 14px !important;
}

/* comments */
.comment-date {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #979797;
    font-size: 12px;
}

.comment {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #979797;
    margin: 0 0 1rem 0;
}


.comments-border {
    border-left: 3px solid #008da9;
}

.comment-dotted-border {
    border-bottom: 1px dotted #979797;
    margin: 0.4rem 0 0 0;
}

.comment-quotes {
    color: #008da9;
}

.coment-body {
    font-size: 12px;
}

.comment-author {
    font-size: 10px;
    color: #979797;
}

.comment-submit-btn-container {
    margin: 0 0 0 8rem;
}

.comment-submit-btn {
    background-color: #008da9 !important;
}

.comment-label label span {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    font-weight: 700 !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .comment-flex {
        flex-wrap: wrap;
    }

    .comment-label label span {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: flex-end !important;
        font-weight: 700 !important;
        margin: 0 0 0.2rem 0 !important;
    }

    .comment-submit-btn-container {
        margin: 0;
    }
}