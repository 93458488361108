.custom-textarea-alt{
    display: flex;
    flex-direction: column;
}
.custom-textarea-alt .dx-texteditor-input, .custom-textarea-alt--nolabel .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;

}
.custom-textarea-alt__label{
    color: #6f6f6f;
    font-size: inherit;
}

.custom-textarea-alt__label span{
    color: rgb(221,103,99);
}
.custom-textarea-alt .dx-htmleditor, .custom-textarea-alt--nolabel .dx-htmleditor{
    color: #7b7b7b;
    font-size: inherit;
}
.custom-textarea-alt .dx-htmleditor .dx-htmleditor-toolbar-wrapper, .custom-textarea-alt--nolabel .dx-htmleditor .dx-htmleditor-toolbar-wrapper{
    background-color: #008da9;
    padding: 0 5px;
}
.custom-textarea-alt .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-button-content, .custom-textarea-alt--nolabel .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-button-content{
    padding: 2px 3px;
}
.custom-textarea-alt .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-icon, .custom-textarea-alt--nolabel .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-icon{
    color: #fff;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}
.custom-textarea-alt .dx-htmleditor .ql-editor, .custom-textarea-alt--nolabel .dx-htmleditor .ql-editor{
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
