.contact-form-bg {
    width: auto;
    height: auto;
    background: url(../../../../images/landing/bg-landing/get_in_touch.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    padding: 3rem !important;
}

.contact-form-container {
    width: 50% !important;
    height: auto !important;
    padding: 4rem;
    padding-top: 0px !important;
    margin: 0 auto;
}
.contact-form-bg h3{
    font-size: 30px;
    margin: 70px 0px 30px 0px;
}
.contact-form-textarea {
    height: 100px !important;
}

.contact-form-button {
    width: 200px !important;
    height: 45px !important;
    border-radius: 0.25rem !important;
    border: 1px solid #008da9 !important;
    background-color: #008da9 !important;
    color: rgba(255, 255, 255, 1) !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .contact-form-container {
        width: 300px !important;
        height: auto !important;
        padding: 0;
        margin: 0 auto !important;
    }

    .contact-form-input {
        width: 270px !important;
        margin: 0 auto !important;
    }

    .contact-form-button {
        width: 100% !important;
    }

    .contact-form-bg {
        padding: 3rem 0 !important;
    }

    .g-recaptcha.contact-form-recaptcha {
        transform: scale(0.9);
        transform-origin: 0 0;
        margin: 0 auto !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 567px) {
    .contact-form-container {
        width: 450px !important;
        height: auto !important;
        padding: 0;
        margin: 0 auto !important;
    }

    .contact-form-input {
        width: 360px !important;
        margin: 0 auto !important;
    }

    .contact-form-bg {
        padding: 3rem 0 !important;
    }

    .g-recaptcha.contact-form-recaptcha {
        transform: scale(1.190);
        transform-origin: 0 0;
        margin: 0 auto !important;
    }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
    .contact-form-container {
        width: 540px !important;
        height: auto !important;
        padding: 0;
        margin: 0 auto !important;
    }

    .contact-form-input {
        width: 450px !important;
        margin: 0 auto !important;
    }

    .contact-form-bg {
        padding: 3rem 0 !important;
    }

    .g-recaptcha.contact-form-recaptcha {
        transform: scale(1);
        transform-origin: 0 0;
        margin: 0 auto !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 782px) {
    .contact-form-container {
        width: 720px !important;
        height: auto !important;
        padding: 0;
        margin: 0 auto !important;
    }

    .contact-form-input {
        width: 630px !important;
        margin: 0 auto !important;
    }

    .contact-form-bg {
        padding: 3rem 0 !important;
    }

    .g-recaptcha.contact-form-recaptcha {
        transform: scale(1);
        transform-origin: 0 0;
        margin: 0 auto !important;
    }
}

@media screen and (min-width: 783px) and (max-width: 1280px) {
    .contact-form-input.last-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .contact-form-container {
        width: 100% !important;
        height: auto !important;
        padding: 4rem;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1200px) {
    .contact-form-input.last-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}