.amenities-list{
    color:#7e7e7e;
    font-size: 14px;
}
.amenities-list__items{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 5px 0 0 15px;
}
.amenities-list__item{
    display: flex;
    column-gap: 10px;
}
.amenities-list__item--hidden{
    display: none;
}


