.career-list{
    color: rgb(118, 118, 118);
    margin-bottom: 20px;
}
.career-list p{
    text-align: center;
    white-space: pre-wrap;
}

.career-list__section{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.career-list__pagination{
    width: 90%;
    display: flex;
    gap: 10px;
}
.career-list__pagination--admin{
    width: 100%;
}
.career-list__pagination--center{
    display: flex;
    justify-content: center;
}
.career-list__message{
    width: 100%;
    text-align: center;
}
.career-list__section__table{
    width: 90%;
}
.career-list__title, .career-list__nav-tabs{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 1000px) {
    .career-list__section__table{
        width: 100%;
    }
    .career-list__pagination{
        width: 100%;
    }
}
