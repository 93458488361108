.page-main{
    margin-top: 20px;
    display:flex;
    flex-direction: column;
/*    justify-content: center;*/
    align-items: center;
    flex-grow: 1;
}
.page-wrapper{
    width: 90%;
    margin-left: auto;
    margin-right: auto;

}