.administration_title{
    color: #6a6a6a;
    font-weight: 800;
    font-size: larger;
    margin-bottom: 15px;
}
.administration_table{
    border: 1px solid #cccc;
    border-bottom: none;
}

.administration_table__header,.administration_table__row{
    display: grid;
    grid-template-columns: 8fr 4fr;
    border-bottom:1px solid #cccc;
}
.administration_table__header{
    background-color: #008fac;
}

.administration_table__header div{
    padding: 10px 20px;
    color: #fff;
    font-weight: 800;
}
.administration_table__header .administration_table__division{
    border-left:1px solid #fff;
}
.administration_table__row .administration_table__division{
    border-left:1px solid #cccc;
}
.administration_table__row .administration_table__row__cell{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 500px) {
    .administration_table__header,.administration_table__row{
        display: grid;
        grid-template-columns: 6.5fr 5.5fr;
        border-bottom:1px solid #cccc;
    }
    .administration_table__row .administration_table__row__cell{
        flex-direction: column;
    }
}
