.custom-requirements{
    background-color: #f8f8f8;
    width: 100wv;
    padding: 10px 30px;
    display: grid;
    grid-template-columns: 8.5fr 3.5fr;
    grid-template-areas: 
        "section-left section-right"
        "section-buttons section-buttons"
    ;
    column-gap: 50px;
    row-gap: 10px;
    /* flex-direction: column; */
    font-size: 13.5px;
}
.custom-requirements__isParking{
    display: flex;
    align-items: center;
    gap: 10px;
}
.custom-requirements__maid-service{
    grid-area: maid;
}
.custom-requirements__isParking{
    grid-area: parking;
    width: 100%;
}
.custom-requirements__isParking__dropdown{
    width: 100%;
}
.custom-requirements__section-left__qty-adults .custom-input-alt__label, .custom-requirements__section-left__qty-children .custom-input-alt__label, .custom-requirements__isParking .custom-dropdown-alt__label, .custom-requirements__maid-service .custom-dropdown-alt__label{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.custom-requirements__isParking .custom-requirements__isParking__check{
    padding-top: 16px;
}
.custom-requirements__section-left{
    grid-area: section-left;
    display: grid;
    grid-template-columns: 3fr 1.5fr 1.5fr 3fr 3fr;
    gap: 10px;
    grid-template-areas: 
        "location  location   radius       building-type floor"
        "date      qty-adults qty-children maid          parking"
        "budget    currency   currency     -             -"
        "pets      pets       pets         -             -"
        "notes     notes      notes        -             -"
        "amenities amenities  amenities    amenities     amenities"
    ;
}
.custom-requirements__section-right{
    padding-top: 20px;
    grid-area: section-right;
}
.custom-requirements__section-buttons{
    display: flex;
    align-items: center;
    gap: 10px;
    grid-area: section-buttons;
}
.custom-requirements__section-buttons__message{
    color: #939393;
}
.custom-requirements__section-buttons__message span{
    color: #d9534f;
}
.custom-requirements__section-left__location{
    grid-area: location;
}
.custom-requirements__section-left__radius{
    grid-area: radius;
}
.custom-requirements__section-left__building-type{
    grid-area: building-type;
}
.custom-requirements__section-left__floor{
    grid-area: floor;
}
.custom-requirements__section-left__date{
    grid-area: date;
}
.custom-requirements__section-left__qty-adults{
    grid-area: qty-adults;
}
.custom-requirements__section-left__qty-children{
    grid-area: qty-children;
}
.custom-requirements__section-left__budget{
    grid-area: budget;
}
.custom-requirements__section-left__currency{
    grid-area: currency;
}
.custom-requirements__section-left__pets{
    display: flex;
    flex-direction: column;
    gap: 10px;
    grid-area: pets;
}
.custom-requirements__section-left__pets__content{
   padding-left: 20px;
   display: grid;
   grid-template-columns: 2.5fr 9.7fr;
   gap: 30px;
}
.custom-requirements__section-left__pets__content__details{
    display: grid;
    gap: 10px;
}
.custom-requirements__section-left__pets__content__details__item{
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 10px;
}
.custom-requirements__section-left__notes{
    grid-area: notes;
}
.custom-requirements__section-left__amenities{
    grid-area: amenities;
}

@media screen and (max-width: 1774px) {
    .custom-requirements__section-left__pets__content{
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .custom-requirements__section-left__pets__content__amount{
        width: 30%;
    }
}

@media screen and (max-width: 1700px) {
    .custom-requirements__section-left{
        grid-template-columns: 3fr 2fr 2fr 2.5fr 2.5fr;
    }
}

@media screen and (max-width: 1249px) {
    .custom-requirements__section-left__pets__content{
        /* padding-left: 20px;
        display: grid; */
        grid-template-columns: 2fr 10fr;
        gap: 30px;
     }
     .custom-requirements__section-left__pets__content__amount{
        width: 100%;
    }
    .custom-requirements{
        padding: 10px 10px;
        column-gap: 10px;
        grid-template-columns: 9fr 3fr;
    }
    .custom-requirements__section-left{
        grid-template-columns: 2.4fr 2.4fr 2.4fr 2.4fr 2.4fr;
        gap: 10px;
        grid-template-areas: 
            "location       location      location     radius       radius"
            "building-type  building-type floor        floor        floor"
            "date           date          date         qty-adults   qty-children"
            "maid           maid          maid         parking      parking"
            "budget         budget        currency     currency     currency"
            "pets           pets          pets         pets         pets"
            "notes          notes         notes        notes        notes"
            "amenities      amenities     amenities    amenities    amenities"
        ;
    }
}
@media screen and (max-width: 1051px) {
    .custom-requirements__section-left__pets__content{
        grid-template-columns: 1fr;
        gap: 10px;
    }
    .custom-requirements__section-left__pets__content__amount{
        width: 30%;
    }
}

@media screen and (max-width: 728px){
    .custom-requirements{
        padding: 20px 40px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
            "section-left"
            "section-right"
            "section-buttons"
        ;
        column-gap: 50px;
        row-gap: 10px;
        /* flex-direction: column; */
        font-size: 13.5px;
    }
    .custom-requirements__section-left{
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        grid-template-areas: 
            "location      location"     
            "radius        -"
            "building-type building-type"
            "floor         floor"
            "date          date"
            "qty-adults    qty-children"   
            "budget        budget"
            "maid          maid"
            "parking       parking"
            "currency      currency"
            "pets          pets"
            "notes         notes"
            "amenities     amenities"
        ;
    }
    .custom-requirements__section-right{
        padding-top: 0px;
    }
}

@media screen and (max-width: 399px){
    .custom-requirements{
        padding: 20px 6px;
    }
}