/* .custom-email-alt__focus-in .dx-state-focused.dx-invalid .dx-invalid-message-auto {
   visibility: hidden;
}

.custom-email-alt__focus-out .dx-invalid-message {
    visibility: visible;
} */

.custom-email-alt__focus-in .dx-state-focused.dx-invalid .dx-invalid-message-auto{
    visibility: hidden;
}
.custom-email-alt__focus-in .dx-invalid ~ .custom-input-alt__container__icon{
    border-color: #337ab7;
}
.custom-email-alt__focus-in .dx-show-invalid-badge .dx-texteditor-input-container::after{
    display: none;
}

.custom-email-alt__focus-in .dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-focused{
    border-color: #337ab7;
}

.custom-email-alt__focus-out .dx-invalid .dx-invalid-message-auto{
    visibility: visible;
}

/* .dx-invalid.dx-texteditor {
    border-color: rgba(217, 83, 79, .4);
} */
