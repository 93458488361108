* :has(> #root .main-container div#imageDownload)   {
    background-color: #000000 !important;
    visibility: hidden;
}

div#imageDownload {
    visibility: visible;
}
.image {
    display: grid;
    gap: 10px;
    margin-bottom: 20px;
    aspect-ratio: 4/2;
}

.image__download{
    display: flex;
    align-items: center;
    justify-content: center;
}


