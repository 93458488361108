.custom-treelist-alt{
    font-size: inherit;
    font-family: 'Cabin', sans-serif !important; 
}
.custom-treelist-alt .dx-treelist-search-text{
    background-color: rgba(0, 141, 169, 0.2);
    color: inherit;
}
.custom-treelist-alt tr[aria-level="1"]{
    font-weight: 900;
}
.custom-treelist-alt tr[aria-level="1"] .dx-checkbox{
    display: none;
}
.custom-treelist-alt tr[aria-level="1"] .dx-treelist-icon-container{
    padding-right: 5px;
}
.custom-treelist-alt .dx-treelist-headers{
    background-color: #f2f2f2;
}
/* .custom-treelist-alt .dx-treelist-content table colgroup col{
    width: fit-content !important;
} */
/* .custom-treelist-alt .dx-treelist-content table  {
    width: 250px;
} */
.custom-treelist-alt .dx-treelist-content table tbody {
    overflow-y: auto;
}
.custom-treelist-alt .dx-checkbox-indeterminate .dx-checkbox-icon::before{
    background-color: #008da9;
}

.custom-treelist-alt .dx-checkbox .dx-checkbox-container{
    border-radius: 5px;
}
.custom-treelist-alt .dx-checkbox .dx-checkbox-icon{
    border-radius: 5px;
}
.custom-treelist-alt .dx-checkbox-checked .dx-checkbox-icon{
    background-color: #008da9;
}
.custom-treelist-alt .dx-checkbox-checked .dx-checkbox-icon::before{
    /* background-color: #008da9; */
    color: #fff;
    font-size: 8px;
    width: 17px !important;
    position: relative !important;
    left: 2.5px !important;
    top: 4.5px !important;
}

.custom-treelist-alt .dx-selection .dx-treelist-text-content{
    font-weight: 800;
}

.custom-treelist-alt .dx-treelist-text-content{
    white-space: normal !important
}