
.custom-tooltip{
    position: absolute;
    /* text-transform: capitalize;
    color: rgba(0, 0, 0, 0.483);
    text-align: center; */
}
/* .dx-tooltip-wrapper .dx-popup-normal .dx-popup-content{
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.483);
    text-align: center;
    padding: 4px 10px !important;
} */
.dx-tooltip-wrapper .dx-popup-normal .dx-popup-content{
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.483);
    text-align: left;
    /* padding: 4px 10px !important; */
}
.custom-tooltip__isHtml li::marker, .dx-tooltip-wrapper li::marker{
    color: #008da9;
}
/* @media screen and (max-width: 1200px) {
    .custom-tooltip-img{
        transform: scale(.9);
    }
}

@media screen and (max-width: 1000px) {
    .custom-tooltip-img{
        transform: scale(.8);
    }
} */