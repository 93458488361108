.my-basket{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.my-basket__banner{
    width: fit-content;
}

.my-basket__banner__container{
    display: flex;
    gap: 20px;
    align-items: center;
}

.my-basket__banner__container span{
    color: rgb(90, 90, 90);
}

.my-basket__title{
    display: flex;
    gap: 20px;
}

.my-basket__buttons{
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

.my-basket .custom-card__image img{
    opacity: 0;
}

@media screen and (max-width: 600px) {
    .my-basket__banner__container{
        flex-direction: column;
    }
}