.confirm-delete-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.confirm-delete-modal {
    width: 735px;
    height: 249px;
    margin: 335px 340px 414px 593px;
    padding: 42px 25px 56px 48px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid #cdcdcd;
    background-color: #f8f8f8;
    margin: 0 auto;
}

.comfirn-delete-modal__title {
    text-align: center;
    margin: 0 auto 1rem auto;
    text-transform: uppercase;
    font-size: 18px;
}

.confirm-delete-modal__message {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #515151;
    margin: 1rem 0 0 0;
}

.confirm-delete-modal__btn-confirm {
    width: 100px;
    height: 45px;
    margin: 1rem 1rem 0 0;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9534f;
    background: linear-gradient(to bottom, #e2817e, #d9534f);
    color: #fff;
}

.confirm-delete-modal__btn-cancel {
    width: 90px;
    height: 45px;
    margin: 1rem 1rem 0 0;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    border: 1px solid #d6d6d6;
    background: linear-gradient(to bottom, #fafafa, #e5e5e5);
    color: #707070;
}

.trash-icon-delete-modal {
    width: 12px !important;
    height: 12px !important;
}

/* toast 270px */
@media screen and (min-width: 320px) and (max-width: 479px) {
    .confirm-delete-modal {
        width: 270px;
        padding: 2rem;
    }
}

/* toast 360px */
@media screen and (min-width: 480px) and (max-width: 547px) {
    .confirm-delete-modal {
        width: 360px;
    }
}

/* toast 360px */
@media screen and (min-width: 548px) and (max-width: 567px) {
    .confirm-delete-modal {
        width: 450px;
    }
}

/* toast 450px */
@media screen and (min-width: 568px) and (max-width: 719px) {
    .confirm-delete-modal {
        width: 520px;
    }
}

/* toast 540px */
@media screen and (min-width: 720px) and (max-width: 767px) {
    .confirm-delete-modal {
        width: 630px;
    }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
    .confirm-delete-modal {
        width: 720px;
    }
}

@media screen and (min-width: 991px) and (max-width: 1080px) {
    .confirm-delete-modal {
        width: 720px;
    }
}