.accordion-treelist{
    width: 100%;
    color: inherit;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.accordion-treelist .accordion-treelist__boxes{
    display: flex;
    gap: 20px;
}
/* .accordion-treelist__box__title{
    border-radius: 3px;
    border: solid 1px #dedede;
    background-color: #f2f2f2;
    padding: 5px 10px;
} */
.accordion-treelist__box__content{
    max-height: 300px;
    border: 1px solid rgb(225, 225, 225);
    overflow-y: auto;
}
.accordion-treelist__box__content .dx-treelist-table{
    border-color: #fff;

}
.accordion-treelist__content{
    display: grid;
    grid-template-columns: 50% 50%;
    /* gap: 10px; */
    
}
.accordion-treelist__content--active{
    transition: all .5s ease;
    overflow: auto;
    max-height: 2000px;
}
.accordion-treelist__content--inactive{
    max-height: 0px;
    overflow: hidden;
    transition: all .3s ease;
}
.accordion-treelist__tags{
    /* width: 100vh; */
    height: 332.25px;
    overflow-y: auto;
}
.accordion-treelist__tags__container{
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    padding-left: 10px;
    gap: 10px;
    color: #9d9d9d;
}

.accordion-treelist__tag{
    display: flex;
    width: fit-content;
    height: fit-content;
    align-items: center;
    border-radius: 3px;
    border: solid 1px #dedede;
    background-color: #fff;
}
.accordion-treelist__tag:hover{
    transform: scale(1.05);
}
.accordion-treelist__tag__delete{
    padding: 0 10px;
    color: #d13030;
    cursor: pointer;
}

.accordion-treelist__tag__delete:hover{
    transform: scale(1.2);
}

.accordion-treelist__tag__title{
    padding-right: 10px;
}
.accordion-treelist__searcher{

    display: grid;
    grid-template-columns: 6fr 6fr;
    /* gap: 10px; */
}
.accordion-treelist__searcher .accordion-treelist__searcher__content{
    display: grid;
    grid-template-columns: 5fr 7fr;
    /* justify-content: space-between; */
    /* gap: 4px; */
    align-items: center;
}
/* .accordion-treelist__searcher input{
    width: 380px;
    height: 34px;
    color: #7b7b7b;
    font-size: inherit;
    border: 1px solid #ddd;
    border-radius: 4px;
} */

.accordion-treelist__searcher input::placeholder{
    color: #c4c4c4;
    font-family: 'Cabin', sans-serif,'FontAwesome';
}
.accordion-treelist__searcher input:hover{
    border-color: rgba(51,122,183,.4);
}
.accordion-treelist__searcher input:focus{
    border-color: #337ab7;
    outline: none
} 
.accordion-treelist__title--inactive{
    height: 34px;
    /* font-size: 14px; */
    font-size: inherit;
    display: flex;
    align-items: center;
    color: #008da9;
    cursor: pointer;
}

.accordion-treelist__title--active{
    color: #6f6f6f;
    cursor: pointer;
}

@media screen and (max-width: 1700px) {
    .accordion-treelist__content{
        grid-template-columns: 58% 42%;        
    }
    .accordion-treelist__searcher{
        grid-template-columns: 58% 42%;
    }
}

@media screen and (max-width: 1249px) {
    .accordion-treelist__content{
       display: flex;
       flex-direction: column-reverse;  
       gap: 10px;  
    }
    .accordion-treelist__searcher{
        grid-template-columns: 100%;
    }
    .accordion-treelist__tags{
        width: 100%;
        height: auto;
        max-height: 100px;
        overflow-y: auto;
    }
    .accordion-treelist__tags__container{
        padding-left: 0px;
    }
}