.forgot-reset{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
}

.forgot-reset__container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 35%;
}

.forgot-reset__container__input{
    width: 100%;
}

.forgot-reset__container__title{
    display: flex;
    gap: 10px;
}

@media screen and (max-width: 1500px) {
    .forgot-reset__container{
        width: 50%;
    }
}

@media screen and (max-width: 900px) {
    .forgot-reset__container{
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .forgot-reset__container{
        width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .forgot-reset__container{
        width: 100%;
    }
}