.accordion-customButton{
    color:rgba(26, 147, 170, 0.90);
    cursor: pointer;
}
.accordion-customButton-icon,.accordion-customButton-icon--active{
    display: inline-block;
    font-size: 10px;
    font-family: 'FontAwesome';
    transition: .4s;
}
.accordion-customButton-icon--active{
    transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
}