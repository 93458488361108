.our-awards {
    width: 100%;
    height: auto;
    width: auto;
    overflow: hidden;
    background: url('../../../../images/landing/bg-landing/wavebg.png');
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.our-awards h3{
    font-size: 30px !important;
    margin: 70px 0px 30px 0px;
}