.group-button{
    width: 30px;
    border: 1px solid #eaeaea !important;
    background: #e0e0e0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #f5f5f5, #e0e0e0);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f5f5f5, #e0e0e0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-family: 'FontAwesome';
}
.group-button--direction-left{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.group-button--direction-right{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.group-button:hover{
    background: #bdbdbd;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #f3f3f3, #bdbdbd);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3, #bdbdbd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}