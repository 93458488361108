.amenities-tag{
    border: .5px solid #aaaaaa;
    color: #9d9d9d;
    font-size: 14px;
    margin: 0 0 5px 0;
    display: inline-flex;
    padding: 5px 10px;
    align-items: center;
    column-gap: 5px;
}
@media screen and (max-width: 634px) {
    .amenities-tag{
        min-width: 120px;
    }
}