.create-account {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 0 0 8rem 0;
    color: #515151;
}
.simple-item-styles{
    display: grid;
    grid-template-columns: 133px 1fr;
    align-items: center;
    gap: 15px;
}
.simple-item-styles .simple-item-styles__label{
    text-align: end;
    font-weight: 700;
}
.simple-item-styles--required{
    color: #d9534f;
}

.phone-block {
    row-gap: 7px;
}

.phone-policy-block{
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: #939393;
}

.tooltop {
    visibility: hidden !important;
    display: none !important;
}

.create-account-form {
    background-color: #f8f8f8 !important;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    width: 650px;
    height: auto;
    padding: 4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.btn-register {
    border-radius: 3px !important;
    border: 1px solid #21b1ad !important;
    background-color: #21b1ad !important;
    color: #fff !important;
    /* margin: 0 158px 0 0 !important; */
    width: 100px !important;
    height: 36px !important;
    transition: all 0.2s ease-in-out !important;
}

.btn-return {
    border: none !important;
    background-color: transparent !important;
    color: #21b1ad !important;
    width: auto !important;
    height: auto !important;
    transition: all 0.2s ease-in-out !important;
}

.requirement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-left: 10px;
}
.requirement p{
    margin: 0 !important
}

.validation-default {
    color: #8f8f8f;
}

.validation-success {
    color: #21b1ad;
}

.validation-error {
    color: #ff0000;
}

.validation-error-msg {
    display: flex;
    flex-direction: row;
    margin: -6px auto 0 auto;
    z-index: 99;
    position: relative;
    top: 0;
    left: -28px;
    right: 0;
    bottom: 0;
    width: 85%;
    height: 36px;
    border: 1px solid #d9534f;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    background-color: #d9534f;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}

.validation-error-msg p {
    font-size: 12px;
    margin: 6px 0 0 12px;
}

.create-account__recaptcha{
    margin-left: 148px;
}
.register-buttons {
    width: 100%;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    padding-left: 148px;
}
.register-buttons__register{
    display: flex;
}
.create-account__validation-icons{
    display: flex;
    flex-direction: column;
    margin-left: 148px;
    font-size: smaller;
    gap: 10px;
}
.create-account__validation-icons .requirements{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.create-account__password{
    display: grid;
    grid-template-columns: calc(100% - 40px) 40px;
    align-items: center;
}
.create-account__password .dx-textbox{
    border-right: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.create-account__password__image{
    /* padding-right: 2px; */
    /* background-color: #fff; */
    height: 20px;
    width: 20px;
}
.create-account__password__image-container{
    display: flex;
    /* padding-right: 2px; */
    border: 1px solid #ddd;
    border-radius: 4px;
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 100%;
}
.dx-state-hover ~ .create-account__password__image-container{
    border-color: rgba(51, 122, 183, .4);
}

.dx-state-focused ~ .create-account__password__image-container{
    border-color: #337ab7;
}

.dx-invalid ~ .create-account__password__image-container{
    border-color: rgba(217,83,79,.4);
}

.dx-invalid.dx-state-focused ~ .create-account__password__image-container{
    border-color: #d9534f;
}
/* .create-account__password__image--no-visible{
    
} */
@media screen and (max-width: 719px) {
    .create-account-form {   
        width: 94%;
        padding: 4rem;
    }
    .simple-item-styles{
        grid-template-columns: 1fr;
    }
    .simple-item-styles .simple-item-styles__label{
        text-align: start;
    }
    .create-account__recaptcha{
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .register-buttons {
        padding-left: 0;
    }
    .create-account__validation-icons{
        margin-left: 0;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .create-account-form {   
        width: 96%;
        padding: 2%;
    }
}