.request-requirements-card{
    padding-top: 10px;
    display: grid;
    grid-template-columns: 9fr 3fr ;
    grid-template-areas: 
        "main-content buttons"
        "main-content buttons"
        "main-content buttons"
        "divisor      divisor"
    ;
}
.request-requirements-card .request-requirements-card__content{
    grid-area: main-content;
    display: grid;
    gap: 10px;
    grid-template-columns: 8fr 4fr;
    grid-template-areas: 
        "section1 section2"
        "section1 section2"
        "section1 section2"
        "section3 section3"
    ;
}
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section1{
    display: grid;
    gap: 10px;
    grid-area: section1;
}
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section2{
    display: flex;
    flex-direction: column;
    gap: 10px;
    grid-area: section2;
}
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section1 div,
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section2 div{
    display: flex;
    align-items: center;
    gap: 5px;
}
.request-requirements-card__floor{
    align-items: flex-start !important;
}
.request-requirements-card__floor img{
    padding-top: 2px;
}
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section3{
    display: grid;
    gap: 10px;
    grid-area: section3;
}
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section2 .request-requirements-card__qty{
    display: flex;
    gap: 10px;
}
.request-requirements-card .request-requirements-card__content .request-requirements-card__content__section2 .request-requirements-card__qty .request-requirements-card__qty__set{
    display: flex;
    align-items: center;
    gap: 5px;
}
.request-requirements-card .request-requirements-card__buttons{
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
    grid-area: buttons;
}
.request-requirements-card .request-requirements-card__buttons .request-requirements-card__buttons__container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.request-requirements-card .request-requirements-card__buttons .request-requirements-card__buttons__container .request-requirements-card__button__edit{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #008da9;
    cursor: pointer;
}
.request-requirements-card .request-requirements-card__buttons .request-requirements-card__buttons__container .request-requirements-card__button__annul{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #d9534f;
    cursor: pointer;
    border: none;
    background-color:transparent;
}
.request-requirements-card__button__annul{
    padding: 0px;
}
.request-requirements-card__button__annul__disabled{
    color: #d9544f8d !important;
    cursor: not-allowed !important;
}
.request-requirements-card__button__annul svg{
    fill: #d9534f;
}
.request-requirements-card__button__annul__disabled svg{
    fill: #d9544f8d !important;
}
.request-requirements-card__button__group__muted-message
{
    font-size: smaller;
    color: #cdcdcd;
    font-style: italic;
}
.request-requirements-card__button__group__muted-message span
{
    color: red;
}
.request-requirements-card__buttons .request-requirements-card__buttons__container{
    width: 136px;
}
.request-requirements-card .request-requirements-card__divisor{
    padding: 10px 0;
    border-bottom: 2px solid #cdcdcd;
    border-bottom-style: dashed;
    grid-area: divisor;
}
.request-requirements-card__showAmenities{
    color: #008da9;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
}
@media screen and (max-width: 1100px) {
    .request-requirements-card{
        grid-template-columns: 9fr 3fr ;
        grid-template-areas: 
            "main-content buttons"
            "main-content buttons"
            "main-content buttons"
            "divisor      divisor"
        ;
    }
    .request-requirements-card .request-requirements-card__content{
        grid-template-columns: 6fr 6fr;
        grid-template-areas: 
            "section1 section2"
            "section1 section2"
            "section1 section2"
            "section3 section3"
        ;
    }
}
/* @media screen and (max-width: 900px) {
    .request-requirements-card{
        grid-template-columns: 10fr 2fr ;
        grid-template-areas: 
            "main-content buttons"
            "main-content buttons"
            "main-content buttons"
            "divisor      divisor"
        ;
    }
} */
@media screen and (max-width: 700px) {
    .request-requirements-card .request-requirements-card__content{
        grid-template-columns: 12fr;
        grid-template-areas: 
            "section1"
            "section2"
            "section3"
        ;
    }
}

@media screen and (max-width: 669px) {
    .request-requirements-card{
        grid-template-columns: 12fr ;
        grid-template-areas: 
            "main-content"
            "buttons"
            "divisor"
        ;
    }
    .request-requirements-card .request-requirements-card__buttons{
        justify-content: flex-start;
        padding-right: 0;
    }
    /* .request-requirements-card .request-requirements-card__buttons .request-requirements-card__buttons__container{
         flex-direction: row; 
    } */
}