.our-solutions-container {
    padding: 0 5% !important;
    margin: 0 auto;
}

.our-solutions-container p{
    text-align: justify !important;
}

.our-solutions {
    width: 100%;
    height: auto;
}

.our-solutions h3 {
    margin: 70px 0 30px 0;
   
    font-size: 30px;
}

.our-solutions__img-circle {
    border-radius: 50%;
    background-color: #2A8FAB;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.our-solutions__img-circle img {
    z-index: 2;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


@media screen and (min-width: 1200px) and (max-width: 1800px) {
    .our-solutions-container {
        padding: 0 5% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 1199px) {
    .our-solutions-container {
        padding: 0 5% !important;
    }
}