

.custom-guest-info{
    border: 1px dashed #acacac;
    border-radius: 5px;
    background-color: #fff;
    color: #7b7b7b;
    width: fit-content;
    height: fit-content;
    padding: 20px;
}
.custom-guest-info--selected{
    min-width: 275.95px;
    width: 100%;
}
.custom-guest-info .section-subtitle{
    left: 0;
    margin: 0 0 10px 0;
}
.custom-guest-info__container {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.custom-guest-info__input-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.custom-guest-info__label-container{
    display: flex;
    margin-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    /* height: 125.21px; */
}
.custom-guest-info__label-field{
    display: flex;
    gap: 10px;
    /* height: 34px; */
    align-items: center;
}
/* .custom-guest-info__label-field div{
    width: 110.2px;
    text-align: right;
    font-weight: 800;
} */

.custom-guest-info__container .custom-checkbox .dx-checkbox-text{
    font-style: italic;
    color: #9a9a9a;
    background-color: transparent;
}

@media screen and (max-width: 728px) {
    
    .custom-guest-info{
        min-width: fit-content;
        width: 100%;
    }
}