.file-uploader {
    position: relative;
    top: -14px;
}

.file-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.file-input-label {
    flex-grow: 1;
    border: 1px solid #ccc;
    padding: 6px 12px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-input-button {
    padding: 6px 12px;
    cursor: pointer;
    background-color: #f1f1f1;
    color: #000;
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 14px;
}