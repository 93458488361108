.custom-input-alt__label{
    color: #6f6f6f;
    font-size: inherit;
}

.custom-input-alt__label span{
   color: rgb(221,103,99)
}

.custom-input-alt__container{
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "inputIcon input";
    align-items: center;
    /* height: 100%; */
}

.custom-input-alt__container__icon{
    font-family: 'FontAwesome';
    color: #b7b7b7;
    pointer-events: none;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 6px 0px 7px 10px;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    grid-area: inputIcon;
}
.custom-input-alt--no-borders .custom-input-alt__container__icon{
    border-top: none;
    border-left: none;
    border-radius: 0px;
    background-color: transparent;
}
.custom-input-alt__container--noIcon .custom-input-alt__container__icon{
    padding-left: 2px !important;
}
.custom-input-alt__container .dx-texteditor{
    border-left: none;
    grid-area: input;
}
.custom-input-alt--no-borders .custom-input-alt__container .dx-texteditor{
    border-top: none;
    border-right: none;
    border-radius: 0px;
    background-color: transparent;
}
.custom-input-alt__container .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;
}

.dx-state-hover ~ .custom-input-alt__container__icon{
    border-color: rgba(51, 122, 183, .4);
}

.dx-state-focused ~ .custom-input-alt__container__icon{
    border-color: #337ab7;
}

.dx-invalid ~ .custom-input-alt__container__icon{
    border-color: rgba(217,83,79,.4);
}

.dx-invalid.dx-state-focused ~ .custom-input-alt__container__icon{
    border-color: #d9534f;
}

.custom-input-alt__container input{
    padding-left: 35.67px;
}
.custom-input-alt__container input::placeholder{
    display: none;
    color: transparent;
}
.custom-input-alt__container .dx-placeholder{
    padding-left: 29.67px;
}

.custom-input-alt__container--noIcon input{
    padding-left: 9px;
}
.custom-input-alt__container--noIcon .dx-placeholder{
    padding-left: 3px;
}