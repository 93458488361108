.request-properties-card{
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 7fr 2fr 2fr;
    align-items: center;
    grid-template-areas: 
        "image   name    status  buttons"
        "image   address status  buttons"
        "image   floor   status  buttons"
        "image   dates   status  buttons" 
        "divisor divisor divisor divisor"
    ;
}

.request-properties-card__img{
    grid-area: image;
}

.request-properties-card__img img{
    height:115px;
    width:160px;
}

.request-properties-card__name{
    padding: 0px 20px;
    grid-area: name;
    font-size:larger;
    display: flex;
    gap: 12px;
    align-items: center;
}

.request-properties-card__name__building{
    display: flex;
    height: fit-content;
    align-items: center;
    background-color: #008da9;
    color: #fff;
    padding: 0px 7px;
    font-size: small;
    border-radius: 40px;
}

.request-properties-card__address{
    padding: 0px 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    grid-area: address;
}

.request-properties-card__floor{
    padding: 0px 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    grid-area: floor;
}

.request-properties-card__dates{
    padding: 0px 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    grid-area: dates;
}

.request-properties-card__status{
    grid-area: status;
}

.request-properties-card__status__tag{
    display: flex;
    height: fit-content;
    width: fit-content;
    align-items: center;
    background-color: #cdcdcd;
    padding: 3px 10px;
    font-size: small;
    border-radius: 40px;
}

.request-properties-card__buttons{
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
}
.request-properties-card__buttons__content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.request-properties-card__buttons__content__view img{
    height: 16px;
    width: 16px;
}
.request-properties-card__buttons__content__view,
.request-properties-card__buttons__content__view:hover
{
    display: flex;
    gap: 5px;
    align-items: center;
    color: #008da9;
    cursor: pointer;
}
.request-properties-card__buttons__content__view, .request-properties-card__buttons__content__delete{
    width: 136px;
}
.request-properties-card__buttons__content__delete{
    display: flex;
    gap: 5px;
    align-items: center;
    color: #d9534f;
    cursor: pointer;
}
.request-properties-card__buttons__content__delete svg{
    fill: #d9534f;
}
.request-properties-card__divisor{
    grid-area: divisor;
    margin: 20px 0px 0px 0px;
    border: 1px solid #cdcdcd;
}

@media screen and (max-width: 1400px){
    .request-properties-card{
        grid-template-columns: 1fr 7fr 2fr 2fr;
    }
}

@media screen and (max-width: 1100px){
    .request-properties-card{
        grid-template-columns: 1fr 8fr 3fr;
        grid-template-areas: 
            "image   name    buttons"
            "image   status  buttons"
            "image   address buttons"
            "image   floor   buttons"
            "image   dates   buttons" 
            "divisor divisor divisor"
        ;
    }
    .request-properties-card__status{
        padding: 0px 20px;
    }
    .request-properties-card__img img{
        height:135px;
        width:180px;
    }
}
/* @media screen and (max-width: 900px) {
    .request-properties-card__buttons__content__view, .request-properties-card__buttons__content__delete{
        width: 100%;
    }
    .request-properties-card__buttons{
        grid-area: buttons;
        display: flex;
        justify-content: flex-start;
        padding-right: 10%;
    }
} */
@media screen and (max-width: 700px) {
    .request-properties-card{
        grid-template-columns: 1fr 11fr;
        grid-template-areas: 
                "image  name"
                "image  status"
                "image  buttons"
                "image  buttons"
                "address address"
                "floor   floor"
                "dates   dates" 
                "divisor divisor"
        ;
    }
   .request-properties-card__buttons{
        justify-content: flex-start;
        padding: 0px 20px;
    }
    /* .request-properties-card__buttons__content{
        flex-direction: row;
    } */
    .request-properties-card__name{
        padding: 0px 20px;
    }
    
    .request-properties-card__address{
        padding: 0px;
    }
    
    .request-properties-card__floor{
        padding: 0px;
    }
    
    .request-properties-card__dates{
        padding: 0px;
    }
    .request-properties-card__status{
        padding: 0px 20px;
    }
}

@media screen and (max-width: 500px) {
    /* .request-properties-card__img img{
        height:95px;
        width:140px;
    } */
    .request-properties-card{
        grid-template-columns: 1fr 11fr;
        grid-template-areas: 
                "image   image"
                "name    name"
                "status  status"
                "address address"
                "floor   floor"
                "dates   dates" 
                "buttons buttons"
                "divisor divisor"
        ;
    }
    .request-properties-card__buttons__content{
        flex-direction: row;
    }
    .request-properties-card__name{
        padding: 0px;
    }
    .request-properties-card__status{
        padding: 0px;
    }
    .request-properties-card__buttons{
        padding: 0px;
    }
    .request-properties-card__img{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
}
