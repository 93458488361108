.custom-textarea{
    display: grid;
    grid-template-columns: 20% calc(80% - 20px);
    gap: 20px;
    /* align-items: center; */
}
.custom-textarea .dx-texteditor-input, .custom-textarea--nolabel .dx-texteditor-input{
    color: #7b7b7b;
    font-size: inherit;

}
.custom-textarea__label{
    text-align: right;
    font-weight: 800;
    color: #515252;
    font-size: inherit;
}

.custom-textarea__label span{
    color: rgb(221,103,99);
}
.custom-textarea .dx-htmleditor, .custom-textarea--nolabel .dx-htmleditor{
    color: #7b7b7b;
    font-size: inherit;
}
.custom-textarea .dx-htmleditor .dx-htmleditor-toolbar-wrapper, .custom-textarea--nolabel .dx-htmleditor .dx-htmleditor-toolbar-wrapper{
    background-color: #008da9;
    padding: 0 5px;
}
.custom-textarea .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-button-content, .custom-textarea--nolabel .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-button-content{
    padding: 2px 3px;
}
.custom-textarea .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-icon, .custom-textarea--nolabel .dx-htmleditor .dx-htmleditor-toolbar-wrapper .dx-icon{
    color: #fff;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
}
.custom-textarea .dx-htmleditor .ql-editor, .custom-textarea--nolabel .dx-htmleditor .ql-editor{
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
@media screen and (max-width:1400px) {
    .custom-textarea{
        grid-template-columns: 30% calc(70% - 20px);
        gap: 20px;
    }
}
@media screen and (max-width:900px) {
    .custom-textarea{
        grid-template-columns: 25% calc(75% - 20px);
        gap: 20px;
    }
}
@media screen and (max-width:450px) {
    .custom-textarea{
        grid-template-columns: 35% calc(65% - 20px) !important;
        gap: 20px;
    }
}