.request-option__property-image{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    border: solid 1px #cdcdcd;
    position: relative;
}
.request-option__property-image .request-option__property-image__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    cursor: pointer;
    
}

.request-option__property-image__more{
    padding: 5% 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.556);
    color: #fff;
    /* height: 100%; */
    width: 30%;
    /* margin-left: 50%; */
    display: flex;
    font-size: 25px;
    text-decoration: underline;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.request-option__property-image__more--smaller{
    text-decoration: none !important;
    font-size: 18px;
}