.navbar-message{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}
.navbar-message--active{
    background-color: #f2f2f2;
    /* border: 1px solid #cdcdcd; */
    box-shadow: inset 0 0 1px #cdcdcd;
    border-bottom: none;
}

.navbar-message--active::after{
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    height: 7px;
    width: calc(100% - 1.2px);
    z-index: 5;
    background-color: #f2f2f2;
}

.navbar-message--square::after{
    bottom: 0px !important;
    width: 100% !important;
}

.navbar-message--active .navbar-message__icon svg{
    fill: #000 !important;
}
.navbar-message--active .navbar-message__title{
    color: #000 !important;
}
.navbar-message .navbar-message__icon,.navbar-message--square .navbar-message__title{
    padding: 0px 10px;
    position: relative;
    cursor: pointer;
}

.navbar-message__icon div:nth-child(2){
    position: absolute;
    right: 0;
    top: -5px;
}
.navbar-message .navbar-message__dropdown{
    display: none;
    background-color: #f2f2f2;
    box-shadow: inset 0 0 1px #cdcdcd;
    position: absolute;
    top: 81px;
    right: 0;
    /* text-align: right; */
    /* left: 0; */
    z-index: 1;
    padding: 20px;
    white-space: nowrap;
}
.navbar-message--square .navbar-message__dropdown{
    top: 78px !important;
    padding: 0px !important;
}
.navbar-message__dropdown a{
    display: flex;
    gap: 10px;
    padding-left: 10px;
    color: #515151;
}
.navbar-message--square .navbar-message__dropdown a{
    gap: 0px !important;
    padding-left: 20px !important;
}
.navbar-message__dropdown a:hover{
    color: #008da9;
}
.navbar-message .navbar-message__dropdown--active{
    display: flex; 
    flex-direction: column;
    gap: 15px;
}
.navbar-message--square .navbar-message__dropdown--active{
    gap: 0px !important;
}
.navbar-message__dropdown__item--active{
    background-color: #aaaaaa;
    padding: 10px;
    border-radius: 20px;
}
.navbar-message__dropdown__item--active span{
    color: #fff;
}
.navbar-message--square .navbar-message__dropdown__item{
    display: flex;
    padding: 10px !important;
    justify-content: end !important;
}
.navbar-message--square .navbar-message__dropdown__item--active{
    border-radius: 0px !important;
    /* padding: 10px 10px 10px 30px !important; */
    justify-content: end !important;
}

.navbar-message__dropdown__title{
    font-weight: 700;
    font-size: .8rem;
}
@media screen and (max-width: 1199.98px) {
    .navbar-message--square .navbar-message__dropdown__item, .navbar-message--square .navbar-message__dropdown__item--active{
        justify-content: start !important;
    }
    .navbar-message__icon,.navbar-message--square .navbar-message__title{
        padding: 0px !important;
        margin: 0px;
        margin-bottom: 8px;
    }
    .navbar-message__icon div:nth-child(2){
        position: absolute;
        right: -10px;
    }
    .navbar-message--active{
        background-color: transparent;
        padding: 0px ;
        border: none;
        box-shadow: none;
    }
    .navbar-message--active .navbar-message__icon--text-white svg{
        fill: #fff !important;
    }
    .navbar-message{
        flex-direction: column;
        position: static;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    .navbar-message__dropdown--active{
        position: static !important;
        display: flex;
        background-color: #fff !important;
        /* border: 1px solid rgba(0,0,0,.15); */
        border-radius: 0.25rem;
        z-index: initial;
        width: 100% !important;
    }
}

