.banner-button-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items:flex-end;
    justify-content: center;
    pointer-events: none;
}

.banner-button-container{
    pointer-events: auto;
    margin: 10px 0;
    border: solid 1px #42a6c9;
    background-color: #008da9;
    transition: all 0.3s ease;
    /* height: 50px; */
}
.banner-button-container__content{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
}
.banner-button-container__content span{
    text-align: justify;
}
.banner-button-container__close{
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
}
.banner-button-container img{
    transform:scale(.8);
}
.banner-button-container__open{
    border-radius: 3px;
    color: white;
    padding-right: 10px;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
}

.banner-button-container__content__action-button{
    padding: 5px 10px;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #008da9;
    color: #008da9;
    background-color: #fff;
    min-width: fit-content;
}
.banner-button-container__content__action-button:hover{
    color: #008da9;
    transform: scale(1.05);
    transition: all .1s ease;
}
.banner-button-container__close-button{
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: fit-content;
}
.banner-button-wrapper__switch-view{
    display: none;
}
@media screen and (max-width: 880px){
    .banner-button-wrapper__switch-view{
        display: block;
    }
    .banner-button-wrapper__switch-view__view-button{
        pointer-events: auto;
        cursor: pointer;
        color: #008da9;
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #008da9;
        background-color: #fff;
        padding: 10px 20px;
    }
    .banner-button-wrapper--close {
        display: grid;
        grid-template-columns: 7fr 5fr;
        align-items: end;
    }
    .banner-button-wrapper--close .banner-button-wrapper__switch-view__view-button{
        margin: 10px 0px;
    }
    .banner-button-wrapper--close .banner-button-wrapper__switch-view{
        display: flex;
        justify-content: flex-end;    
    }
    .banner-button-wrapper--close .banner-button-wrapper__quick-request{
        display: flex;
        justify-content: flex-end;
        padding-right: 50px;
    }
    .banner-button-wrapper--close .banner-button-wrapper__switch-view__view-button{
        width: fit-content;
    }
    .banner-button-wrapper--close .banner-button-container{
        width: fit-content;
    }
    .banner-button-wrapper--open{
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

}
@media screen and (max-width: 650px){
    .banner-button-wrapper--close {
        grid-template-columns: 7.5fr 4.5fr;
    }
    .banner-button-wrapper--close .banner-button-wrapper__quick-request{
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
    }
}
@media screen and (max-width: 500px) {
    .banner-button-container__content{
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 450px){
    .banner-button-wrapper--close {
        grid-template-columns: 8fr 4fr;
    }
    .banner-button-wrapper--close .banner-button-wrapper__quick-request{
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
    }
}