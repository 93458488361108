.career-list-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px;
}

.career-list-form__buttons{
    padding-left: calc(20% + 20px);
    display: flex;
    gap: 20px;
}

@media screen and (max-width:1400px) {
    .career-list-form{
        gap: 8px;
        padding: 35px;
    }
    .career-list-form__buttons{
        padding-left: calc(30% + 20px);
    }
}
@media screen and (max-width:1200px){
    .career-list-form{
        font-size: 14px;
        padding: 20px 35px;
    }
}
@media screen and (max-width:900px) {
    .career-list-form__buttons{
        padding-left: calc(25% + 20px);
    }
}

@media screen and (max-width:450px) {
    .career-list-form__buttons{
        padding-left: calc(35% + 20px);
    }
}
