
/* .custom-date-range-alt__container .dx-daterangebox{
    padding-left: 35.67px;
} */
.custom-date-range-alt__container{
    min-width: 235px;
}
.custom-date-range-alt__container .dx-placeholder{
    font-size: smaller;
}
.custom-date-range-alt  input{
    text-align: center;
    padding-right: 0px !important;
}
.custom-date-range-alt .dx-daterangebox .dx-editor-outlined.dx-state-hover::before,.custom-date-range-alt .dx-daterangebox .dx-editor-outlined.dx-state-focused::before{
    border-bottom-color: transparent !important
}
.custom-date-range-alt .custom-input-alt__container .dx-start-datebox{
    margin-right: 5px;  
    width: calc(100% + 50px) !important;
}

.custom-date-range-alt .custom-input-alt__container .dx-end-datebox input{
    padding-left: 0px !important;
}
.custom-date-range-alt .custom-input-alt__container .dx-placeholder{
    width: 100%;
    text-align: center;
}
.custom-date-range-alt .custom-input-alt__container .dx-end-datebox .dx-placeholder{
    padding-left: 0px !important;
}
.custom-date-range-alt__container .dx-texteditor-buttons-container{
    display: none !important;
}

.custom-date-range-alt__container .dx-daterangebox-separator .dx-icon{
    display: none !important;
}
.custom-date-range-alt__container .dx-daterangebox{
    display: flex;
    align-items: center;
}
.custom-date-range-alt__container .dx-daterangebox-separator{
    width: 10px;
    height: 1px;
    background-color: #a5a5a5;
}

.dx-datebox-wrapper .dx-calendar-contoured-date span{
    border-color: #008da9;
    box-shadow: 0 0 0 2px #008da96b;
}

.dx-datebox-wrapper .dx-calendar-cell.dx-state-hover span{
    background-color:#008da96b !important;
}

.dx-datebox-wrapper .dx-calendar-today span{
    border-color: #008da9;
}

.dx-datebox-wrapper .dx-calendar-selected-date span{
    background-color:#008da9;
}

.dx-datebox-wrapper .dx-calendar-cell-in-range::before{
    background-color: #008da92e;
}
.dx-datebox-wrapper .dx-calendar-body .dx-calendar-views-wrapper{
    border: 1px solid #008da96b;
}
.dx-datebox-wrapper .dx-calendar-body .dx-calendar-views-wrapper .dx-widget{
    border: 1px solid #008da96b;

}
.dx-datebox-wrapper .dx-calendar-body .dx-calendar-views-wrapper .dx-widget table tr th{
 text-align: center;
}
@media screen and (max-width: 1497px) {
    .custom-date-range-alt__container .dx-placeholder{
        font-size: x-small;
    }
}

@media screen and (max-width: 1392px) {
    .custom-date-range-alt__container .dx-placeholder{
        font-size: xx-small;
    }
}
@media screen and (max-width: 1330px) {
    .custom-date-range-alt__container .dx-placeholder{
        font-size: 7.5px;
    }
}
@media screen and (max-width: 1305px) and (min-width: 1250px) {
    .custom-date-range-alt  input{
        font-size: smaller !important;
    }
}
@media screen and (max-width: 1249px) {
    .custom-date-range-alt__container .dx-placeholder{
        font-size: smaller;
    }
}