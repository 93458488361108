@font-face {
    font-family: 'Oasis';
    src: local('Oasis'), url(../../../assets/fonts/bartonGrotesque/brandon-grotesque-light-italic-58a8a4ccb88be.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.subtitle {
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    font-style: italic;
    font-family: Oasis;
    letter-spacing: 2px;
}
