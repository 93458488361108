.list-a-property-content{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 13.5px;
}
.list-a-property-content form{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 90%;

}
.list-a-property-content__row{
    display: grid;
    grid-template-columns: 6fr 6fr;
}

.list-a-property__buttonarea{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-left: 190px;
}

@media screen and (max-width: 900px) {
    .list-a-property-content__row{
        grid-template-columns: 1fr;
        row-gap: 30px;
    }
    /* .list-a-property__buttonarea{
        margin-left: calc(170px + 20px);
    } */
}

@media screen and (max-width: 600px) {
    .list-a-property-content form{
        width: 98%;
    }
}
@media screen and (max-width: 450px) {
    .list-a-property__buttonarea{
        row-gap: 20px;
        margin-left: 0px;
        align-items: center;
    }
    .list-a-property-content form{
       font-size: 12px;
    }
    .list-a-property-content form{
        width: 100%;
    }
}
