.layer-mask {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 4rem auto 0 auto;
    flex-grow: 1;
}

.msg-container-success {
    background-color: #eef9fd !important;
    border: 1px solid #42a6c9 !important;
}

.msg-container-error {
    background-color: #fbeded;
    border: 1px solid #d9534f;
}

.msg-container-verify {
    background-color: transparent !important;
    border: none !important;
}

.msg-container-success,
.msg-container-error,
.msg-container-verify {
    border-radius: 3px !important;
    width: auto !important;
    height: auto !important;
    padding: 2rem !important;
}

.loader {
    width: 45px;
    height: 45px;
}

.btn-account-success {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #42a6c9 !important;
    border: 1px solid #42a6c9 !important;
    border-radius: 3px;
    color: #fff !important;
    width: 72px;
    height: 36px;
    transition: all 0.2s ease-in-out;
}

.btn-account-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6 !important;
    border: 1px solid #f6f6f6 !important;
    border-radius: 3px;
    color: #757575 !important;
    width: 270px;
    height: 36px;
    transition: all 0.2s ease-in-out;
}

.btn-account-success:hover {
    background-color: #fff !important;
    border: 1px solid #42a6c9 !important;
    color: #42a6c9 !important;
}

.fc-account-success {
    color: #42a6c9 !important;
}

.fc-account-error {
    color: #7e0401 !important;
}