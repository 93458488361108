.navbar-cart{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.navbar-cart .navbar-cart__icon{
    padding: 0px 10px;
    position: relative;
    cursor: pointer;
}

.navbar-cart__icon div:nth-child(2){
    position: absolute;
    left: 0;
    top: -5px;
}
@media screen and (max-width: 767px) {
    .navbar-cart__icon{
        padding: 0px !important;
        margin: 0px;
        margin-bottom: 8px;
    }
    .navbar-cart__icon div:nth-child(2){
        position: absolute;
        left: -10px;
    }
    .navbar-cart{
        flex-direction: column;
        position: static;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
}

