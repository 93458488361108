.user-profile{
    margin-bottom: 50px;
}
.user-profile .user-profile__username{
    display: flex;
    gap: 15px;
    align-items: center;
    color: #515151;
}
.user-profile .user-profile__email p{
    color:#6c757d;
    font-size: smaller;
}
.user-profile .user-profile__username .user-profile__username__title{
    text-transform: uppercase;
    font-weight: 700;
}
.user-profile__sections{
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    gap: 5%;
}
.user-form__container{
    /* display: flex;
    justify-content: space-between; */
    border-radius: 3px;
    border: solid 1px #cdcdcd;
    background-color: #f8f8f8;
    padding: 20px;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;
    /* width: fit-content; */
}
.user-form__container__fields{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 5px;
}
.user-form__container__fields__field{
    display: flex;
    gap: 10px;
    align-items: center;
}
.user-form__container__fields__field p{
    margin: 0;
}
.user-form__container__fields--textbox{
    gap: 12px;
}
.user-form__container__fields--textbox .user-form__container__fields__field .user-form__container__fields__field__label{
    width: 68px;
    text-align: right;
}
.user-form__container__fields__field__input{
    /* width: 350px; */
    width: calc(100% - 78px);
}
.user-form__container__buttons{
    margin-left: 78px;
    display: flex;
    gap: 15px;
}
/* .user-form__container .user-form__container__buttons .secondary-action-button{
    padding-top: 5.2px;
    padding-bottom: 5.2px;
} */
.manage-account-info-btn-container{
    margin-top: 5px;
}
.manage-account-info-btn-container span{
    padding: 10px 0;
    color: #008da9;
    cursor: pointer;
}
.user-form__container__photo{
    display: flex;
    justify-content: end;
}
.profile-image-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.profile-image {
   
    width: fit-content;
    height: fit-content;
}

.profile-image img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    /* margin: 0 0 1rem 0; */
}
.profile-image-container{
    font-size: 14px;
}

.user-form__container .custom-popup .custom-popup__content{
    width: fit-content !important;
}
.user-form__container__modal{
    display: flex;
    width: 40rem;
    padding: 40px;
    flex-direction: column;
    gap: 20px;
    color: #515151;
}
.user-form__container__modal .user-form__container__modal__title{
    display: flex;
    justify-content:  center;
    font-weight: 700;
    text-transform: uppercase;
}
.user-form__container__modal .user-form__container__modal__body{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.user-form__container__modal__body .user-form__container__modal__body_img{
    background-color: rgb(150, 150, 150);
    width: fit-content;
}
.user-form__container__modal__body .user-form__container__modal__body_img img{
    border-radius: 50%;
    width: 120px;
    height: 120px;
}
.user-form__container__modal__body .user-form__container__modal__body_description{
    text-align: justify;
}
.user-form__container__modal .user-form__container__modal__footer{
    display: flex;
    gap: 20px;
}
@media screen and (max-width: 1550px) {
    .user-form__container{
        grid-template-columns: 73% 25%;
        gap: 2%;
    }
}
@media screen and (max-width: 1350px) {
    .user-form__container{
        grid-template-columns: 68% 30%;
        gap: 2%;
    }
}
@media screen and (max-width: 1150px) {
    .user-form__container{
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
    }
    .user-form__container__photo{
        justify-content: center;
    }
}
@media screen and (max-width: 991px) {
    .user-profile__sections{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .user-form__container{
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 2%;
    }
    .user-form__container__photo{
        justify-content: end;
    }
}
@media screen and (max-width: 750px) {
    .user-form__container{
        display: grid;
        grid-template-columns: 73% 25%;
        gap: 2%;
    }
}
@media screen and (max-width: 650px) {
    .user-form__container{
        display: grid;
        grid-template-columns: 68% 30%;
        gap: 2%;
    }
    .user-form__container__modal{
        width: 90vw;
    }
}
@media screen and (max-width: 650px) {
    .user-form__container{
        display: grid;
        grid-template-columns: 68% 30%;
        gap: 2%;
    }
}
@media screen and (max-width: 550px) {
    .user-form__container{
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
    }
    .user-form__container__photo{
        justify-content: center;
    }
    .user-form__container__modal{
        padding: 20px;
    }
}

/* @media screen and (max-width: 420px) {
    .user-form__container__modal{
        width: 19rem;
        padding: 5px;
    }
} */
/* @media screen and (max-width: 400px) {
    .user-form__container{
        padding: 10px;
    }
} */
/* 
.user-profile {
    width: 100%;
    height: 100%;
    //    min-height: 80vh;
    margin-bottom: 50px;
}
.user-profile__sections{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5%;
}
.user-form-title,
.guest-form-title {
    border-left: 3px solid #008da9;
    padding: 0 0 0 0.3rem;
    font-weight: 700;
}

.log-out-btn {
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    color: #979797;
    transition: all 0.2s ease-in-out;
    margin: 0 1rem 1rem 0.9rem;
}

.log-out-btn:hover {
    background-color: #fff;
    color: #979797;
}

.user-form {
    background-color: #f8f8f8;
    margin-top: 20px;
    // padding: 1rem 2rem;
    padding: 5%;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.009);
    width: 100%;
}

.user-form-label input {
    width: 250px !important;
    height: 35px !important;
}

.user-form__container-phone {
    width: 250px;
}

.user-form-label label span {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
    font-weight: bold !important;
}


.user-form-label-disabled label span {
    margin: 0 auto 0 0;
}

// Manage Account Info Buttons 
.manage-account-info-btn-container {
    margin: 0 0 0 102px;
    width: 180px !important;
}

.manage-account-info-btn {
    border: none;
    outline: none;
    color: #008da9;
    background: none;
}

.manage-account-info-title {
    color: #008da9;
}

// Save Account Info Buttons 
.save-account-info-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 100px;
    width: 180px !important;
}

.save-btn {
    width: 100px;
    height: 27px;
    border: 1px solid #008da9;
    border-radius: 3px;
    background-color: #008da9;
    color: #fff;
    margin: 0 1rem 0 0;
    transition: all 0.2s ease-in-out;
}

.save-btn:hover {
    background-color: #fff;
    color: #008da9;
}

.cancel-btn {
    width: 100px;
    height: 27px;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    background-color: #eaeaea;
    color: #979797;
    transition: all 0.2s ease-in-out;
}

.cancel-btn:hover {
    background-color: #fff;
    color: #979797;
}

// Profile Image 


.profile-image-title {
    color: #008da9;
}

.upload-file-container label {
    cursor: pointer;
}

#image-file {
    display: none;
}

// Guest Form 
.guest-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    height: auto;
    padding: 0 0 2rem 0;
}

.guest-form {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 1rem;
    width: 278px;
    height: auto;
}

.guest-form-empty {
    border: 0;
}

.guest-form-label>div {
    font-size: 12px;
}

.guest-form-label label span {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: center !important;
    font-weight: bold;
    font-size: 12px;
}

// Edit Photo Modal 
.custom-modal-image-container__outer-layer {
    border-radius: 3px;
    border: 1px solid #a7a7a7;
    background-color: #a7a7a7;
    color: #000;
    width: 100px;
    height: 100px;
    margin: 0 0 1rem 0;
}

.custom-modal-image-container__inner-layer {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.custom-modal-image-container__inner-layer>img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.edit_photo_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.45);
    transition: all 0.2s ease-in-out;
}

.edit_photo_modal .modal-header {
    border-bottom: 0 none !important;
    margin: 0 auto !important;
    text-transform: uppercase;
    font-weight: 700 !important;
}

.edit_photo_modal .modal-body {
    //width: 300px !important;
    margin: 0 auto !important;
}

.edit_photo_modal .modal-footer {
    border-top: 0 none !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.edit_photo_modal .modal-dialog {
    width: auto !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16rem auto 0 auto;
}

.edit_photo_modal .modal-content {
    width: 720px !important;
    padding: 1rem 2rem !important;
    background-color: #f8f8f8;
}

.edit_photo_modal .custom-modal-save-btn {
    background-color: #008da9;
    color: #fff;
}

.edit_photo_modal .custom-modal-cancel-btn {
    background-color: #f7f7f7;
    color: #000;
}

// Media Queries 
@media screen and (min-width: 320px) and (max-width: 991px) {

    .user-form-label,
    .user-form-label-disabled {
        flex-wrap: wrap;
    }

    .user-form-label input {
        width: 100% !important;
    }

    .manage-account-info-btn-container,
    .save-account-info-btn-container {
        margin: 0 0 1rem 0;
    }
}

@media screen and (min-width: 320px) and (max-width: 1399px) {
    .guest-form {
        width: 100%;
    }

    .guest-form-label label span {
        font-size: 10px;
        margin: 0;
        padding: 0;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .guest-form-title {
        margin: 1rem 0;
    }
}

// edit photo modal 
@media screen and (min-width: 320px) and (max-width: 479px) {
    .edit_photo_modal>div {
        width: 270px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-body {
        width: 270px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-footer {
        border-top: 0 none !important;
        width: 270px !important;
        margin: 0 auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 480px) and (max-width: 547px) {
    .edit_photo_modal>div {
        width: 360px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-body {
        width: 360px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-footer {
        border-top: 0 none !important;
        width: 360px !important;
        margin: 0 auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 548px) and (max-width: 567px) {
    .edit_photo_modal>div {
        width: 450px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-body {
        width: 450px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-footer {
        border-top: 0 none !important;
        width: 450px !important;
        margin: 0 auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 568px) and (max-width: 719px) {
    .edit_photo_modal>div {
        width: 500px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-body {
        width: 500px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-footer {
        border-top: 0 none !important;
        width: 500px !important;
        margin: 0 auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 720px) and (max-width: 767px) {
    .edit_photo_modal>div {
        width: 600px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-body {
        width: 600px !important;
        margin: 0 auto !important;
    }

    .edit_photo_modal .modal-footer {
        border-top: 0 none !important;
        width: 600px !important;
        margin: 0 auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1399px) and (min-width: 1200px) {
    .user-form__container-phone {
        width: 209px;
    }
}

@media screen and (max-width: 1199px) {
    .user-form__container-phone {
        width: 100%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {

    .cancel-btn,
    .manage-account-info-title {
        z-index: 1;
    }
}

@media screen and (min-width: 1400px) {
    // .user-form {
    //    width: 630px !important;
    //} 
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .user-form-label input {
        width: 209px !important;
    }

    //.user-form-label-disabled label span,
   // .offset-font {
   //     font-size: 10px;
   // } 

    .user-form-label-disabled label {
        width: 72px !important;
    }
}
@media screen and (max-width: 991px) {
    .user-profile__sections{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    
}
@media screen and (min-width: 320px) and (max-width: 1399px) {

    .user-form-label-disabled label span,
    .offset-font {
        font-size: 10px;
    }
}

@media screen and (min-width: 320px) and (max-width: 519px) {
    .log-out-btn {
        width: 90px;
        height: 22px;
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .offset-title {
        font-size: 10px;
    }
} */