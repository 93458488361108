.dropdown-input__container--error .dx-texteditor-container{
    border: 1px solid #d9534f;
}

.dropdown-input__container__message--error{
    position: absolute;
    z-index: 100;
    padding: 8px 10px;
    color: #fff;
    background-color: #d9534f;
    border-radius: 4px;
}