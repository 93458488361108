.terms-container p {
    color: #888888;
    cursor: default;
}
.terms-container h2 {
    color: black;
    margin-top: 50px;
    font-size: 18px;
    font-style: italic;
}


.backgroung-img-block {
    background: url('../../../../../images/homeslide01.jpg') no-repeat;
    background-position-x: center;
    min-width: 100%;
    height: 60vh;
}

.terms-container .main-content {
    width: 83.333333%;
    align-self: center;
    margin-bottom: 50px;
    font-size: 14px;
}
