.checkbox-multiple{
    padding-top:6px;
    display: flex;
    column-gap: 20px;
}

.checkbox-multiple__name{
    text-align: right;

    font-weight: 800;
    font-size: 14px;
    color: #515252;
}

.checkbox-multiple__options{
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

@media screen and (max-width: 450px) {
    .checkbox-multiple{
        display: grid;
        grid-template-columns: 35% calc(65% - 20px) !important;
        gap: 20px;
    }
}