.not-logged-message{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5000;
    display: flex;
    align-items:flex-end;
    justify-content: center;
    pointer-events: none;
}

.not-logged-message__content{
    pointer-events: auto;
    display: flex;
    gap: 5px;
    width: 80%;
    margin-bottom: 2.4vh;
    border-radius: 5px;
    align-items: center;
    padding: 16px 0;
    background-color: #febc22;
    color: #4b4b4b;
    transition: all 1s ease;
}
.not-logged-message__content img{
    padding: 0px 8px 0px 25px;
}
.not-logged-message__content .not-logged-message__content__link{
    cursor: pointer;
    color: #008da9;
}

.not-logged-message__content--active{
    opacity: 1;
    transition: all 1s ease;
}
.not-logged-message__content--inactive{
    pointer-events: none;
    opacity: 0;
    transition: all 1s ease;
}
