.request-properties-card-options{
    padding-top: 30px;
    display: grid;
    gap: 20px;

    grid-template-columns: 1fr 7fr 2fr 2fr;
    /* align-items: center; */
    grid-template-areas: 
        "image   information    status  buttons"
        "image   information    status  buttons"
        "image   information    status  buttons"
        "image   information    status  buttons" 
        "divisor divisor        divisor divisor"
    ;
}

.request-properties-card-options__field-status{
    display: none;
}
.request-properties-card-options__information{
    display: flex;
    grid-area: information;
}
.request-properties-card-options__information__content{
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 7fr 5fr;
    grid-template-areas: 
    "informationContentLeft informationContentRight"
    "informationContentExtensionList informationContentExtensionList"
    ;
    /* justify-content: space-between; */
}
.request-properties-card-options__information__right{
    grid-area: informationContentRight
}
.request-properties-card-options__information__left{
    grid-area: informationContentLeft

}
.request-properties-card-options__information__extension-list{
    grid-area: informationContentExtensionList;    
}
.request-properties-card-options__information__extension-list__separator{
    border: 2px dotted #c7c7c7;
    border-bottom: none;
    margin-bottom: 10px;
}
.request-properties-card-options__information__extension-list__content{
    display: flex;
    gap: 20px;
    max-height: 250px;
    overflow-y: auto;
}
.request-properties-card-options__information__extension-list__content span{
    font-weight: 700;
}
.request-properties-card-options__information__extension-list__content__centered{
    display: flex;
    align-items: center;
    gap: 8px;
}
.request-properties-card-options__information__extension-list__content__tag{
    color: #ffff;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: x-small;
    display: flex;
    justify-content: center;
}
.request-properties-card-options__img{
    grid-area: image;
}
.request-properties-card-options__img img{
    height:115px;
    width:160px;
}

.request-properties-card-options__information__field{
    display: flex;
    gap: 10px;
}
.request-properties-card-options__information__field__name{
    white-space: nowrap;
    font-weight: 1000;
}
.request-properties-card-options__status{
    grid-area: status;
}

.request-properties-card-options__status__tag{
    display: flex;
    height: fit-content;
    width: fit-content;
    align-items: center;
    background-color: #cdcdcd;
    padding: 3px 10px;
    font-size: small;
    border-radius: 40px;
}
.request-properties-card-options__status__tag--new{
    color: #fff;
    background-color: #05b15a;
}
.request-properties-card-options__status__tag--reserved{
    color: #fff;
    background-color: #008da9;
}
.request-properties-card-options__buttons{
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
}
.request-properties-card-options__buttons__content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.request-properties-card-options__buttons__content__view img{
    height: 16px;
    width: 16px;
}
.request-properties-card-options__buttons__content__view,
.request-properties-card-options__buttons__content__view:hover
{
    display: flex;
    white-space: nowrap;
    gap: 5px;
    align-items: center;
    color: #008da9;
    cursor: pointer;
}
.request-properties-card-options__buttons__content__delete{
    display: flex;
    gap: 5px;
    align-items: center;
    color: #d9534f;
    cursor: pointer;
}
.request-properties-card-options__buttons__content__new{
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.request-properties-card-options__buttons__content__new.btn-cancel .action-button {
    background-image: linear-gradient(to bottom, #e2817e, #d9534f);
}

.request-properties-card-options__buttons__content__new.btn-declined .action-button {
    /* background-image: linear-gradient(to bottom, #e2817e, #d9534f); */
    color: #d43f3a;

    background-image: linear-gradient(to bottom, #ffffff00, #ffffff00);
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #d43f3a;
}

.request-properties-card-options__buttons__content__new.btn-declined:hover .action-button {
    /* background-image: linear-gradient(to bottom, #e2817e, #d9534f); */
    color: #fff;

    background-image: linear-gradient(to bottom, #e2817e, #d9534f);
}
.request-properties-card-options__buttons__content__new_content-wrapper{
    display: flex;
    flex-direction: column;
}
.request-properties-card-options__buttons__content__new__blocked-message{
    font-style: italic;
    font-size: smaller;
}
.request-properties-card-options__buttons__content__new__blocked-message span{
    color: #d43f3a;
}
.request-properties-card-options .custom-popup__content{
    width: 600px;
}

.request-properties-card-options__popup{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.request-properties-card-options__popup__title{
    color: #515151;
    text-align: center;
    font-weight: 800;
}
.request-properties-card-options__popup__buttons{
    /* padding-left: calc(125px + 20px); */
    display: flex;
    gap: 10px;
}
.request-properties-card-options__popup__message{
    display: flex;
    justify-content: center;
}
.request-properties-card-options__buttons__content__cancel{
    display: flex;
    cursor: pointer;
    color: #d9534f;
    gap: 5px;
}
.request-properties-card-options__buttons__content__cancel span:first-child{
    width: 16px;
}
.request-properties-card-options__buttons__content__cancel span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
}
.request-properties-card-options__buttons__content__cancel:hover{
    transform: scale(1.02);
}
.request-properties-card-options__buttons__content__decline{
    cursor: pointer;
    color: #d9534f;
    border: 1.5px solid #d9534f;
    border-radius: 2px;
    text-align: center;
    padding: 4px 0px;
    transition: background-color .5s ease;
}
.request-properties-card-options__buttons__content__decline:hover{
    background-color: #d9534f;
    color: #fff;
    transition: background-color .5s ease;

}
.request-properties-card-options__divisor{
    grid-area: divisor;
    margin: 20px 0px 0px 0px;
    border: 1px solid #cdcdcd;
}
.request-properties-card-options__title{
    display: none;
}

.request-properties-card-options .missing-text {
    color: lightgray;
    font-style: italic;
}
.request-properties-card-options__information__field__value{
    display: flex;
    white-space: nowrap;
    gap: 5px;
}
.request-properties-card-options__information__field__value__tax{
    display: flex;
    white-space: nowrap;
    align-items: center;
    gap: 2px;
}
.request-properties-card-options__information__field__value__tax-icon{
    background-color: #008da9;
    height: 60%;
    color: white;
    padding: 0 6px;
    font-size: small;
    display: flex;
    align-items: center;
    border-radius: 100%;
}
.request-properties-card-options__information__field__value--link{
    color: #008da9;
    text-decoration: underline !important;
}
.request-properties-card-options__information__field__value--link:hover{
    color: #006376;
}
.request-properties-card-options__message__pending-extension{
    padding: 5px 10px;
    background-color: #81eaff38;
    border: 1px solid #008da9;
    border-radius: 2px;
}
.request-properties-card-options__message__pending-extension__title{
    font-weight: 700;
}
.request-properties-card-options__message__pending-extension__dates{
    font-size: smaller;
}
.request-properties-card-options__message__pending-extension__cancel{
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #d43f3a;
    fill: #d43f3a;

}
.request-properties-card-options__message__pending-extension__comment{
    font-size: smaller;
    font-style: italic;
}
.request-properties-card-options__message__pending-extension__pending-confirmation__title, .request-properties-card-options__message__pending-extension__pending-confirmation__content{
    font-size: smaller;
}
.request-properties-card-options__message__pending-extension__pending-confirmation__title, .request-properties-card-options__message__pending-extension__pending-confirmation__content span{
    font-weight: 900;
}
.request-properties-card-options__message__pending-extension__pending-confirmation__content{
    padding-left: 10px;
}
.request-properties-card-options__message__pending-extension__pending-confirmation__buttons{
    padding: 6px 0px;
    display: flex !important;
    gap: 10px;
}
.request-properties-card-options__message__pending-extension__pending-confirmation__buttons .btn-declined{
    background-color: #ffff;
}
@media screen and (max-width:1350px){
    .request-properties-card-options__popup{
        padding: 20px 25px;
        gap: 6px;
    }
}
@media screen and (max-width: 1200px) {
    .request-properties-card-options{
        grid-template-columns: 1fr 7.5fr 1.5fr 2fr;
    }
}
@media screen and (max-width: 1100px) {
    .request-properties-card-options{
        grid-template-columns: 1fr 8fr 3fr;
        grid-template-areas: 
            "image     information     buttons"
            "image     information     buttons"
            "image     information     buttons"
            "image     information     buttons" 
            "divisor   divisor         divisor"
        ;
    }
    .request-properties-card-options__img img{
        height:135px;
        width:180px;
    }
    .request-properties-card-options__information__content{
        grid-template-columns: 12fr;
        gap: 2px;
        grid-template-areas: 
        "informationContentLeft"
        "informationContentRight"
        "informationContentExtensionList"
        ;
    }
    .request-properties-card-options__field-status{
        display: flex;
    }
    .request-properties-card-options__status{
        display: none;
    }
}
@media screen and (max-width: 700px){
    .request-properties-card-options .custom-popup__content{
        width: fit-content;
    }
    .request-properties-card-options__information__field__option-id, .request-properties-card-options__information__field__property-name{
        display: none;
    }
    .request-properties-card-options__title{
        display: flex;
        flex-direction: column;
        grid-area: title;
    }
    .request-properties-card-options{
        row-gap: 5px;
        grid-template-columns: 1fr 11fr;
        grid-template-areas: 
            "image     title"
            "image     title"
            "image     buttons"
            "image     buttons" 
            "information information"
            "divisor   divisor"
        ;
    }
    .request-properties-card-options__buttons{
        justify-content: flex-start;
    }
    .request-properties-card-options__buttons__content__new{
        flex-direction: row;
    }
    .request-properties-card-options__buttons__content__decline{
        padding: 5px 20px;
    }
}
@media screen and (max-width: 560px){
    .request-properties-card-options__popup{
        padding: 15px 15px;
        gap: 5px;
    }
}
@media screen and (max-width: 500px){
    .request-properties-card-options{
        grid-template-columns: 12fr;
        grid-template-areas: 
            "image"
            "title"
            "buttons"
            "information"
            "divisor"
        ;
    }
    .request-properties-card-options__img{
        display: flex;
        /* justify-content: center; */
    }
}
@media screen and (max-width: 408px){
    .request-properties-card-options__popup .custom-input{
        grid-template-columns: 100% !important;
        gap: 2px;
    }
    .request-properties-card-options__popup .custom-input .custom-input__label{
        text-align: left;
    }
    .request-properties-card-options__popup .custom-textarea{
        grid-template-columns: 100% !important;
        gap: 2px;
    }
    .request-properties-card-options__popup .custom-textarea .custom-textarea__label{
        text-align: left;
    }
    .request-properties-card-options__popup__buttons{
        padding-left: 0px;
    }
}
/* @media screen and (max-width: 1400px){
    .request-properties-card-options{
        grid-template-columns: 1fr 7fr 2fr 2fr;
    }
}

@media screen and (max-width: 1100px){
    .request-properties-card-options{
        grid-template-columns: 1fr 9fr 2fr;
        grid-template-areas: 
            "image   name    buttons"
            "image   status  buttons"
            "image   address buttons"
            "image   floor   buttons"
            "image   dates   buttons" 
            "divisor divisor divisor"
        ;
    }
    .request-properties-card-options__status{
        padding: 0px 20px;
    }
    .request-properties-card-options__information__img img{
        height:135px;
        width:180px;
    }
}

@media screen and (max-width: 700px) {
    .request-properties-card-options{
        grid-template-columns: 1fr 11fr;
        grid-template-areas: 
                "image  name"
                "image  status"
                "image  buttons"
                "image  buttons"
                "address address"
                "floor   floor"
                "dates   dates" 
                "divisor divisor"
        ;
    }
   .request-properties-card-options__buttons{
        justify-content: flex-start;
        padding: 0px 20px;
    }
    
    .request-properties-card-options__name{
        padding: 0px 20px;
    }
    
    .request-properties-card-options__address{
        padding: 0px;
    }
    
    .request-properties-card-options__floor{
        padding: 0px;
    }
    
    .request-properties-card-options__dates{
        padding: 0px;
    }
    .request-properties-card-options__status{
        padding: 0px 20px;
    }
}

@media screen and (max-width: 500px) {
    .request-properties-card-options{
        grid-template-columns: 1fr 11fr;
        grid-template-areas: 
                "image   image"
                "name    name"
                "status  status"
                "address address"
                "floor   floor"
                "dates   dates" 
                "buttons buttons"
                "divisor divisor"
        ;
    }
    .request-properties-card-options__buttons__content{
        flex-direction: row;
    }
    .request-properties-card-options__name{
        padding: 0px;
    }
    .request-properties-card-options__status{
        padding: 0px;
    }
    .request-properties-card-options__buttons{
        padding: 0px;
    }
    .request-properties-card-options__information__img{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
} */
