.career-apply{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.career-apply form{
    display: flex;
    font-size: 14px;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    background-color: rgb(243, 243, 243);
    border: 1px rgb(165, 165, 165) solid;
    padding: 30px 10%;
}
.career-apply__button{
    margin-left: calc(20% + 20px);
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 1400px) {
    .career-apply form{
        width: 85%;
        padding: 30px 8%;
        gap: 10px;
    }
    .career-apply__button{
        margin-left: calc(30% + 20px);
    }
}

@media screen and (max-width: 900px) {
    .career-apply form{
        width: 95%;
        padding: 30px 6%;
    }
    .career-apply__button{
        margin-left: calc(25% + 20px);
    }
}

@media screen and (max-width: 600px) {
    .career-apply form{
        width: 100%;
        padding: 30px 4%;
    }
}

@media screen and (max-width: 450px) {
    .career-apply form{
        padding: 20px 2%;
    }
    .career-apply__button{
        margin-left: calc(35% + 20px);
        
    }
}