.secondary-action-button{
    border: 1px solid #eaeaea !important;
    border-radius: 4px;
    background: #e0e0e0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #f5f5f5, #e0e0e0);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f5f5f5, #e0e0e0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* font-family: Brandon-Grotesque-Regular; */
    color: #a0a0a0;
    font-size: 14px;
    /* padding: 4px 12px; */
}

.secondary-action-button:hover{
    background: #bdbdbd;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #f3f3f3, #bdbdbd);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3, #bdbdbd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}