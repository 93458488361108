.admin-article-modal-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
}

.admin-article-modal-none {
    display: none;
}

.admin-article-modal {
    border-radius: 3px;
    width: 900px;
    height: auto;
    background-color: #f8f8f8;
    position: relative;
    z-index: 101;
}

.admin-article-modal-title {
    text-align: center;
    text-transform: capitalize;
    color: #000;
    margin: 3rem 0 0 0 !important;
}

.modal-label label span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 700;
}

.modal-label-image-preview label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 700;
    position: relative;
    top: 9px;
    right: 17px;
}

.oasis-btn {
    background-color: #008da9 !important;
    color: #fff !important;
    margin: 0 0 0 6.5rem !important;
}

.oasis-btn-edit {
    background-color: #008da9 !important;
    color: #fff !important;
    margin: 0 0 0 6.5rem !important;
}

.oasis-cancel-btn {
    background-color: #f2f2f2 !important;
    color: #000 !important;
    margin: 0 1rem !important;
}

.form-padding {
    padding: 1rem 3rem 2rem 3rem !important;
}

.file-uploader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 25% !important;
    width: 100% !important;
}

.text-oasis {
    color: #008da9 !important;
}

/* Required Field Mark - Asterisk */
.modal-label.dx-field-item-required .dx-field-item-label-text::after {
    content: "*";
    color: #c9302c;
    padding-left: 5px;
}

.modal-label span.dx-field-item-required-mark {
    display: none !important;
}

/* Media Queries */
@media screen and (min-width: 320px) and (max-width: 389px) {
    .admin-article-modal-title {
        text-align: left;
        padding: 0 0 14px 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 300px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }
}

@media screen and (min-width: 390px) and (max-width: 449px) {
    .admin-article-modal-title {
        text-align: left;
        padding: 0 0 14px 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 340px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 0 0 9px !important;
    }
}

@media screen and (min-width: 450px) and (max-width: 547px) {
    .admin-article-modal-title {
        text-align: left;
        padding: 0 0 14px 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 400px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 0 0 9px !important;
    }
}

@media screen and (min-width: 548px) and (max-width: 567px) {
    .admin-article-modal-title {
        text-align: left;
        padding: 0 0 14px 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 450px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 0 0 9px !important;
    }
}

@media screen and (min-width: 568px) and (max-width: 719px) {
    .admin-article-modal-title {
        text-align: center;
        padding: 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 540px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 !important;
    }
}

@media screen and (min-width: 720px) and (max-width: 767px) {
    .admin-article-modal-title {
        text-align: center;
        padding: 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 630px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 0 0 9px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 919px) {
    .admin-article-modal-title {
        text-align: center;
        padding: 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 720px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 0 0 9px !important;
    }
}

@media screen and (min-width: 920px) and (max-width: 991px) {
    .admin-article-modal-title {
        text-align: center;
        padding: 0 !important;
    }

    .admin-article-modal-overlay {
        width: 100% !important;
    }

    .admin-article-modal {
        width: 900px !important;
        height: auto !important;
        margin: 0 auto !important;
    }

    .admin-article-textarea {
        width: 100% !important;
        height: 100px !important;
    }

    .text-oasis {
        font-size: 12px !important;
    }

    .modal-label {
        flex-wrap: wrap;
    }

    .modal-label label span {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 1rem 0;
    }

    .modal-label.text-capitalize {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .modal-label-image-preview.text-capitalize.dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-item-optional.dx-flex-layout.dx-label-h-align {
        flex-wrap: wrap !important;
        padding: 0 0 2rem 0 !important;
    }

    .modal-label-image-preview label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        position: relative;
        top: 0px;
        right: 0px;
        margin: 1rem 0 !important;
    }

    .oasis-btn,
    .oasis-btn-edit {
        margin: 0 0 1rem 0 !important;
    }

    .oasis-cancel-btn {
        margin: 0 0 1rem 1rem !important;
    }

    .image-preview__text.pointer {
        margin: 0 0 0 9px !important;
    }
}