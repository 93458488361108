.career-by-id{
    color: rgb(118, 118, 118);
    margin-bottom: 20px;
}
.career-by-id li::marker{
    color : rgba(26, 147, 170, 0.90)
}
.career-by-id__message{
    display: flex;
    justify-content: center;
}
.career-by-id__section{
    margin-bottom: 20px;
}