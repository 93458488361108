.request-accordion__title{
    display: flex;
    gap: 8px;
    color: #008da9;
    border-left: 3px solid #cdcdcd ;
    padding-left: 5px;
    cursor: pointer;
    width: fit-content;
}

.request-accordion__title--inactive img{
    transform: rotateX(180deg);
    transition: all .6s;
}

.request-accordion__title--active img{
    transform: rotateX(0deg);
    transition: all .6s;
}

.request-accordion__content{
    padding-left: 8px;
    color: #7c7c7c;
}
.request-accordion__content--inactive{
    max-height: 0px;
    overflow: hidden;
    transition: all .6s ease;
}
.request-accordion__content--active{
    max-height: 1000px;
    overflow: auto;
    transition: all .6s;
}
.request-accordion__wrapper{
    display: flex;
    gap: 20px;
}

.request-accordion__wrapper__filter{
    color:#cdcdcd ;
    display: flex;
    gap: 5px;
    text-align: center;
}

.request-accordion__wrapper__filter__title{
    color:#9f9f9f 
}
.request-accordion__wrapper__filter__button{
    border:2px solid #cdcdcd;
    border-radius: 20px;
    padding: 0px 10px;
    cursor: pointer;
    font-size: smaller;
}

.request-accordion__wrapper__filter__button--active{
   border-color: #008da9;
   color: #008da9;
}