.received-requests{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}
.received-requests__header{
    display: flex;
    justify-content: space-between;
}
.received-requests__title{
    display: flex;
    gap: 15px;
    align-items: center;
    font-weight: 700;
}
.received-requests__message{
    padding: 50px 0;
}
@media screen and (max-width: 767px) {
    .received-requests__header{
        flex-direction: column;
        gap: 20px;
        justify-content: start;
    }
}
