.my-request__option__amenities-tags{
    display: flex;
    gap: 10px;
}

.my-request__option__amenities-tags__tag{
    border: solid 1px #cdcdcd;
    color: #939393;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
}