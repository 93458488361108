.scrollbar {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end !important;
    align-items: center !important;
    height: 100% !important;
    width: 100% !important;
}

.scrollbar-nav {
    height: 64px;
    position: relative !important;
    top: -63px !important;
    background-color: #ECECEC !important;
    box-shadow: 0px 6px 9px -3px rgba(0, 0, 0, 0.18);
}
.scrollbar-nav__container{
    width: 100%;
    padding: 0px !important;
}
.scrollbar-nav__container .navbar-nav{
    padding:0px 5% !important;
}
.scrollbar-nav-link {
    color: #0e93ad !important;
    font-style: italic !important;
    font-weight: 200 !important;
    font-size: 18px !important;
    margin: 0 25px 0 0;
    cursor: pointer !important;
}
.scrollbar-nav__container .navbar-nav li{
   margin-right: 0px !important;
   margin-left: 15px;
}
.active {
    border-bottom: 3px solid #0e93ad !important;
    padding: 0 0 3px 0 !important;
}

.scrollbar-nav-link a.active {
    color: #0e93ad !important;
}

.fixed-scrollbar>div {
    width: 100% !important;
}

.fixed-scrollbar {
    position: fixed !important;
    top: 82px !important;
    left: 0px !important;
    right: 0px !important;
    z-index: 99 !important;
}

.sticky-scrollbar {
    position: fixed !important;
    top: 810px !important;
    left: 0px !important;
    right: 0px !important;
    z-index: 99 !important;
}

@media screen and (min-width: 1300px) and (max-width: 1387px) {
    .scrollbar-nav-link {
        font-size: 16px !important;
    }
}

@media screen and (min-width: 1150px) and (max-width: 1299px) {
    .scrollbar-nav-link {
        font-size: 14px !important;
    }
}

@media screen and (min-width: 1031px) and (max-width: 1149px) {
    .scrollbar-nav-link {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 913px) and (max-width: 1030px) {
    .scrollbar-nav-link {
        font-size: 10px !important;
    }
}

@media screen and (min-width: 853px) and (max-width: 912px) {
    .scrollbar-nav-link {
        font-size: 9px !important;
    }
}

@media screen and (min-width: 794px) and (max-width: 852px) {
    .scrollbar-nav-link {
        font-size: 8px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 793px) {
    .scrollbar-nav-link {
        font-size: 7px !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .scrollbar {
        margin: 0 auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
    .scrollbar {
        margin: 0 auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 1919px) {

    .fixed-scrollbar,
    .sticky-scrollbar,
    .scrollbar-nav {
        width: auto !important;
    }

    .navbar-collapse.collapse.show>div>li {
        margin: 0 0 1rem 0 !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .scrollbar.navbar-nav {
        align-items: flex-start !important;
    }

    .scrollbar-nav {
        height: auto !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .scrollbar-nav>div.container {
        width: 90% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 768px){
    .scrollbar-nav {
        padding: 0px !important;
        padding-right: 5% !important;
    }
}
