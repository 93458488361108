.presentation-tag{
    display: grid;
    grid-template-columns: 1fr 11fr;
    align-items: center;
    gap: 50px;
}

.presentation-tag img{
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
    border: 1px solid #eaeaea;
    transform: scaleX(-1);
}


@media screen and (max-width: 782px){
    .presentation-tag{
        grid-template-columns: 1fr;
        gap: 30px;
    }
    .presentation-tag__img{
        display: flex;
        justify-content: center;
    }
}