.delete-button{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #eaeaea !important;
    border-radius: 4px;
    background: #df726e;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #df726e, #d8534e);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #df726e, #d8534e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* font-family: Brandon-Grotesque-Regular; */
    color: #fff;
    font-size: 14px;
    padding: 4px 12px;
}

.delete-button:hover{
    background: #d76d69;  /* fallback for old browsers */
    color: #fff;
    background: -webkit-linear-gradient(to bottom, #d76d69, #be4844);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #d76d69, #be4844); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}