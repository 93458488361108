.contact-info{
    height: 100%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 20px;
}
.contact-info__title{
    display: flex;
    justify-content: center;
}
.contact-info__fields{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contact-info__field-group{
    display: grid;
    grid-template-columns: 3fr 9fr;
    gap: 10px;
}
.contact-info__field-group__name{
    display: flex;
    justify-content: flex-end;
}
.contact-info__buttons {
    display: flex;
    gap: 20px;
    margin-left: calc(20% + 20px);
}
.contact-info__buttons button{
    padding: 5px 10px;
}
@media screen and (max-width: 1400px) {
    .contact-info__buttons {
        margin-left: calc(30% + 20px);
    }
}
@media screen and (max-width: 900px) {
    .contact-info{
        padding: 40px 30px;
        font-size: 12px;
    }
    .contact-info__buttons {
        margin-left: calc(25% + 20px);
    }
}
@media screen and (max-width: 485px) {
    .contact-info{
        padding: 40px 20px;
        font-size: 11px;
    }
}
@media screen and (max-width: 450px) {
    .contact-info__buttons {
        margin-left: calc(35% + 20px);
    }
}