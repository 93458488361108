.privacy-container{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-self: center;
    width:100%;
}

.privacy-container p{
    width:83.333333%;
}

.privacy-container span,
.privacy-container span span {
    color: #888888;
    cursor: default;
}

.backgroung-img-block {
    background: url('../../../../../images/homeslide01.jpg') no-repeat;
    background-position-x: center;
    min-width: 100%;
    height: 60vh;
}

.backgroung-img-block  .content {
    color: #ffffff;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 30vh;
}

.backgroung-img-block  .content h1 {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.backgroung-img-block img{
    width: 100%;
}

.privacy-container .main-content {
    width: 83.333333%;
    align-self: center;
    margin-bottom: 50px;
    font-size:14px;
}


.privacy-container li::marker {
    color: #888888;
}

.privacy-container h2 *{
    color: black;
    margin-top: 50px;
    font-size:18px;
}

