.icon-number{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #008fac;
    /* text-align: center; */
    color: #fff;
    font-size: .6rem;
    /* padding: 1px 6px; */
    width: 20px;
    border-radius: 100%;
    border: solid 2px #fff;
}
.icon-number--zero{
    background-color: #8e8e8e;
}
.icon-number--plus{
    font-size: .48rem;
    padding: 2px 3px;
}
.icon-number--hide{
    display: none;
}