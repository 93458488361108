.login-msg-border-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left: 3px solid #FEEABF !important;
    border-top: 3px solid #FEEABF !important;
    border-bottom: 3px solid #FEEABF !important;
}

.login-msg-border-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-right: 3px solid #FEEABF !important;
    border-top: 3px solid #FEEABF !important;
    border-bottom: 3px solid #FEEABF !important;
}

.row.login-msg-full-border {
    border: 3px solid #FEEABF !important;
    border-radius: 5px !important;
}

.login-msg-color {
    background-color: #FFFCF0;
}

.login-msg-text {
    font-size: 12px;
    margin: 0 0 0.6rem 0;
}

.quick-request-login-btn {
    background-color: #fff;
    color: #008da9;
    border: 1px solid #008da9;
    border-radius: 5px;
    width: 90px;
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.quick-request-register-btn {
    color: #008da9;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 90px;
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.quick-request-login-btn:hover,
.quick-request-register-btn:hover {
    color: #008da9;
}

.login-msg-container {
    margin: 0.25rem 0 0.5rem 0 !important;
}