
.custom-dropdown-alt-multiple .dx-dropdownbox .dx-texteditor-input{
    font-family: 'Cabin', sans-serif !important; 
    color:#7b7b7b;
    font-size: inherit;
    border-left: 1px solid #eaeaea !important;
    border-top: 1px solid #eaeaea !important;
    border-bottom: 1px solid #eaeaea !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

}
.custom-dropdown-alt-multiple .dx-state-invisible .dx-overlay-content, .custom-dropdown-alt--no-borders .dx-state-invisible .dx-overlay-content{
    display: none !important;
}
.custom-dropdown-alt--no-borders .dx-dropdownbox .dx-texteditor-input{
    border: none !important;
}
.custom-dropdown-alt-multiple .dx-dropdownbox .dx-texteditor-buttons-container{
    border-right: 1px solid #eaeaea !important;
    border-top: 1px solid #eaeaea !important;
    border-bottom: 1px solid #eaeaea !important;
    
}

.custom-dropdown-alt--no-borders .dx-dropdownbox .dx-texteditor-buttons-container{
    border: none !important;
}

.custom-dropdown-alt-multiple__list .dx-item{
    text-align: left !important;
    font-family: 'Cabin', sans-serif !important;
    color:#7b7b7b;
    font-size: inherit;
}
.custom-dropdown-alt-multiple__list .dx-checkbox-text{
    background: #f4f4f4; 
    font-size: inherit;
    font-family: 'Cabin', sans-serif !important; 
    color: #515252;
}
.custom-dropdown-alt-multiple__list .dx-checkbox-icon{
    border-radius: 5px;
    border: solid #c8c8c8 1px;
    width: 17px;
    height: 17px;
}
.custom-dropdown-alt-multiple__list .dx-list-select-all{
    font-family: 'Cabin', sans-serif !important;
    color: #7b7b7b;
    cursor: pointer;
}
.custom-dropdown-alt-multiple__list .dx-checkbox-indeterminate .dx-checkbox-icon::before{
    border-radius: 3px;
    /* border: solid #c8c8c8 1px; */
    width: 14px !important;
    position: relative !important;
    left: 4.9px !important;
    top: 4.1px !important;

    background-color: #fff;
}
.custom-dropdown-alt-multiple__list .dx-checkbox-icon::before{
    border-radius: 5px;
    background-color: #008da9;
    color: #fff;
    font-size: 8px;
    width: 17px !important;
    position: relative !important;
    left: 3.5px !important;
    top: 3.9px !important
}
.custom-dropdown-alt-multiple__list .dx-checkbox-checked .dx-checkbox-text{
    font-weight: 800;
}
.custom-dropdown-alt-multiple--hideText input{
    color: transparent !important;
}
.custom-dropdown-alt-multiple--hideText .dx-state-invisible{
    color: #7b7b7b !important;
    display: block !important;
}
/* @media screen and (max-width: 990px){
    .custom-dropdown-alt-multiple .dx-dropdownbox .dx-texteditor-input{
        font-size: 12px;
    }
    .custom-dropdown-alt-multiple__list .dx-item{
        font-size: 12px;
    }
    .custom-dropdown-alt-multiple__list .dx-checkbox-text{
        font-size: 12px;
    }
    .custom-dropdown-alt-multiple .dx-placeholder{
        font-size: 12px;
    }
} */