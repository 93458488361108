.my-basket__requirements{
    background-color: #f8f8f8;
    width: 100wv;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    font-size: 13.5px;
}

.my-basket__requirements__main-column{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 5px;
}

.my-basket__requirements__content{
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 10px;
}
.my-basket__requirements__guest-column{
    display: flex;
    margin-top: 30px;
    justify-content: end;
    width: 100%;
}
.my-basket__requirements__buttons{
    margin-left: 138px;
    margin-top: 10px;
}

.my-basket__requirements__location{
    font-family: 'Cabin', sans-serif,'FontAwesome';
}

.my-basket__requirements__notes{
    width: 660px;
}

.my-basket__requirements__acordion-section{
    margin: 5px 0 0 138px
}

/*row 1*/

.my-basket__requirements-row1{
    display: flex;
}

/*row 2*/

.my-basket__requirements-row2{
    display: grid;
    grid-template-columns: 550px 350px;
}

/*row 3*/

.my-basket__requirements-row3{
    display: grid;
    grid-template-columns: 660px 1fr;
    gap: 50px;
}
.my-basket__requirements-row3__column1{
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.my-basket__requirements-row3__column1__row1{
    display: flex;
}

.my-basket__requirements-row3__column1__row2{
    width: 550px;
}
.my-basket__requirements-row3__column2{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* .my-basket__requirements-row3__column2__row1{
    position: absolute;
} */
/*row 4*/
.my-basket__requirements-row4{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.my-basket__requirements-row4__row1{
    display: grid;
    grid-template-columns: 315px 315px;
    gap: 30px;
}

.my-basket__requirements-row4__row2{
    margin-left: 136px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.my-basket__requirements-row4__row2--center{
    display: flex;
}

.my-basket__requirements-row4__row2__row1{
    display: flex;
    gap: 30px;
}

.my-basket__requirements-row4__row2__row1__checkbox-container{
    height: 34px;
    display: flex;
    align-items: center;
}
.my-basket__requirements__pet-details{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}
.my-basket__requirements-row4__row2__row2{
    display: grid;
    grid-template-columns: calc(660px - 138px) 172px;
    gap: 30px;
}

.my-basket__requirements-row4__row2__row2__pet-type{
    margin-left: 110px;
}

.my-basket__requirements-row4__row2__divisor{
    border-bottom: 1px dashed rgb(180, 180, 180);
    width:  730px;
}

@media screen and (max-width: 1594px) {
    .my-basket__requirements-row3{
        gap: 15px;
    }
}

@media screen and (max-width: 1450px) {
    .my-basket__requirements__content{
        display: grid;
        grid-template-columns: 660px calc(100% - 660px - 50px);
        gap: 50px;
    }
    .my-basket__requirements-row2{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .my-basket__requirements-row2__location{
        width: 550px;
    }
    .my-basket__requirements-row2__radius{
        width: 350px;
    }
    .my-basket__requirements-row3{
        display: grid;
        grid-template-columns: 660px;
    }
    .my-basket__requirements-row4__row2__row2{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .my-basket__requirements-row4__row2__row2__pet-weight{
        width: 172px;
        margin-left: 160px;
    }
    .my-basket__requirements-row4__row2__divisor{
        border-bottom: 1px dashed rgb(180, 180, 180);
        width:  550px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 1400px) {

    .my-basket__requirements-row4__row2__row2__pet-type{
        margin-left: 50px;
    }
    .my-basket__requirements-row4__row2__row2__pet-weight{
        width: 172px;
        margin-left: 140px;
    }
}

@media screen and (max-width: 1199px) {
    .my-basket__requirements__content{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .my-basket__requirements__guest-column{
        margin-top: 0px;
        margin-bottom: 15px;
    }
    .my-basket__requirements__acordion-section{
        margin: 5px 0 0 10px
    }
    .my-basket__requirements .accordion-textarea__title{
        margin-left: 10px !important;
    }
    .my-basket__requirements__buttons{
        margin-left: 10px;
        margin-top: 10px;
    }
    .my-basket__requirements-row2{
        display: grid;
        grid-template-columns: 550px 350px;
    }
    .my-basket__requirements-row3{
        display: grid;
        grid-template-columns: 660px 1fr;
        gap: 15px;
    }
    .my-basket__requirements-row4__row2__row2{
        display: grid;
        grid-template-columns: calc(660px - 138px) 172px;
        gap: 30px;
    }
    
    .my-basket__requirements-row4__row2__row2__pet-weight{
        margin-left: 0;
    }
    
    .my-basket__requirements-row4__row2__divisor{
        width:  730px;
    }
}

@media screen and (max-width: 1050px) {
    .my-basket__requirements-row2{
        display: grid;
        grid-template-columns: 480px 280px;
    }
    .my-basket__requirements-row2__location{
        width: 100%;
    }
    .my-basket__requirements-row2__radius{
        width: 100%;
    }
    .my-basket__requirements-row3__column1__row2{
        width: 480px;
    }
    .my-basket__requirements-row3{
        display: grid;
        grid-template-columns: 660px;
    }
}

@media screen and (max-width: 980px) {
    .my-basket__requirements-row4__row2__row2{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .my-basket__requirements-row4__row2__row2__pet-weight{
        width: 172px;
        margin-left: 140px;
    }
    .my-basket__requirements-row4__row2__row2__pet-type{
        margin-left: 50px;
        width: 475px;
    }
    .my-basket__requirements-row4__row2__divisor{
        width:  550px;
    }
}

@media screen and (max-width: 900px) {
    .my-basket__requirements-row4__row2__row2__pet-weight{
        width: 172px;
        margin-left: 150px;
    }
    /* .my-basket__requirements-row4__row2__row2__pet-type{
        margin-left: 75px;
        width: 475px;
    } */
    .my-basket__requirements-row2{
        display: flex;
        flex-direction: column;    
    }
    .my-basket__requirements-row2__location{
        width: 480px;
    }
    .my-basket__requirements-row2__radius{
        width: 280px;
    }
    .my-basket__requirements-row4__row2__row2__pet-type{
        margin-left: 82px;
        width: 442px;
    }
}

@media screen and (max-width: 775px) {
    .my-basket__requirements-row3__column1__row1{
        flex-direction: column;
        gap: 15px;
    }
    .my-basket__requirements-row3__column1__row1 .custom-dropdown{
        width: 280px;
    }
    .my-basket__requirements-row4__row1{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .my-basket__requirements-row4__row1{
        width: 315px;
    }
    .my-basket__requirements-row4__row2__row1{
        flex-direction: column;
        gap: 15px;
    }
    .my-basket__requirements-row4__row2__row1__amount-pets{
        width: 315px;
    }
    .my-basket__requirements-row4__row2__row2__pet-weight{
        width: 172px;
        margin-left: 75px;
    }
    .my-basket__requirements-row4__row2__row2__pet-type{
        margin-left: 15px;
        width: 400px;
    }
    .my-basket__requirements-row4__row2__divisor{
        width:  450px;
    }
    .my-basket__requirements__notes{
        width: 100%;
    }
    .my-basket__requirements-row3{
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 660px) {
    .my-basket__requirements-row1 .custom-date-range{
        display: flex;
        flex-direction: column ;
        gap: 15px;
    }
    .my-basket__requirements-row4__row2__row2__pet-type{
        width: 265px;
        margin-left: 50px;
    }
    .my-basket__requirements-row4__row2__divisor{
        width:  100%;
    }
}

@media screen and (max-width: 550px) {
    .my-basket__requirements-row2__location{
        width: 100%;
    }
    
    .my-basket__requirements-row3__column1__row2{
        width: 100%;
    }
    .my-basket__requirements-row4__row2{
        margin-left: 10px;
    }
    .my-basket__requirements__acordion-section .accordion-treelist__searcher input{
        width: 100%;
    }
   
}

@media screen and (max-width: 450px) {
    .my-basket__requirements-row1 .custom-date-range{
        width: 100%;
    }
    .my-basket__requirements-row2__radius{
        width: 100%;
    }
    .my-basket__requirements-row3__column1__row1 .custom-dropdown{
        width: 100%;
    }
    .my-basket__requirements-row4__row1{
        width: 100%;
    }
    .my-basket__requirements-row4__row2__row1__amount-pets{
        width: 100%;
    }
    .my-basket__requirements-row4__row2__row2 .my-basket__requirements-row4__row2__row2__pet-type{
        width: 100%;
        margin-left: 0;
    }
    .my-basket__requirements-row4__row2__row2 .my-basket__requirements-row4__row2__row2__pet-weight{
        width: 100%;
        margin-left: 0;
    }
    .my-basket__requirements-row3__column1__row1 .custom-input__numbers .dx-texteditor{
        width: 100px;
    }
}