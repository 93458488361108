.custom-uploader{
    display: grid;
    grid-template-columns: 20% calc(80% - 20px);
    gap: 20px;
    /* align-items: center; */
}
.custom-uploader .dx-fileuploader-input-label{
    color: #7b7b7b;
    font-size: inherit;
}
.custom-uploader__label{
    padding-top: 5px;
    text-align: right;
    font-weight: 800;
    color: #515252;
    font-size: inherit;
}

.custom-uploader__label span{
    color: rgb(221,103,99);
}
.custom-uploader .dx-widget{
    font-size: inherit;
}
.custom-uploader__input .dx-fileuploader-wrapper{
    padding: 0;
}
.custom-uploader__input .dx-fileuploader-input-wrapper{
    padding: 0;
    margin: 0;
    border: 0;
}

.custom-uploader__input .dx-fileuploader-content{
    display: flex;
    flex-direction: column-reverse;
}

.custom-uploader .dx-fileuploader-files-container{
    padding-top: 0;
}

.custom-uploader .dx-fileuploader-file-container{
    padding-bottom: 10px;
}
.custom-uploader .dx-fileuploader-upload-button{
    display: none;
}
.custom-uploader__message{
    font-style: italic;
    color: #939393;
}

.custom-uploader__input--another .dx-fileuploader-input-wrapper .dx-fileuploader-button{
    border-radius: 3px;
    border: solid 1px #008da9;
    background-color: #008da9;
    color: rgb(212, 212, 212);
}
.custom-uploader__input--another .dx-fileuploader-input-wrapper .dx-fileuploader-button:hover{
    color: #fff;
}
.custom-uploader__input--hide .dx-fileuploader-input-wrapper{
    display: none;
}
@media screen and (max-width:1400px) {
    .custom-uploader{
        grid-template-columns: 30% calc(70% - 20px);
        gap: 20px;
    }
}
@media screen and (max-width:900px) {
    .custom-uploader{
        grid-template-columns: 25% calc(75% - 20px);
        gap: 20px;
    }
}
@media screen and (max-width:450px) {
    .custom-uploader{
        grid-template-columns: 35% calc(65% - 20px) !important;
        gap: 20px;
    }
}