.requests-table{
    border: 1.5px solid #cdcdcd;
}
.requests-table li::marker{
    color : rgba(26, 147, 170, 0.90)
}
.requests-table__header{
    display: grid;
    gap: 10px;
    grid-template-columns: 1.5fr 9fr 1.5fr;
    background-color: #008da9;
    color: #fff;
    padding: 20px;
}

.requests-table__body .requests-table__body__item{
    display: grid;
    gap: 10px;
    grid-template-columns: 1.5fr 9fr 1.5fr;
    padding: 20px;
    border-bottom: 1.5px solid #cdcdcd;
}

.requests-table__body__item--active{
    border-left: 10px solid #c3d8dd;
    background-color: #f1f1f1;
    cursor: pointer;
}

.requests-table__body .requests-table__body__item:last-child{
    border-bottom: none;
}
.requests-table__body__item__date{
    color: #6b6b6b;
    font-style: italic;
}
.requests-table__body__item__main-content .main-content__info{
    max-height: 0;
    transition: max-height .4s ease-out;
    overflow: hidden;
}
.requests-table__body__item__main-content .main-content__info--show{
    max-height: 1000px;
    transition: max-height .7s ease-in;
}

.requests-table__body__item__main-content .main-content__title{
    font-weight: 800;
    color: #515151;

}
.requests-table__body__item__main-content .main-content__email{
    color: #b1b1b1;
    font-style: italic;
}
.requests-table__body__item__main-content .main-content__info__item{
    display: flex;
    gap: 10px;
    color: #515151;
}
.requests-table__body__item__main-content .main-content__info__name{
    font-weight: 700;
}
.requests-table__body__item__actions{
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 20px;
}
.requests-table__body__item__actions--more{
    height: fit-content;
}
.requests-table__body__item__actions--delete{
    color: #d9534f;
    display: flex;
    align-items: center;  
    gap: 2px;
    height: fit-content;
    cursor: pointer;
}
.main-content__info__value--download{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #008da9;
}
.main-content__info__value--download:hover{
    color: #007e97;
    transform: scale(1.01);
}
@media screen and (max-width: 1600px) {
    .requests-table__header{
        grid-template-columns: 2fr 8fr 2fr;
    }
    
    .requests-table__body .requests-table__body__item{
        grid-template-columns: 2fr 8fr 2fr;
    }
}

@media screen and (max-width: 1200px) {
    .requests-table__header{
        grid-template-columns: 2.5fr 7fr 2.5fr;
    }
    
    .requests-table__body .requests-table__body__item{
        grid-template-columns: 2.5fr 7fr 2.5fr;
    }
}

@media screen and (max-width: 900px) {
    .requests-table__header{
        grid-template-columns: 3fr 6fr 3fr;
    }
    
    .requests-table__body .requests-table__body__item{
        grid-template-columns: 3fr 6fr 3fr;
    }
}

@media screen and (max-width: 760px) {
    .requests-table__body__item__actions{
        flex-direction: column;
    }
    .requests-table__header{
        grid-template-columns: 4fr 6fr 2fr;
    }
    .requests-table__body__item__main-content .main-content__info__item{
        flex-direction: column;
        gap: 0;
    }
    .requests-table__body .requests-table__body__item{
        grid-template-columns: 4fr 6fr 2fr;
    }
}

@media screen and (max-width: 570px) {
    .requests-table__header{
        padding: 20px 2px;
        gap: 2px;
    }
    
    .requests-table__body .requests-table__body__item{
        padding: 2px;
        gap: 2px;
    }
    .requests-table{
        font-size: .8rem;
    }
    .requests-table__header{
        grid-template-columns: 4.5fr 5fr 2.5fr;
    }
    
    .requests-table__body .requests-table__body__item{
        grid-template-columns: 4.5fr 5fr 2.5fr;
    }
}