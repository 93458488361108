.amenities{
    font-size: 14px;
    color:#7e7e7e;
}
.amenities__lists{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 10px;
}
.amenities__tags{
    display: flex;
    column-gap: 20px;
}
@media screen and (max-width: 911px) {
    .amenities__lists{
        column-gap: 13%;
    }
}
@media screen and (max-width: 862px) {
    .amenities__lists{
        column-gap: 10%;
    }
}

@media screen and (max-width: 797px) {
    .amenities__lists{
        column-gap: 8%;
    }
}
@media screen and (max-width: 759px) {
    .amenities{
        font-size: 13px;
    }
    .amenities__lists{
        column-gap: 1%;
    }
}

@media screen and (max-width: 634px) {
    .amenities{
        font-size: 12px;
    }
    .amenities__tags{
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 580px) {
    .amenities__lists{
        grid-template-columns: 1fr;
        margin-top: 10px;
        row-gap: 10px;
    }
}
