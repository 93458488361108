.main-button {
    border: 4px solid rgba(255, 255, 255, 1) !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    color: rgba(255, 255, 255, 1) !important;
    font-weight: bold !important;
    font-size: inherit !important;
    font-family: inherit !important;
    padding: 1rem;
    margin: 0 auto;
    width: 270px;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.main-button--small {
    height: 45px;
    margin: 0 0 0 1rem;
}

.main-button:hover, .main-button:focus{
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(26, 147, 170, 1) !important;
}