.custom-popup{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.209);
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    z-index: 5000;
    overflow: hidden;
    transition: all .8s ease;
}
.custom-popup--active{
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all .8s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-popup__content{
    /* border: 5px solid red; */
    width: 60%;
    background-color: rgb(248,248,248);
    column-gap: 50px;
    height: auto;
    box-shadow: rgb(108, 108, 108) 0px 3px 5px;
}

@media screen and (max-width:1000px){
    .custom-popup__content{
        /* border: 5px solid red; */
        width: 75%;
    }
}
@media screen and (max-width:750px){
    .custom-popup__content{
        /* border: 5px solid red; */
        width: 85%;
    }
}
@media screen and (max-height:750px){
    .custom-popup__content{
        /* border: 5px solid red; */
        max-height: 95%;
        overflow-y: auto;
    }
}
@media screen and (max-width:550px){
    .custom-popup__content{
        /* border: 5px solid red; */
        width: 98%;
    }
}
