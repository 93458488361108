.btn-add-guest {
    width: 100px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    color: #fff !important;
    background-color: #298DA9 !important;
    transition: all 0.2s ease-in-out;
}

.btn-add-guest:hover {
    background-color: inherit;
    color: inherit;
}

.drop-down-list .dx-item.dx-list-item.dx-list-item-selected {
    background-color: #298DA9 !important;
    color: #fff !important;
}