.ae-outer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.ae-btn {
    height: 33px;
    padding: 6px 14px 7px;
    margin: 0 1rem 0 0;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #45d9b1;
    background-image: linear-gradient(to bottom, #45d9b1, #008da9);
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: color 0.2s ease-in-out;
}

.ae-btn:hover {
    cursor: pointer;
    color: #fff;
}

.ae-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 390px) {
    .ae-outer-content {
        width: 270px !important;
        height: 240px !important;
    }

    .ae-btn {
        margin: 0 auto 0.4rem auto !important;
    }

    .ae-buttons {
        flex-direction: column !important;
        margin: 0 auto !important;
        text-align: center !important;
    }

    .ae-buttons a {
        font-size: 14px !important;
    }
}

@media screen and (min-width: 391px) and (max-width: 480px) {
    .ae-outer-content {
        width: 360px !important;
        height: 200px !important;
    }

    .ae-btn {
        margin: 0 auto 0.4rem auto !important;
    }

    .ae-buttons {
        flex-direction: column !important;
        margin: 0 auto !important;
        text-align: center !important;
    }

    .ae-buttons a {
        font-size: 14px !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 567px) {
    .ae-outer-content {
        width: 450px !important;
        height: 200px !important;
    }

    .ae-btn {
        margin: 0 auto 0.4rem auto !important;
    }

    .ae-buttons {
        flex-direction: column !important;
        margin: 0 auto !important;
        text-align: center !important;
    }

    .ae-buttons a {
        font-size: 14px !important;
    }
}