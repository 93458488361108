.custom-card {
    /* font-family: Brandon-Grotesque-Regular; */
    display: grid;
    color: rgb(140, 140, 140);
    font-size: 13px;
    font-weight: 500;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr .5fr .5fr; 
    grid-template-areas: 
            "image title       title       title       title       includes   favorite"
            "image description description description description includes   favorite"
            "image floorplans  floorplans  floorplans  floorplans  floorplans floorplans"
        ;
    padding: 15px 5px;
    border-bottom: solid rgba(141, 141, 141, 0.483) 1px;
    column-gap: 20px;
    /* align-items: stretch; */
    cursor: pointer;
    /*width: 22em;
    height: 400px;*/

}

.custom-card__addToCartPopup{
    grid-area: cartPopup;
}

    .custom-card .custom-card__image,
    .custom-card .custom-card__image img {
        height: 90px;
        width: 135px
    }
    .custom-card__image__wrapper, .custom-card__image__wrapper .dx-gallery{
        height: 100% !important;
        width: 100% !important;
    }
    .custom-card .custom-card__image .dx-gallery{
        height: 90px !important;
    }
    .custom-card--square {
        cursor: pointer;
        position: relative;
        color: rgb(140, 140, 140);
        font-size: 13px;
        font-weight: 500;
        border: solid 1.5px #cdcdcd;
        width: 100%;
        z-index: 1;
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "image       image       image       image"
            "title       title       title       includes"
            "description description description includes"
            "floorplans  floorplans  floorplans  floorplans";
        column-gap: 5px;
    }
.custom-card--addToCart{
    grid-template-columns: .5fr 1fr 1fr 1fr .5fr 2fr .3fr !important; 
    grid-template-areas: 
            "image title       title       title        includes cart favorite"
            "image description description description  includes cart favorite"
            "image floorplans  floorplans  floorplans   includes cart favorite"
        ;
}
.custom-card--square--addToCartPopup{
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    grid-template-areas:
            "image       image       image       image"
            "title       title       title       includes"
            "description description description includes"
            "floorplans  floorplans  floorplans  floorplans"
            "cartPopup   cartPopup   cartPopup   cartPopup";
}
.custom-card__addToCartPopup{
    display: none;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
}
.custom-card--addToCartPopup:hover .custom-card__addToCartPopup{
    padding-top: 5px;
    padding-bottom: 0px;
    display: flex;
}
.custom-card--addToCartPopup{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 107px .5fr !important; 
    grid-template-areas: 
            "image title       title       title       title       includes   favorite"
            "image description description description description includes   favorite"
            "image description description description description cartPopup   favorite"
            "image floorplans  floorplans  floorplans  floorplans  cartPopup favorite"
        ;
}
.custom-card__description__location{
    display: flex;
    align-items: center;
}
.custom-card__description__location img,.custom-card__floorplans img{
    width: 17px;
    height: 17px;
}
.custom-card--square .custom-card__image{
   /* height: 235px; */
   width: 100%;
   aspect-ratio: 3/2;
}
.custom-card--square .custom-card__image img{
    height: 100%;
    /* height: calc(100% - 200px); */
    width: 100%;
    object-fit: cover;
}
.custom-card--square:hover{
    position: absolute;
    transform: scale(1.05);
    z-index: 100;
    background-color: #fff;
    color: rgb(140, 140, 140);
    box-shadow: rgba(0, 0, 0, 0.546) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.custom-card--square:hover .custom-card__addToCartPopup{
    display: flex;
}
.custom-card--square .custom-card__favorite{
   position: absolute;
   background-color: rgba(0, 0, 0, 0.22);
   display: flex;
   justify-content: space-between;
   width: 100%;
   padding: 10px;
}
.custom-card--square .custom-card__favorite img{
    width: 30px;
 }

.custom-card:hover{
    color: rgb(140, 140, 140);
}
.custom-card--selected{
    animation: custom-card-selected-animation 3s linear;
}
.custom-card--square--selected{
    animation: custom-card---square--selected-animation 3s linear;
}
.custom-card__wrapper .custom-popup__content{
    width: fit-content;
}
.custom-card__modal{
    padding: 8% 10%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.custom-card__modal__title{
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
}

.custom-card__modal__buttons{
    padding-top: 10px;
    display: flex;
    gap: 15px;
}

@keyframes custom-card---square--selected-animation {
    0%{
        position: absolute;
        transform: scale(1.08);
        z-index: 100;
        box-shadow: rgba(0, 0, 0, 0.546) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
    100%{
        position: relative;
        /* border: solid 1.5px #cdcdcd;
        width: 100%; */
        transform: scale(1);
        z-index: 1;
        box-shadow: none;
        /* height: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-areas: 
                "image       image       image       image"
                "title       title       title       includes"
                "description description description includes"
                "floorplans  floorplans  floorplans  floorplans"
            ;
        column-gap: 5px; */
    }
}

@keyframes custom-card-selected-animation {
    0%{
        box-shadow: #008da9 0px 3px 6px 0px inset,  #008da9 0px -3px 6px 1px inset;

    }
    100%{
        box-shadow: none;
    }
}
.custom-card__addToCart{
    width: 100%;
    grid-area: cart;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.custom-card__addToCart__floor{
    display: flex;
    /* align-items: center; */
    gap: 5px;
}
.custom-card__addToCart__floor__button{
    height: 34px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
    border: 1px solid #008da9;
    color: #008da9;
    padding: 5px 10px;
    width: fit-content;
    transition: all ease .2s;
    white-space: nowrap;
}

    .custom-card__addToCart__floor__button.button__red {
        color: #d9534f;
        border: 1px solid #d9534f;
    }

.custom-card__addToCart__floor__button svg {
    fill: #008da9;
    width: 16px;
    height: 16px;
}
.custom-card__addToCart__floor__button:hover svg{
    fill: #fff;
}
.custom-card__addToCart__floor__button:hover{
    background-color: #008da9;
    color: #fff;
    transition: all ease .2s;
}

    .custom-card__addToCart__floor__button.button__red:hover {
        background-color: #d9534f;
        color: #fff;
        transition: all ease .2s;
    }
.custom-card__addToCart__floor__image{
    height: 34px;
    display: flex;
    align-items: center;
}
.custom-card__addToCart__floor__dropdown__container{
    width: 100%;
}
.custom-card__addToCart__floor__dropdown__container__group{
    display: grid;
    grid-template-columns: 8fr 2fr;
    gap: 15px;
}
.custom-card__addToCart__floor__dropdown__container__message{
    color: #b7b7b7;
}
.custom-card__image{
    display: flex;
    align-items: center;
    grid-area: image;
}
.custom-card__title{
    font-size: 15px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    grid-area: title;
}
.custom-card--square .custom-card__title{
    padding: 5px 0 0 10px ;
    color: #b7b7b7;
}
.custom-card__description{
    display: flex;
    flex-direction: column;
    row-gap: 4px;  
    grid-area: description;
}
.custom-card--square .custom-card__description{
    /* display: block; */
    padding: 0 0 0 10px ;
}
.custom-card--square .custom-card__description__radius__text{
    display: block !important;
    max-width: 100%;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
}
.custom-card__floorplans{
    padding-top: 5px;
    display: flex;
    max-width: 100%;
    gap: 5px;
    grid-area: floorplans;
}
.custom-card--addToCartPopup .custom-card__floorplans{
    padding-top: 0px;
    align-items: center;
    /* padding-bottom: 5px; */
}

.custom-card__description__location__text{
    display: block !important;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.custom-card__floorplans__text{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3em;
    line-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-card--square .custom-card__floorplans{
    padding: 5px 10px 10px 10px ;
    grid-area: floorplans;
}

.custom-card--addToCartPopup .custom-card__floorplans .custom-card__floorplans__text,
.custom-card--square .custom-card__floorplans .custom-card__floorplans__text{
    display: block;
    max-width: 100%;
    -webkit-line-clamp: 1;
    max-height: fit-content;
    line-height: normal;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
}
.custom-card--square:hover .custom-card__floorplans .custom-card__floorplans__text{
    white-space:initial;
    overflow: auto;
    text-overflow:initial;
}
.custom-card__description div {
    display: flex;
    column-gap: 5px;
}
.custom-card__includes{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    grid-area: includes;
}
.custom-card__includes--reverse{
    flex-direction: column-reverse !important;
    justify-content: start;
}
.custom-card--square .custom-card__includes{
    flex-direction: column-reverse;
    justify-content: start;
    padding: 5px 10px 0 0 ;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #b7b7b7;
}

.custom-card__includes__amenities{
    margin-top: 4px;
    display: flex;
    column-gap: 10px; 
    grid-area: amenities;
}
.custom-card--square .custom-card__includes__amenities{
  flex-direction: row-reverse;
  gap: 10%;
  justify-content: end;
}
.custom-card__includes__building-type{
    grid-area: buildingtype;
}

.custom-card__favorite{
    /*display: flex;
    align-items: flex-start;
    justify-content: flex-end;*/
    text-align: end;
    margin-top: 0;
    margin-right: 10px;
    grid-area: favorite;

}

@media screen and (min-width: 1101px) {
    .custom-card {
        grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr .5fr; 
    }
}


@media screen and (max-width: 1412px) and (min-width: 992px) {
    .custom-card--addToCart{
        grid-template-columns: .5fr 1fr 1fr .5fr .5fr 2.5fr .3fr !important; 
    }
}
@media screen and (max-width: 1100px) and (min-width: 992px){
    .custom-card {
        /* font-size: 9px; */
        column-gap: 10px;
    }
    .custom-card__title{
        font-size: 13px !important;
    }
    .custom-card__title img{
        height: 20px;
    }
}

@media screen and (max-width: 992px){
    .custom-card__favorite{
        margin-right: 0px;    
    }
    .custom-card--addToCart{
        grid-template-columns: .5fr 1fr 1fr .5fr 3fr .3fr !important; 
        grid-template-areas: 
                "image title       title       title        includes  favorite"
                "image description description description  includes  favorite"
                "image floorplans  floorplans  floorplans   cart      cart"
            ;
    }
    .custom-card__addToCart{
        padding-top: 5px;
    }
}

@media screen and (max-width:776px){
    .custom-card--addToCart{
        grid-template-columns: .5fr 5fr 3fr .5fr !important; 
        grid-template-areas: 
                "image title       includes   favorite"
                "image description includes   favorite"
                "image floorplans  floorplans floorplans"
                "cart cart cart cart"
            ;
    }
    .custom-card__addToCart__floor__dropdown__container__group{
        display: grid;
        grid-template-columns: 11fr 1fr;
        gap: 15px;
    }
}
@media screen and (max-width: 650px) {
    .custom-card--addToCart .custom-card__title{
        display: none !important;
    }
    .custom-card__title{
        font-size: 11px;
    }
    .custom-card__title img{
        height: 20px;
    }
    .custom-card__includes__amenities img{
        height: 13px;
    }
    .custom-card__favorite img{
        height: 14px;
    }

    /* .custom-card__description__location img,.custom-card--addToCart .custom-card__floorplans img{
        width: 15px;
        height: 15px;
    } */
    /* .custom-card {
        column-gap: 10px;
        display: grid;
        grid-template: 
            "image title title title title favorite"
            "image includes includes includes includes includes"
            "description description description description description description"
            "floorplans floorplans  floorplans  floorplans  floorplans  floorplans"
        ;
    } */

    .custom-card--addToCart{
        align-items: center;
        grid-template-columns: .5fr 8fr 2fr !important; 
        grid-template-areas: 
                "image description favorite"   
                "image floorplans  favorite"
                "image includes    includes"
                "cart  cart        cart"
            !important;
    }
    .custom-card--addToCart  .custom-card__includes{
        flex-direction: row !important;
        justify-content: space-between;
        align-items: end;
        /* align-items: center;  */
        margin-bottom: 0px;
        row-gap: 1px !important;
        font-size: inherit !important;
    }

    .custom-card--addToCart  .custom-card__includes img{
        height: 15px;
        width: 15px;
    }
    

    .custom-card--addToCart .custom-card__image, .custom-card--addToCart .custom-card__image img {
        height: 70px !important;
        width: 110px !important
    }
    .custom-card__floorplans{
        padding-top: 0px;
        overflow: hidden;
    }
    .custom-card__floorplans__text{
        height: fit-content;
        font-size: inherit;
        display: block;
        width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height:fit-content;
        line-height:normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .custom-card__description{
        font-size: inherit !important;
        overflow: hidden;
        width: 100%;
    }
    .custom-card__favorite{
        margin-right: 0px;    
        height: 100%;
    }
    .custom-card--square--addToCartPopup .custom-card__favorite{
        height: auto;
    }

}
@media screen and (max-width: 500px) {
    .custom-card .custom-card__title{
        display: none;
    }
    .custom-card--addToCartPopup .custom-card__title{
        display: none;
    }
    .custom-card--addToCartPopup {
        font-size: 12px;
        column-gap: 10px;
        grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr .5fr !important;
        grid-template-areas:
            "image description description description description includes favorite"
            "image description description description description includes favorite"
            "image floorplans floorplans floorplans floorplans floorplans floorplans"
            "cartPopup cartPopup cartPopup cartPopup cartPopup cartPopup cartPopup" !important
        ;
    }
    .custom-card{
        column-gap: 10px;
        font-size: 12px;
        grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr .5fr;
        grid-template-areas:
            "image description description description description includes favorite"
            "image description description description description includes favorite"
            "image floorplans floorplans floorplans floorplans floorplans floorplans"
        ;
    }
    .custom-card--addToCartPopup .custom-card__addToCartPopup{
        padding: 5px;
    }
    /* .custom-card--addToCartPopup .custom-card__description{
        row-gap: 5px
    } */
    .custom-card--addToCartPopup .custom-card__image{
        display: flex;
        align-items: center;
        height: 100%;
        width: 100px !important;
    }
    .custom-card .custom-card__image{
        display: flex;
        align-items: center;
        height: 100%;
        width: 100px;
    }
    .custom-card .custom-card__image .dx-gallery{
        height: 65px !important;
        /* width: 100px !important; */
    }
    .custom-card .custom-card__image img {
        height: 65px;
        width: 100px;
    }
    .custom-card--addToCartPopup .custom-card__image img {
        height: 65px !important;
        width: 100px !important;
    }
    .custom-card--addToCartPopup .custom-card__title{
        font-size: 14px;
    }
    .custom-card--addToCartPopup .custom-card__floorplans{
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 450px) {
    .custom-card--addToCart .custom-card__image, .custom-card--addToCart .custom-card__image img {
        height: 65px;
        width: 100px
    }
    .custom-card--addToCart{
        font-size: 12px;
    }
}