.map-information{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.map-information__description{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.map-information__description__title{
    font-size: 18px;
    font-weight: 700;
}


@media screen and (max-width:550px) {
    .map-information__description__title{
        font-size: 15px;
    }
    .map-information__description__subtitle{
        font-size: 12px;
        padding-bottom: 10px;
    }
}
