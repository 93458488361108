.property-cards-container{
    margin-bottom: 10px;
    /*display: grid;
    grid-template-columns: auto auto;
    row-gap: 50px;
    justify-content: space-between;*/
}
.property-cards-container--square{
    margin: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
    gap: 15px;
}

@media screen and (max-width: 1250px)and (min-width: 881px){
    /* .property-cards-container{
        grid-template-columns: auto;
    } */
    .property-cards-container--square{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 704px) {
    .property-cards-container--square{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 450px) {
    .property-cards-container--square{
        grid-template-columns: 1fr;
    }
}