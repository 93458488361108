.request-option__title{
    display: flex;
    gap: 10px;
    align-items: center;
    color: #515151;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.request-option__title__main{
    display: flex;
    gap: 10px;
}
.request-option__title__additional{
    display: flex;
    gap: 10px;
}
.request-option__title__additional__info{
    display: flex;
    gap: 10px;
}  
.request-option__title__additional__images{
    display: flex;
    align-items: center;
    gap: 10px;
}
.request-option__title__building-type{
    display: flex;
    font-weight: normal;
    background-color: #939393;
    color: #fff;
    border-radius: 20px;
    padding: 0 8px;
    font-size: small;
    align-items: center;
}
.request-option__title__renovated{
    color: #939393;
    font-weight: normal;
    font-size: smaller;
    display: flex;
    gap: 3px;
    align-items: center;
}

.request-option__title__renovated img{
    width: 22px;
    height: 19px;
}

.request-option__title__certified{
    display: flex;
    align-items: center;
}

@media screen and (max-width:900px){
    .request-option__title{
        flex-direction: column;
        align-items: flex-start;

    }
}
@media screen and (max-width:600px){
    .request-option__title__additional{
        flex-direction: column;
        align-items: flex-start;

    }
}