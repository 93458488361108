.property-request {
    width: 100%;
    min-height: 100vh;
    height: auto;
}

.property-request__header-text {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700 !important;
}

.property-request__success-message {
    width: 100%;
    height: auto;
}

.property-request__success-message-container {
    border: 1px solid #42a6c9;
    border-radius: 3px;
    background-color: #eef9fd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto 0 auto;
    width: 720px;
    height: auto;
}

.success-message-color,
.success-message-color:hover {
    color: #42a6c9;
}

.property-request__contact-title,
.property-request__property-title {
    text-transform: uppercase !important;
    text-align: center !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    margin: 3rem 0 0.5rem 0 !important;
}

.property-request__header-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 370px;
    background: url("../../../images/bg_news.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.phone-width {
    width: 370px !important;
}

.checkbox {
    margin: 6px 0 0 0;
}

.commute-distance-selectbox {
    width: 100px !important;
}

.property-request-textarea {
    height: 80px;
}

.property-request-submit-btn {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin: 0 0 0 100px !important;
}

.property-request-submit-btn-button {
    border-radius: 2px;
    background: rgba(26, 147, 170, 0.90);
    background: -webkit-linear-gradient(to bottom, #45d9b1, rgba(26, 147, 170, 0.90));
    background: linear-gradient(to bottom, #45d9b1, rgba(26, 147, 170, 0.90));
    color: rgb(237, 237, 237);
    box-shadow: 0.5px 0.5px 0.5px rgba(129, 129, 129, 0.607);
    border: none;
}

.property-request-submit-btn-button:hover {
    color: #fff;
    box-shadow: none;
}

.label>label>span,
.label-required>label>span {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    width: 90px !important;
    height: auto !important;
    font-weight: 700 !important;
}

.label-required>label>span::after {
    content: "*";
    color: #c9302c;
}

.sub-text-color {
    color: #bfbfbf !important;
    font-size: 12px;
}

.custom-drop-down-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: 0 1rem;
}

.custom-drop-down-btn>span {
    background-color: #f2f2f2;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 27px;
}

.one-col-input {
    width: 75% !important;
    margin: 0 auto;
}

.two-col-input {
    width: 75% !important;
    margin: 0 auto;
}

.distance-input {
    width: 25% !important;
}

.distance-box {
    width: 103px !important;
}

@media screen and (min-width:320px) and (max-width: 1160px) {
    .property-request__form h1 {
        font-size: 12px !important;
    }

    .property-request-form-container {
        padding: 0 0 3rem 0 !important;
        width: auto !important;
        margin: 0 auto !important;
    }

    .label>label>span,
    .label-required>label>span {
        font-size: 10px !important;
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        margin: 0 0 1rem 0 !important;
    }

    .label-flex-wrap {
        flex-wrap: wrap !important;
    }

    .phone-width {
        width: auto !important;
    }

    .distance-input {
        width: 50% !important;
    }

    .distance-box {
        margin: 0 0 0 1rem !important;
    }
}

@media screen and (min-width:480px) and (max-width: 547px) {
    .property-request__form h1 {
        font-size: 14px !important;
    }

    .label>label>span,
    .label-required>label>span {
        font-size: 12px !important;
    }

    .distance-box {
        margin: 0 0 0 1rem !important;
    }
}

@media screen and (min-width: 548px) {
    .property-request__form h1 {
        font-size: 18px !important;
    }

    .label>label>span,
    .label-required>label>span {
        font-size: 14px !important;
    }

    .distance-box {
        margin: 0 0 0 1rem !important;
    }
}

@media screen and (min-width: 548px) and (max-width: 767px) {
    .distance-box {
        margin: 0 0 0 1rem !important;
    }
}

@media screen and (min-width: 768px) {
    .distance-box {
        margin: 0 0 0 1rem !important;
    }
}

@media screen and (min-width: 1160px) {
    .property-request {
        /* height: 100% !important; */
        margin: 0 0 4rem 0;
    }
}

/* Success Message Media Queries */
@media screen and (min-width: 320px) and (max-width: 566px) {
    .property-request__success-message-container {
        margin: 2rem auto !important;
        padding: 2rem !important;
        width: 270px !important;
    }
}

@media screen and (min-width: 567px) and (max-width: 719px) {
    .property-request__success-message-container {
        width: 450px !important;
    }
}

@media screen and (min-width: 720px) and (max-width: 839px) {
    .property-request__success-message-container {
        width: 540px !important;
    }
}

/* Submit Btn Media Query */
@media screen and (min-width: 320px) and (max-width: 1160px) {
    .property-request-submit-btn {
        margin: 0 !important;
    }
}

/* recaptcha */
@media screen and (min-width:320px) and (max-width: 479px) {
    .g-recaptcha.property-request-recaptcha {
        transform: scale(0.76) !important;
        transform-origin: 0 0;
    }
}

@media screen and (min-width:480px) and (max-width: 567px) {
    .g-recaptcha.property-request-recaptcha {
        transform: scale(0.9) !important;
        transform-origin: 0 0;
    }
}

@media screen and (min-width:1161px) {
    .g-recaptcha.property-request-recaptcha {
        transform: scale(0.9) !important;
        transform-origin: 0 0;
        margin: 0 0 0 100px !important;
    }
}