.image-preview-container.container {
    height: 45px;
    margin: 0 !important;
    padding: 0 !important;
}

.image-preview-container__inner {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin: 0 !important;
    padding: 0 !important;
}

.image-preview__container {
    height: 36px !important;
    width: 72px !important;
}

.image-preview__img {
    height: 100% !important;
    width: 100% !important;
}

.image-preview__text {
    font-size: 10px;
}

.image-preview.mx-2.text-lowercase {
    position: relative;
    top: 9px;
}

.text-oasis-danger {
    color: #c9302c;
    position: relative;
    top: 9px;
}

.file-uploader-query .dx-fileuploader-wrapper {
    margin: 0 !important;
    padding: 0 !important;
}

.file-uploader-query .dx-fileuploader-input-wrapper {
    margin: 0 !important;
    padding: 0 !important;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .image-preview-container__inner {
        flex-wrap: wrap !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 567px) {
    .image-preview.mx-2.text-lowercase {
        left: -9px !important;
    }

    .text-oasis-danger {
        top: 0px;
        left: 9px;
    }
}

@media screen and (min-width: 568px) and (max-width: 991px) {
    .image-preview.mx-2.text-lowercase {
        position: relative !important;
        top: 9px !important;
        left: 9px !important;
    }

    .text-oasis-danger {
        left: 9px;
    }
}